import { AuthConfigServiceProvider } from './app-auth-config.service';
import { AppStorageServiceProvider, AuthStorageServiceProvider } from './app-storage.service';
import { IdentityServiceProvider } from './identity.service';
import { StudentServiceProvider } from './student.service';
import { TagManagerServiceProvider } from './tag-manager.service';
import { UserServiceProvider } from './user.service';
import { AuthorisationServiceProvider } from './authorisation.service';
import { NoticeServiceProvider } from './notices.service';
import { EmbargoServiceProvider } from './embargo.service';
import { PersonServiceProvider } from './person.service';
//import { RecentlyViewedServiceProvider } from './recently-viewed.service';

export {MetaService} from './meta.service';
export {AppAuthConfigService} from './app-auth-config.service';
export {AppStorageService} from './app-storage.service';
export {IdentityService} from './identity.service';
export {StudentService} from './student.service';
export {PersonService} from './person.service';
export {TagManagerService} from './tag-manager.service';
export {UserService} from './user.service';
export {AuthorisationService} from './authorisation.service';
export {NoticeService} from './notices.service';
export {RecentlyViewedService} from './recently-viewed.service';

export const ServiceProviders=[
    AuthConfigServiceProvider,
    AuthStorageServiceProvider,
    AppStorageServiceProvider,
    IdentityServiceProvider,
    StudentServiceProvider,
    TagManagerServiceProvider,
    UserServiceProvider,
    AuthorisationServiceProvider,
    PersonServiceProvider,
    NoticeServiceProvider,
    EmbargoServiceProvider
    // RecentlyViewedServiceProvider ** This causes an error. There must be a cicular dependency somewhere **
];