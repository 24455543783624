import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/providers';
import { BypassErrorService } from '@uoa/error-pages';
import { BaseHttpService } from './base-http-service';
import { cacheable } from '@core/cache';
import { Affiliations, AppConfiguration } from '@domain/models';
import { Observable, map } from 'rxjs';

/**
 * Get personal information adjunctive to identity
 *
 */
@Injectable({
    providedIn: 'root',
})
export class PersonService extends BaseHttpService {
    constructor(
        @Inject(APP_CONFIG) configuration: AppConfiguration,
        http: HttpClient,
        errorBypass: BypassErrorService) {
            super(configuration, http, errorBypass);
    }

    /**
     * Get a list of a person's affiliations
     *
     * @param id student id
     * @returns List of affiliations
     */
    @cacheable()
    getAffiliations(id: string): Observable<string[]> {
        return this.getResource<Affiliations>('affiliations', {params: {id}})
        .pipe(
            map(result => result.data),
        );
    }
}

export const PersonServiceProvider={ provide: PersonService, useClass: PersonService };