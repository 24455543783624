import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ServiceError, UnsubmittedApplications } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { PersonViewComponent } from '@modules/person/model/person-view.component';

@Component({
  templateUrl: './unsubmitted-applications-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class UnsubmittedApplicationsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    unsubmittedApplications: UnsubmittedApplications;

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getUnsubmittedApplications(super.identity.id)
            .subscribe({
                next: (result) => {
                    this.unsubmittedApplications = result;
                    this.isLoading = false;
                    this.isError = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    render() {
        this.load();
    }
}
