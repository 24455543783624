<ion-grid class="table ion-no-padding">
    <div class="table__thead">

        <ion-row>
            <ion-col size="2">Application No.</ion-col>
            <ion-col size="2">Admit Term</ion-col>
            <ion-col size="2">Academic Programme</ion-col>
            <ion-col size="2">Status</ion-col>
            <ion-col size="2">Reason</ion-col>
            <ion-col size="2" class="ion-text-center">Details</ion-col>
        </ion-row>

    </div>
    <div class="table__tbody">

        <ng-container *ngIf="submittedApplications?.length > 0; else noData">
            <ng-container *ngFor="let application of submittedApplications; even as isEven">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ application.applicationNumber | default }}</ion-col>
                    <ion-col size="2">{{ application.term?.description | default }}</ion-col>
                    <ion-col size="2">{{ application.programme?.description | default }}</ion-col>
                    <ion-col size="2">{{ application.status?.description | default }}</ion-col>
                    <ion-col size="2">{{ application.programme?.reason.action?.code | default }} / {{ application.programme?.reason.code | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="application.applicationNumber" (toggled)="handleToggleDetails(application.applicationNumber)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(application.applicationNumber)" class="more-details">
                    <ion-col size="12">
                        <app-submitted-application-details
                                [application]="application"
                                [highlight]="isEven" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #noData>
            <ion-row>
                <ion-col size="12" class="ion-text-center">No data to display</ion-col>
            </ion-row>
        </ng-template>
    </div>
</ion-grid>