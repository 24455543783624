<ng-container *ngIf="progress?.length > 0; else noProgress">
    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="3">Programme</ion-col>            
                <ion-col size="3">Requirement</ion-col>
                <ion-col class="ion-text-center">
                    Reqd.
                </ion-col>
                <ion-col class="ion-text-center">
                    Passed
                </ion-col>
                <ion-col class="ion-text-center">
                    Progress
                </ion-col>
                <ion-col class="ion-text-center">
                    Need
                </ion-col>                
                <ion-col class="ion-text-center"></ion-col>                
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let data of progress; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="3">{{ data.programme.description | default }}</ion-col>
                    <ion-col size="3">{{ data.requirement.description | default }}</ion-col>
                    <ion-col class="ion-text-right">{{ data.unitsRequired | default }}</ion-col>
                    <ion-col class="ion-text-right">{{ data.unitsPassed | default }}</ion-col>
                    <ion-col class="ion-text-right">{{ data.unitsInProgress | default }}</ion-col>
                    <ion-col class="ion-text-right">{{ data.unitsNeeded | default }}</ion-col>
                    <ion-col class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)" />
                    </ion-col>                    
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)">
                    <ion-col size="12">
                        <app-student-progress-details [progress]="data"/>
                    </ion-col>
                </ion-row>                
            </ng-container>
        </div>
    </ion-grid>   
</ng-container>

<ng-template #noProgress>
    <h3 class="header__notfound">
        No data to display for Student Progress
    </h3>
</ng-template>