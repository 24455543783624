import { Component } from '@angular/core';

@Component({
  selector: 'app-search-result-card-skeleton',
  templateUrl: './search-result-card-skeleton.component.html',
  styleUrls: ['./search-result-card-skeleton.component.scss']
})
export class SearchResultCardSkeletonComponent {
  count = 3;
  placeholders = Array(this.count);
}
