import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NoticeService } from '@app/core/services';
import { Identity } from '@app/domain/models';

@Component({
    selector: 'app-identity-summary',
    templateUrl: './identity-summary.component.html',
    styleUrl: './identity-summary.component.scss',
})
export class IdentitySummaryComponent {
    @Input() identity!: Identity;
    @Output() openMenu = new EventEmitter<boolean>(false);
    isErrorBannerOpen = false;
    errorMessage: string;

    constructor(private notices: NoticeService) {
        notices.getLatestError()
            .subscribe({
                next: (notice) => {
                    this.errorMessage = notice?.message;
                    this.isErrorBannerOpen = !notices.getHasViewedError() && this.errorMessage != null;
                }
            });
    }

    get residency () {
        return this.identity?.residency === 'I' ? 'International' : 'Domestic';
    }

    dismissErrorBanner() {
        this.isErrorBannerOpen = false;
        this.notices.setHasViewedError(true);
    }

    /**
     * Handle mobile menu button click. This always emits true as the close button
     * is within the menu.
     */
    menuClick() {
        this.openMenu.emit(true);
    }
}