export const ObjectAttributeId = {
  // Person
  PERSON_LEGAL_NAME: 'person.legalName',
  PERSON_PREFERRED_NAME: 'person.preferredName',
  PERSON_DOB: 'person.dob',
  PERSON_GENDER: 'person.gender',
  PERSON_ETHNICITIES: 'person.ethnicities',
  PERSON_RESIDENCY: 'person.residency',
  PERSON_CITIZENSHIP: 'person.citizenship',
  PERSON_NSN: 'person.nsn',
  PERSON_PREFERRED_EMAIL: 'person.preferredEmail',
  PERSON_OTHER_EMAIL: 'person.otherEmail',
  PERSON_TELEPHONE: 'person.telephone',
  PERSON_ADDRESS: 'person.address',

  // Programme
  PROGRAMME_COURSES_AND_CLASSES: 'programme.coursesAndClasses'
} as const;

export const MenuItemId = {
  PERSON: 'person',
  PERSONAL: 'personal',
  AFFILIATIONS: 'affiliations',
  SERVICE_INDICATORS: 'service-indicators',
  RESIDENCY: 'residency',
  STUDENT_GROUPS: 'student-groups',

  APPLICANT: 'applicant',
  PRIORITY_GROUPS: 'priority-groups',
  UNSUBMITTED_APPLICATIONS: 'unsubmitted-applications',
  SUBMITTED_APPLICATIONS: 'submitted-applications',
  CHECKLISTS: 'checklists',
  ENTRANCE_QUALIFICATIONS: 'entrance-qualifications',
  PRIOR_EDUCATION: 'prior-education',
  SCHOOL_RESULTS: 'school-results',
  ENGLISH_LANGUAGE: 'english-language',

  STUDENT: 'student',
  ENROLMENT: 'enrolment',
  PROGRAMME: 'programme',
  CONCESSIONS: 'concessions',
  PROGRESS: 'progress',
  CREDIT: 'credit',
  OFFICIAL_DOC_REQUEST: 'official-doc-request',
  VISAS_INSURANCE: 'visas-insurance',
  RESEARCH: 'research',
  COMMENTS_CHECKLISTS: 'comments-checklists',
  CS9_ADVISEMENTS: 'cs9-advisements',
  CS9_GRADUATION_REQUIREMENTS: 'cs9-graduation-requirements',
  CS9_UNOFFICIAL_TRANSCRIPT: 'cs9-unofficial-transcript',
  CS9_CHECKLIST_MANAGEMENT: 'cs9-checklist-management',
  CS9_CLASS_SEARCH: 'cs9-class-search',
  COMMENTS: 'comments',

  FEES: 'fees',
  TRANSACTION_FEES: 'transaction-fees',
  STUDYLINK_STATUS: 'studylink-status',
  LOAN_DETAILS: 'loan-details',
  INVOICES: 'invoices',
  THIRD_PARTY_INFO: 'third-party-info',

  EXAMS: 'exams',
  EXAM_DETAILS: 'exam-details',
  ASSESSMENTS: 'assessments',

  GRADUATION: 'graduation',
  GRADUATION_APPLICATIONS: 'graduation-applications',
  GRADUATION_EVENTS: 'graduation-events',
  DEGREES: 'degrees',

  SCHOLARSHIPS: 'scholarships',
  SCHOLARSHIPS_APPLICATIONS: 'scholarship-applications',
  SCHOLARSHIPS_HISTORY: 'scholarships-history',
  SCHOLARSHIPS_PAYMENTS: 'scholarship-payments',
  TIMETABLES: 'timetables',

  COMMUNICATIONS: 'communications',
  COMMUNICATIONS_VIEW: 'communications-view',
  COMMUNICATIONS_HISTORY: 'communication-history',

  REFERRALS: 'referrals',
};

/**
 * All possible security objects
 */
const SECURITY_OBJECTS = new Set([
  ...Object.values(MenuItemId),
  ...Object.values(ObjectAttributeId),
]);

export interface ScopePermission {
  [key: string]: Set<string>;
}

function without(toRemove: Set<string>, original: Set<string>): Set<string> {
  const returnSet = new Set<string>();
  original.forEach( item => {
    if (!toRemove.has(item)) {
      returnSet.add(item);
    }
  });
  return returnSet;
}

const SCOPE_PERMISSIONS: ScopePermission = {
  // Admin has access to all objects
  'https://person.auckland.ac.nz/admin.read': SECURITY_OBJECTS,

  // Standard
  'https://person.auckland.ac.nz/standard.read': without(new Set([
      MenuItemId.THIRD_PARTY_INFO,
      MenuItemId.SCHOLARSHIPS_APPLICATIONS,
      MenuItemId.SCHOLARSHIPS_HISTORY,
      MenuItemId.SCHOLARSHIPS_PAYMENTS,
  ]), SECURITY_OBJECTS),

  'https://person.auckland.ac.nz/light.read':
    without(new Set([
      MenuItemId.RESIDENCY,
      MenuItemId.STUDENT_GROUPS,
      MenuItemId.PRIORITY_GROUPS,
      MenuItemId.UNSUBMITTED_APPLICATIONS,
      MenuItemId.SUBMITTED_APPLICATIONS,
      MenuItemId.CHECKLISTS,
      MenuItemId.SCHOOL_RESULTS,
      MenuItemId.ENGLISH_LANGUAGE,
      MenuItemId.ENTRANCE_QUALIFICATIONS,
      MenuItemId.CONCESSIONS,
      MenuItemId.TRANSACTION_FEES,
      MenuItemId.STUDYLINK_STATUS,
      MenuItemId.LOAN_DETAILS,
      MenuItemId.INVOICES,
      MenuItemId.THIRD_PARTY_INFO,
      MenuItemId.PRIOR_EDUCATION,
      ObjectAttributeId.PERSON_LEGAL_NAME,
      ObjectAttributeId.PERSON_DOB,
      ObjectAttributeId.PERSON_GENDER,
      ObjectAttributeId.PERSON_ETHNICITIES,
      ObjectAttributeId.PERSON_RESIDENCY,
      ObjectAttributeId.PERSON_CITIZENSHIP,
      ObjectAttributeId.PERSON_NSN,
      ObjectAttributeId.PERSON_OTHER_EMAIL,
      ObjectAttributeId.PERSON_TELEPHONE,
      ObjectAttributeId.PERSON_ADDRESS,
      ObjectAttributeId.PROGRAMME_COURSES_AND_CLASSES,
      MenuItemId.EXAM_DETAILS,
      MenuItemId.COMMUNICATIONS_VIEW,
      MenuItemId.COMMUNICATIONS_HISTORY,
      MenuItemId.SCHOLARSHIPS_APPLICATIONS,
      MenuItemId.SCHOLARSHIPS_HISTORY,
      MenuItemId.SCHOLARSHIPS_PAYMENTS,
    ]), SECURITY_OBJECTS)
  ,

  'https://person.auckland.ac.nz/exams.read':
    without(new Set([MenuItemId.TRANSACTION_FEES,
      MenuItemId.STUDYLINK_STATUS,
      MenuItemId.LOAN_DETAILS,
      MenuItemId.INVOICES,
      MenuItemId.THIRD_PARTY_INFO,
      MenuItemId.SCHOLARSHIPS_APPLICATIONS,
      MenuItemId.SCHOLARSHIPS_HISTORY,
      MenuItemId.SCHOLARSHIPS_PAYMENTS
    ]), SECURITY_OBJECTS)
  ,

  'https://person.auckland.ac.nz/fees.read': without(new Set([
      MenuItemId.SCHOLARSHIPS_APPLICATIONS,
      MenuItemId.SCHOLARSHIPS_HISTORY,
      MenuItemId.SCHOLARSHIPS_PAYMENTS
  ]), SECURITY_OBJECTS),

  'https://person.auckland.ac.nz/scholarships.read': SECURITY_OBJECTS,
};

const EMBARGO_RESTRICTED = [
  MenuItemId.SCHOOL_RESULTS
];

export interface Permissions {
  objects: string[];
  permissions: ScopePermission;
  embargoRestricted: string[]
}

/**
 * Get a scope permission matrix. The default provider uses local configuration, but this
 * could be changed to retrieve configuration from a network resource.
 *
 * @returns ScopePermission
 */
export const getPermissions = async (): Promise<Permissions> => {
  return {
    objects: [...SECURITY_OBJECTS],
    permissions: SCOPE_PERMISSIONS,
    embargoRestricted: EMBARGO_RESTRICTED
  };
};
