import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Exam, ExamDetail, ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ExamViewModel } from './exam-view.model';
import dayjs from 'dayjs';

@Component({
  selector: 'app-exams-view',
  templateUrl: './exams-view.component.html',
  styleUrl: './exams-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ExamsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    exams: ExamViewModel[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getExams(super.identity.id)
            .subscribe(
                {
                    next: (result: Exam) => {
                        this.exams = this.buildViewModel(result);
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isError = true;
                        this.isLoading = false;
                    },
                });
    }

    render() {
        this.load();
    }

    buildViewModel(result: Exam) {
        let exams = result.exams;
        const conditions = result.conditions;

        exams = exams.filter(v => v.term.academicYear >= dayjs().year());

        return [].concat(...exams.map((exam: ExamDetail) => {
            const found = conditions?.find(c => c.term.description === exam.term.description);

            return Object.assign({}, {...exam}, {
                conditions: found
            });
        })) as ExamViewModel[];
    }
}
