/* eslint-disable no-console */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@uoa/web-components/loader';

async function main() {
  try {
      if (environment.production) {
          enableProdMode();

          // eslint-disable-next-line no-console
          console.debug = () => null;
      }

      console.debug('Bootstrapping application module');
      await platformBrowserDynamic().bootstrapModule(AppModule);

      console.debug('Loading custom elements');
      await defineCustomElements();
  } catch (ex) {
      console.error(`Error during application bootstrap. ${ex}`);
  }
}

void main();