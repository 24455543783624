<ng-container *ngIf="communications?.length > 0; else empty">
    <ion-grid class="ion-no-padding margin-bottom-l">
        <ion-row>
            <ion-col class="ion-padding-0" size="auto">
                <ion-item color="primary" lines="none">
                    <ion-select [value]="commType" (ionChange)="onChangeComm($event)"
                                label="Communications:" interface="popover" placeholder="All">
                        <ion-select-option [value]="null">All</ion-select-option>
                        <ng-container *ngFor="let direction of commTypeList">
                            <ion-select-option [value]="direction.code">{{ direction.description }}</ion-select-option>
                        </ng-container>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid class="table ion-no-padding" slot="content">
        <div class="table__thead">
            <ion-row>
                <ion-col size="" class="ion-text-center">Date</ion-col>
                <ion-col size="2">Letter Code</ion-col>
                <ion-col size="">Category</ion-col>
                <ion-col size="">Direction</ion-col>
                <ion-col size="2" class="ion-text-center">Details</ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let comm of communicationList; even as isEven; index as i; trackBy: trackBy">

                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="" class="ion-text-center">
                        {{ comm.createdOn | date: 'dd/MM/YYYY HH:mm' | default }}
                    </ion-col>
                    <ion-col size="2">{{ comm.type?.code| default }}</ion-col>
                    <ion-col size="">{{ comm.category?.description | default }}</ion-col>
                    <ion-col size="">{{ direction(comm) }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)">
                    <ion-col size="12">
                        <app-communication-details [highlight]="isEven" [communication]="comm"/>
                    </ion-col>
                </ion-row>

            </ng-container>
        </div>
    </ion-grid>
</ng-container>
<ng-template #empty>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>