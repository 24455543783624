<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2>Scholarships Applications</h2>
        <app-scholarship-applications [applications]="applications" *ngIf="applications?.length > 0; else empty"/>
        <ng-template #empty>
            <h3 class="header__notfound">No data to display</h3>
        </ng-template>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>