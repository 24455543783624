import { Component, Input } from '@angular/core';
import {EnrolmentCart, KeyValue} from '@domain/models';
import dayjs from 'dayjs';
import { sortByProp } from '@app/util';
import { ModalController } from '@ionic/angular';
import {CustomCalendarEventInput} from '@modules/person/components/timetable-view/timetable-view.model';

@Component({
  selector: 'app-enrolment-cart-calendar',
  templateUrl: './enrolment-cart-calendar.component.html',
  styleUrl: './enrolment-cart-calendar.component.scss'
})
export class EnrolmentCartCalendarComponent {
    events: CustomCalendarEventInput[];

    @Input() set cart(items: EnrolmentCart[]) {
        // Includes only with timetable
        items = items.filter(v => v.class.timetables?.length > 0);
        this.events = this.toCalendarEvents(items);
    }

    constructor(private modalCtrl: ModalController) {}

    close() {
        void this.modalCtrl.dismiss();
    }

    private toCalendarEvents(data: EnrolmentCart[]): CustomCalendarEventInput[] {
        let id = 0;
        const dayOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const eventClassName = (cart: EnrolmentCart) => {
            if (cart.studentEnrolmentStatus?.description) {
                return `enrolment-${cart.studentEnrolmentStatus?.description.toLowerCase()}`;
            }

            return 'enrolment-unenrolled';
        };
        const studentEnrolmentStatus = (cart: EnrolmentCart) => {
            if (!cart.studentEnrolmentStatus) {
                return {
                    code: '',
                    description: 'Unenrolled',
                } as KeyValue;
            }

            return cart.studentEnrolmentStatus;
        };

        const events = data.flatMap( cart =>
            cart.class.timetables?.map( timetable => ({
                id: `${++id}`,
                title: `${cart.class.subject.code} ${cart.class.catalogNumber}`,
                startRecur: timetable.start,
                endRecur: dayjs(timetable.end).endOf('day').toDate(),
                startTime: timetable.startTime,
                endTime: timetable.endTime,
                daysOfWeek: timetable.days.map(day => dayOfWeek.indexOf(day)),
                className: eventClassName(cart),
                extendedProps: {
                    description: cart.class.description,
                    subject: cart.class.subject,
                    component: cart.class.component,
                    catalogNumber: cart.class.catalogNumber.toString(),
                    classNumber: cart.classNumber,
                    building: timetable.building,
                    room: timetable.room,
                    location: timetable.location,
                    instructors: cart.class.instructors,
                    studentEnrolmentStatus: studentEnrolmentStatus(cart)
                }
            }))
        );

        return sortByProp('ascend', events, 'startRecur');
    }
}
