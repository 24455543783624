import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/providers';
import { BypassErrorService } from '@uoa/error-pages';
import { BaseHttpService } from './base-http-service';
import { cacheable } from '@core/cache';
import { Notice, AppConfiguration, NoticeSeverity, ServiceError } from '@domain/models';
import { Observable, map, catchError, throwError } from 'rxjs';
import dayjs from 'dayjs';
import { DataResponse } from './model';

/**
 * Get portal notices
 *
 */
@Injectable({
    providedIn: 'root',
})
export class NoticeService extends BaseHttpService {
    /**
     * Track if the user has viewed the error notification. As this service is a singleton then
     * this value will be set for the duration of the user's session.
     */
    private hasViewedError: boolean = false;

    constructor(
        @Inject(APP_CONFIG) configuration: AppConfiguration,
        http: HttpClient,
        errorBypass: BypassErrorService) {
            super(configuration, http, errorBypass);
    }

    /**
     * Get a list of portal notices
     *
     * @returns List of notices (that have not expired or not in-force)
     */
    @cacheable()
    getNotices(): Observable<Notice[]> {
        return this.getResource<DataResponse<Notice>>('messages', {handleError: true})
            .pipe(
                map(result => result.data.filter(notice => this.isCurrent(notice))),
                catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying notices', err)) )
            );
    }

    public getLatestError(): Observable<Notice> {
        return this.getNotices()
                .pipe(
                    map(notices => notices.find(n => n.severity.code === NoticeSeverity.Error))
                );
    }

    public setHasViewedError(value: boolean) {
        this.hasViewedError = value;
    }

    public getHasViewedError(): boolean {
        return this.hasViewedError;
    }

    private isCurrent(notice: Notice): boolean {
        if (notice.meta?.start === undefined || notice.meta?.end === undefined) {
            return true;
        }

        const now = new Date();
        const start = this.toDate(notice.meta?.start);
        const end = this.toDate(notice.meta?.end);

        if (start === undefined || end === undefined) {
            return true;
        }

        return start.getTime() <= now.getTime() && end.getTime() >= now.getTime();
    }

    private toDate(value: string): Date {
        if (value === undefined) {
            return undefined;
        }

        const dt = dayjs(value);
        if (dt.isValid()) {
            return dt.toDate();
        }

        return undefined;
    }
}

export const NoticeServiceProvider={ provide: NoticeService, useClass: NoticeService };