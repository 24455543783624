import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Plan } from '@domain/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-plans-modal',
  templateUrl: './plans-modal.component.html',
  styleUrl: './plans-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlansModalComponent {
    openPanels = new Set<number>();

    constructor(private modalCtrl: ModalController) {}

    @Input() plans: Plan[];

    planHasDetail(plan: Plan): boolean {
        return plan.points !== undefined || plan.option !== undefined || plan.duration !== undefined;
    }

    close() {
        return this.modalCtrl.dismiss(null, 'close');
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }
}
