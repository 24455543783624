<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2 class="margin-bottom-0">Groups</h2>
        <ion-list class="ion-no-padding">
            <ng-container *ngIf="groups && groups.length > 0; else noGroups">
                <ion-item class="ion-no-padding" *ngFor="let group of groups">
                    <app-icon *ngIf="group.isActive"
                        aria-hidden="true" 
                        name="check circle"
                        icon-style="outlined"
                        slot="start" 
                        size="large"
                        colour="success"></app-icon>
                    <app-icon  *ngIf="!group.isActive"
                        aria-hidden="true" 
                        name="cancel"
                        slot="start" 
                        icon-style="outlined"
                        size="large"
                        color="danger"></app-icon>
                    <ion-label [class]="group.isActive ? '':'inactive-group'">{{group.description}}</ion-label>
                </ion-item>
            </ng-container>

            <ng-template #noGroups>
                <h3 class="header__notfound">
                    No data to display
                </h3>
            </ng-template>
        </ion-list>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>