import { Address, Identity, Name, PhoneNumber, Verified } from '@app/domain/models';

export interface PersonView {
    studentId: number;
    nsn?: {
        nsn: string;
        verification: Verification;
    },
    username: string;
    primaryName: {
        verification: Verification;
        name: string;
    };
    preferredName: string;
    titleName: string;
    birth: {
        verification: Verification;
        dateOfBirth: Date;
    };
    gender: string;
    ethnicities: string[];
    phoneNumbers: {
        type: string;
        number: string;
    }[];
    preferredEmail: {
        email: string;
        verified: boolean;
    };
    emails: {
        type: string;
        email: string;
        verified: boolean;
    }[];
    mailingAddress: string;
    citizenships: string[];
    residency?: {
        verification: Verification;
        country: string;
    };
    lastUpdated: Date;
}

interface Verification {
    verified: boolean;
    method: string;
    methodDescription: string;
}

const toName = (name: Name) => name ? [name.first, name.middle, name.last, name.suffix]
                .filter(n => n !== undefined).join(' ') : 'None';

const toAddress = (addr: Address) =>
                    addr ? [addr.line1, addr.line2, addr.line3, addr.suburb, `${addr.city} ${addr.postcode}`, addr.state, addr.country]
                        .filter((f) => f !== undefined)
                        .join('\n') : '';

const toPhone = (phone: PhoneNumber) => (phone ?
    (phone.countryCode ? '+' + phone.countryCode : '') +
    (phone.areaCode ?? '') +
    phone.number +
    (phone.extension ? ` ext ${phone.extension}` : '') : '');


const verificationSourceNames: {[index: string]: string} = {
    'B': 'Birth Certificate',
    'M': 'Births, Deaths, Marriages',
    'P': 'Passport',
    'O': 'Other',
    'L': "Driver's License",
    'V': 'Visa',
    'I': 'Immigration File',
    'D': 'Digital ID Wallet',
    'N': 'National Student Index',
    'C': 'Citizenship Certificate',
    'W': 'Marrige Certificate',
    'R': 'RealMe'
};


function mapVerificationMethod(verified: Verified): Verification {
    return {
        verified: verified !== undefined,
        method: verified?.source,
        methodDescription: verificationSourceNames[verified?.source] ?? verified?.source
    };
}

export function toViewModel(identity: Identity): PersonView {
    if (identity === undefined || identity === null) {
        return null;
    }

    const primary = identity.names.find((name) => name.type.toLowerCase() === 'primary');
    const primaryName = toName(primary);

    const preferred = identity.names.find((name) => name.type.toLowerCase() === 'preferred');
    const preferredName = toName(preferred);

    const titleName = preferred ? `${preferred.first} ${preferred.last}` : `${primary.first} ${primary.last}`;
    const mailingAddress = toAddress(identity.addresses.find((a) => a.type.toLowerCase() === 'mailing'));
    const nsn = identity.extIds?.find((id) => id.type.toLowerCase() === 'nsn');
    const residencyNames: {[index: string]: string} = {
        I: 'International',
        'AU-PR': 'Australian permanent resident',
        'NZ-PR': 'NZ permanent resident',
        'AU-C': 'Australian citizen',
        'NZ-C': 'NZ citizen'
    };

    const preferredEmail = identity.emails.find(e => e.email === identity.emailAddress);

    return {
        primaryName: {
            name: primaryName,
            verification : mapVerificationMethod(primary?.verified)
        },
        preferredName,
        titleName,
        studentId: identity.id,
        username: identity.upi,
        gender: identity.gender,
        nsn: {
            nsn: nsn?.id,
            verification : mapVerificationMethod(nsn?.verified)
        },
        birth: {
            verification : mapVerificationMethod(identity.dobVerified),
            dateOfBirth: identity.dob,
        },
        citizenships: identity.citizenships?.map((c) => c.country),
        ethnicities: identity.ethnicities?.map((e) => e.value),
        phoneNumbers: identity.phones?.map((p) => ({ type: p.type, number: toPhone(p) })) ?? ([]),
        preferredEmail,
        emails: identity.emails?.filter(email => email.email !== identity.emailAddress),
        mailingAddress,
        residency: {
            verification : mapVerificationMethod(identity.residencyVerified),
            country: residencyNames[identity.residency] ?? identity.residency,
        },
        lastUpdated: identity.whenUpdated
    };
}