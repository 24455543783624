import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Communication } from '@domain/models/communication';
import { APP_CONFIG } from '@core/providers';
import { AppConfiguration } from '@domain/models';

@Component({
  selector: 'app-communication-details',
  templateUrl: './communication-details.component.html',
  styleUrl: './communication-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicationDetailsComponent {
    @Input() communication: Communication;
    @Input() highlight: boolean;

    constructor(@Inject(APP_CONFIG) private configuration: AppConfiguration) {
    }

    get documentLink(): string {
        if (this.communication.documentPath) {
            return `${this.configuration.links.studentDocuments}${this.communication.documentPath}`;
        }

        return null;
    }

    get hasLinkedData() {
        return this.communication.academicProgramme?.career.description ||
                this.communication.applicationNumber ||
                this.communication.term?.academicYear ||
                this.communication.academicProgramme?.code ||
                this.communication.academicProgramme?.description;
    }

    get emailCc() {
        if (this.communication.emailCc) {
            return this.communication.emailCc.split(',');
        }

        return null;
    }
}
