<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError; else errorDisplay">
    <ng-container *ngIf="details?.length > 0; else empty">
        <ng-container *ngFor="let detail of details">
            <p class="bold margin-top-0">{{ detail.section }}</p>
            <ng-container *ngFor="let field of detail.fields">
                <app-field-list classList="margin-top-0 margin-bottom-0">
                    <app-field-value label="{{ field.fieldName }}">{{ field.value | default }}</app-field-value>
                </app-field-list>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template #empty>
        <h3 class="header__notfound">No data to display</h3>
    </ng-template>
</app-dataload-spinner>

<ng-template #errorDisplay>
    <h3 class="header__notfound">{{message}}</h3>
</ng-template>