<app-field-list classList="margin-top-0">
    <app-field-value label="Academic Plan">
        <ng-container *ngIf="details.plans?.length > 0; else noPlans">
            <ng-container *ngFor="let plan of details.plans">
                {{ plan.plan.code }} <br/>
            </ng-container>
        </ng-container>
        <ng-template #noPlans>
            No data
        </ng-template>
    </app-field-value>
    <app-field-value label="Plan Description">
        <ng-container *ngIf="details.plans?.length > 0; else noPlans">
            <ng-container *ngFor="let plan of details.plans">
                {{ plan.plan.description }} <br/>
            </ng-container>
        </ng-container>
        <ng-template #noPlans>
            No data
        </ng-template>
    </app-field-value>
</app-field-list>
<app-field-list>
    <ng-container *ngFor="let plan of details.plans">
        <app-field-value label="Academic Load">
            {{ details.studentProgramme.academicLoad.description | default }}
        </app-field-value>
        <app-field-value label="Acad Plan - Research">
            {{ plan.option?.description | default }}
        </app-field-value>
        <app-field-value label="Acad Plan - Points">
            {{ plan.points?.description | default}}
        </app-field-value>
        <app-field-value label="Acad Duration">
            {{ plan.duration?.description | default}}
        </app-field-value>
    </ng-container>
</app-field-list>
<app-field-list>
    <app-field-value label="Academic Career">
        {{ details.career.description | default }}
    </app-field-value>
    <app-field-value label="Academic Organisation">
        {{ details.department.description | default }}
    </app-field-value>
    <app-field-value label="Organisation Description">
        {{ details.programme.faculty.description | default}}
    </app-field-value>
</app-field-list>
<app-field-list>
    <app-field-value label="Research Topic/Thesis Title">{{ details.topic | default }}</app-field-value>
    <ion-row>
        <ion-col size="6">
            <app-field-value labelSize="8" valSize="4" label="Thesis Submission No">
                {{ details.submissionNumber | default }}
            </app-field-value>
            <app-field-value labelSize="8" valSize="4" label="Actual Submission Date">
                {{ details.actualSubmitStart | shortDate | default }}
            </app-field-value>
        </ion-col>
        <ion-col size="5" offset="1">
            <app-field-value labelSize="8" valSize="4" label="Thesis Result Date">
                {{ details.resultApprovedOn | shortDate | default }}
            </app-field-value>
            <app-field-value labelSize="8" valSize="4" label="Thesis Final Grade">
                {{ details.grade?.code | default }}
            </app-field-value>
        </ion-col>
    </ion-row>
</app-field-list>
<ion-grid class="bare-table ion-no-padding" *ngIf="details.advisors?.length > 0">
    <ion-row class="bare-table__thead margin-bottom-l">
        <ion-col class="bold">Supervisor ID</ion-col>
        <ion-col class="bold">Supervisor Name</ion-col>
        <ion-col class="bold">Workload</ion-col>
        <ion-col class="bold">Advisor Role</ion-col>
        <ion-col size="2" class="bold ion-text-center">Details</ion-col>
    </ion-row>
    <ion-row class="bare-table__tbody ion-align-self-start margin-bottom-m" *ngFor="let advisor of details.advisors">
        <ion-col>{{ advisor.id | default }}</ion-col>
        <ion-col>{{ advisor.name | default }}</ion-col>
        <ion-col>{{ advisor.percentage | default }}%</ion-col>
        <ion-col>{{ advisor.advisorRole.description | default }}</ion-col>
        <ion-col size="2" class="ion-text-center">
            <ion-button (click)="openModal(advisor)" fill="clear" class="ion-no-padding ion-no-margin">
                <app-icon name="info" slot="icon-only" size="large" colour="secondary"></app-icon>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>