import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { StudentAdapter } from '@core/adapters';
import { AppConfiguration, Concession, ServiceError } from '@domain/models';
import { groupBy } from 'ramda';
import { ConcessionsViewModel } from './concessions-view.model';
import { sortByProp } from '@app/util';
import dayjs from 'dayjs';
import { APP_CONFIG } from '@app/core/providers';

@Component({
  templateUrl: './concessions-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ConcessionsViewComponent extends PersonViewComponent{
    concessions: ConcessionsViewModel;
    isLoading = true;
    isError = false;
    message: string;
    concessionsLink: string;

    constructor(private student: StudentAdapter, @Inject(APP_CONFIG) private configuration: AppConfiguration) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.concessionsLink = `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.concessions}?EMPLID=${super.identity.id}`;

        this.student.getStudentConcessions(super.identity.id)
            .subscribe({
                next: result => {
                    this.concessions = this.buildViewModel(result);
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    buildViewModel(result: Concession[]): ConcessionsViewModel {
        const today = dayjs();

        result = sortByProp('descend', result, 'term.description');

        return groupBy((v: Concession) => {
            const startDate = dayjs(v.class.start).add(30, 'days');

            if (startDate.isBefore(today)) {
                return 'past';
            }

            return 'current';
        }, result);
    }
}
