import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ExternalTest, ExternalTestResult, ServiceError } from '@app/domain/models';
import { PersonViewComponent } from '../../model/person-view.component';
import { StudentAdapter } from '@app/core/adapters';

interface ExternalTestsViewModel {
    entrance?: {
        literacyAchieved: boolean;
        numeracyAchieved: boolean;
        universityEntranceAchieved: boolean;
    };
    embargoed: boolean;
    tests: (ExternalTest & {
        results?: ExternalTestResult[];
        isLoaded?: boolean;
        isError?: boolean;
    })[]
}

const NZ_SCHOOL_TESTS=['NCEA2', 'NCEA3', 'IB', 'CIE'];
const NZ_NCEA_TESTS=['NCEA2', 'NCEA3'];

@Component({
  templateUrl: './external-tests-view.component.html',
  styleUrl: './external-tests-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ExternalTestsViewComponent extends PersonViewComponent {
    openPanels = new Set<number>();
    isLoading = true;
    isError = false;
    message: string;
    externalTests: ExternalTestsViewModel;

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getExternalTests(super.identity.id)
            .subscribe( {
                next: (tests) => {
                    this.externalTests = {
                        tests: (tests ?? []).map((externalTest) => ({
                            ...externalTest,
                            results: null,
                            isLoaded: false,
                            isError: false
                        })),
                        embargoed: false
                    };

                    this.externalTests.embargoed = this.externalTests.tests.some((tst) => tst?.embargoed);

                    // Remove embargoed tests and include only NZ School tests
                    this.externalTests.tests = this.externalTests.tests.filter(tst => !tst.embargoed && NZ_SCHOOL_TESTS.includes(tst.test.code));

                    this.externalTests.entrance = {
                        universityEntranceAchieved: tests.some((e) => e.universityEntranceAchieved),
                        numeracyAchieved: tests.some((e) => e.numeracyAchieved),
                        literacyAchieved: tests.some((e) => e.literacyAchieved)
                    };

                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isError = true;
                    this.isLoading = false;
                }
        });
    }

    render() {
        this.load();
    }

    handleToggleDetails(index: number, testId: string) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);

            if (NZ_NCEA_TESTS.includes(testId) && !this.externalTests.tests[index].isLoaded) {
                this.externalTests.tests[index].isError = false;
                this.student.getExternalTestResults(super.identity.id.toString(), testId)
                    .subscribe({
                        next: result => {
                            this.externalTests.tests[index].results = result.filter(testResult =>
                                testResult.testedOn.getTime() === this.externalTests.tests[index].testedOn.getTime());
                            this.externalTests.tests[index].isLoaded = true;
                        },
                        error: () => {
                            this.externalTests.tests[index].isError = true;
                        }
                    });
            }
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    hasDetails(test: ExternalTest) {
        return test.testCentre?.testCentre !== undefined ||
            test.school?.testCentre !== undefined ||
            test.testCentre?.description !== undefined ||
            test.school?.description !== undefined ||
            NZ_NCEA_TESTS.includes(test.test.code);
    }
}
