import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { APP_CONFIG } from '@core/providers';
import { AppConfiguration, Invoice } from '@domain/models';

@Component({
  selector: 'app-tax-invoice',
  templateUrl: './tax-invoice.component.html',
  styleUrl: './tax-invoice.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class TaxInvoiceComponent {
    openPanels = new Set<number>();

    @Input() invoices: Invoice[];
    @Input() studentId: number;

    constructor(@Inject(APP_CONFIG) private configuration: AppConfiguration) {}

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    get taxInvoiceLink(): string {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.taxInvoice}?EMPLID=${this.studentId}`;
    }
}
