import { Component, Input } from '@angular/core';
import { ExternalTest, ExternalTestResult } from '@domain/models';
import { ExternalTestResultsViewModel } from './external-test-results-view.model';
import { uniq } from 'ramda';

const NZ_NCEA_TESTS=['NCEA2', 'NCEA3'];

@Component({
  selector: 'app-external-test-details',
  templateUrl: './external-test-details.component.html',
  styleUrl: './external-test-details.component.scss'
})
export class ExternalTestDetailsComponent {
    testRes: ExternalTestResultsViewModel[];

    @Input() test: ExternalTest;
    @Input() isLoaded: boolean;
    @Input() isError: boolean;
    @Input() set testResults(results: ExternalTestResult[]) {
        if (results) {
            // Flatten test results
            const flattenResults = [].concat(...results.map(item => {
                return item.details.map(detail => {
                    return Object.assign({}, {component: item.component}, { ...detail });
                });
            }));

            this.testRes = uniq(flattenResults);
        }
    }

    get isNceaTest() {
        return NZ_NCEA_TESTS.includes(this.test.test.code);
    }
 }
