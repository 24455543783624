import { Component, Input } from '@angular/core';
import { EnrolmentCart, EnrolmentCartTimetable } from '@domain/models';
import dayjs from 'dayjs';
import { sortByProp } from '@app/util';

@Component({
  selector: 'app-enrolment-cart-details',
  templateUrl: './enrolment-cart-details.component.html',
  styleUrl: './enrolment-cart-details.component.scss'
})
export class EnrolmentCartDetailsComponent {
    @Input() item: EnrolmentCart;

    get timetables() {
        return sortByProp('ascend', this.item.class.timetables, 'start');
    }

    classDay(timetable: EnrolmentCartTimetable) {
        return `${dayjs(timetable.start).format('DD/MM/YYYY')} to ${dayjs(timetable.end).format('DD/MM/YYYY')}`;
    }

    classTime(timetable: EnrolmentCartTimetable) {
        const time = `${timetable.startTime} - ${timetable.endTime}`;

        return `${timetable.days[0]} ${time}`;
    }
}
