import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Comment } from '@app/domain/models';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent {
    isLoading = true;
    isError = false;

    @Input() comments: Comment[];
    @Input() accordianHeader: string;

    openPanels = new Set<string>();

    isHideDetails(checklistId: string) {
        return this.openPanels.has(checklistId) ?? false;
    }

    handleToggleDetails(checklistId: string) {
        if (!this.openPanels.has(checklistId)) {
            this.openPanels.add(checklistId);
        } else {
            this.openPanels.delete(checklistId);
        }
    }
}
