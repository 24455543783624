<div class="class-type" *ngIf="eventTypes?.length > 0">
    <h2 *ngIf="calendarType === 'default'">Class Type</h2>
    <h2 *ngIf="calendarType === 'enrolment'">Enrolment Status</h2>
    <ion-grid class="ion-no-padding">
        <ion-row class="margin-bottom-m ion-wrap" *ngFor="let row of groupedEventTypes">
            <ion-col class="display-flex ion-align-self-start" *ngFor="let type of row">
                <div class="swatch swatch-{{ eventClassName(type) }}"></div>
                <span class="ion-text-capitalize">{{ type }}</span>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>

<ng-container *ngIf="event">
    <div class="details">
        <h2>Details</h2>
        <ion-grid>
            <ion-row class="margin-bottom-m" *ngIf="props.subject.code || props.catalogNumber">
                <ion-col size="auto" class="bold border-bottom-light-2">
                    {{ props.subject.code }} {{ props.catalogNumber }}
                </ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="props.description">
                <ion-col class="bold">{{ props.description }}</ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="calendarType === 'enrolment' && props.studentEnrolmentStatus?.description">
                <ion-col size="auto" class="bold pill pill-{{ props.studentEnrolmentStatus?.description.toLowerCase() }}">
                    <span class="">{{ props.studentEnrolmentStatus?.description }}</span>
                </ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="props.component.description">
                <ion-col size="auto"
                         [class]="calendarType === 'default'? 'bold pill pill-'+ eventClassName(props.component.description.toLowerCase()) : ''">
                    <span class="">{{ props.component.description }}</span>
                </ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="startTime || endTime">
                <ion-col>{{ startTime }} - {{ endTime }}</ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="props.room">
                <ion-col>Room {{ props.room }}</ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="props.building">
                <ion-col>Building {{ props.building }}</ion-col>
            </ion-row>
            <ion-row class="margin-bottom-m" *ngIf="props.instructors?.length > 0">
                <ion-col>
                    <ng-container *ngFor="let instructor of props.instructors">
                        {{ instructor.name }}<br/>
                    </ng-container>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ng-container>