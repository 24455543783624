import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TertiaryEducation } from '@domain/models';

@Component({
  selector: 'app-tertiary-education',
  templateUrl: './tertiary-education.component.html',
  styleUrl: './tertiary-education.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TertiaryEducationComponent {
    @Input() education: TertiaryEducation[];

    qualification(education: TertiaryEducation): string {
        let qualificationText = education.degree.description;
        if (education.degree.code) {
            qualificationText += ` (${education.degree.code})`;
        }
        return qualificationText;
    }
}
