<app-field-list classList="margin-0">
    <app-field-value label="Degree Description">{{ degree.degree.description | default }}</app-field-value>
    <app-field-value label="Academic Plan">
        <ng-container *ngIf="degree.plans?.length > 0; else noPlans">
            <ng-container *ngFor="let plan of degree.plans">
                {{ plan.code }} - {{ plan.description }} <br/>
            </ng-container>
        </ng-container>
        <ng-template #noPlans>
            No data
        </ng-template>
    </app-field-value>
    <app-field-value label="Honours">{{ degree.honoursSuffix?.description | default }}</app-field-value>
    <app-field-value label="Degree Status">{{ degree.status.description | default }}</app-field-value>
    <app-field-value label="Status Date">{{ statusDate | shortDate | default }}</app-field-value>
</app-field-list>