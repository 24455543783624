<ng-container *ngIf="tuitionFees; else noTransactions">
    <ion-grid class="ion-no-padding">
        <ion-row class="background-light-1 ion-align-items-center padding-top-l padding-bottom-l padding-left-l padding-right-l">
            <ion-col size-lg="4" class="bold ion-margin-end">Total Tuition Fees owing</ion-col>
            <ion-col size-lg="6" class="bold background-light padding-top-m padding-bottom-m padding-left-m padding-right-m">
                {{ tuitionFees.accountBalance || 0.00 | currency }}
            </ion-col>
        </ion-row>
    </ion-grid>

    <div class="transaction-history" *ngIf="tuitionFees.transactions?.length > 0">
        <div class="display-flex ion-justify-content-between">
            <h3 class="color-primary">Transaction History</h3>
            <ion-button class="margin-top-l margin-bottom-s" [href]="feesLink" target="_blank"  fill="outline" color="primary" size="default" shape="round">
                CS9 Fees
            </ion-button>
        </div>
        <ion-grid class="ion-no-padding margin-bottom-l">
            <ion-row>
                <ion-col size="auto">
                    <ion-item color="primary">
                        <ion-select [value]="defaultTerm" (ionChange)="onChangeTerm($event)" aria-label="Academic Year" [interfaceOptions]="customPopoverOptions" interface="popover" placeholder="Select Term">
                            <ng-container *ngFor="let transactionTerm of tuitionFees.transactions">
                                <ion-select-option [value]="transactionTerm.term">{{ transactionTerm.term }} Academic Year</ion-select-option>
                            </ng-container>
                        </ion-select>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ion-grid>

        <ion-grid class="table ion-no-padding margin-top-l">
            <div class="table__thead">
                <ion-row>
                    <ion-col size="2" class="ion-text-center">
                        Transaction Date
                    </ion-col>
                    <ion-col size="2">
                        Term
                    </ion-col>
                    <ion-col size="4">
                        Description
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                        Amount
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                        Details
                    </ion-col>
                </ion-row>
            </div>
            <div class="table__tbody">

                <ng-container *ngFor="let transaction of transactions; even as isEven; index as i">
                    <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                        <ion-col size="2" class="ion-text-center">{{ transaction.transactionDatetime | shortDate | default }}</ion-col>
                        <ion-col size="2">{{ transaction?.term?.code | default }}</ion-col>
                        <ion-col size="4">{{ transaction.description | default }}</ion-col>
                        <ion-col size="2" class="ion-text-right">{{ transaction.amount | currency | default }}</ion-col>
                        <ion-col size="2" class="ion-text-center">
                            <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"
                                    [disabled]="transaction?.term?.code === null || transaction.description.toLowerCase() === 'student services fee'" />
                        </ion-col>
                    </ion-row>
                    <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                        <ion-col size="12">
                            <app-tuition-fee-details [transaction]="transaction" />
                        </ion-col>
                    </ion-row>
                </ng-container>

            </div>
        </ion-grid>
    </div>

    <div *ngIf="tuitionFees.feesFree" class="fees-free margin-top-l">
        <h3 class="color-primary">Fees Free</h3>
        <div class="margin-top-l background-light-1 padding-top-l padding-bottom-l padding-left-l padding-right-l">
            <app-field-list>
                <app-field-value label="Fees Free Year">{{ tuitionFees.feesFree.year | default }}</app-field-value>
                <app-field-value label="Effective Date">{{ tuitionFees.feesFree.effectiveDate | shortDate | default }}</app-field-value>
            </app-field-list>
            <div class="padding-top-l padding-bottom-l">
                <app-field-list>
                    <app-field-value label="Eligibility Status">
                        <span class="background-light padding-top-s padding-bottom-s padding-right-s padding-left-s">
                            {{ tuitionFees.feesFree.eligible | default }}
                        </span>
                    </app-field-value>
                </app-field-list>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noTransactions>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>