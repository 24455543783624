import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '../../model/person-view.component';
import { StudentAdapter } from '@core/adapters';
import { NamedLink, ServiceError } from '@domain/models';
import { Communication } from '@domain/models/communication';
import { InfiniteScrollCustomEvent } from '@ionic/core';
import { finalize } from 'rxjs';

@Component({
    templateUrl: './communications-view.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class CommunicationsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    communications: Communication[];
    links: NamedLink;

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getCommunications(super.identity.id)
            .subscribe({
                next: result => {
                    this.communications = result.data;
                    // eslint-disable-next-line no-underscore-dangle
                    this.links = result._links;
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    onIonInfinite(event: InfiniteScrollCustomEvent) {
        if (this.links?.next) {
            this.student.getCommunicationsByUrl(this.links?.next.href)
                .pipe(finalize(() => event.target.complete()))
                .subscribe({
                    next: result => {
                        if (result.data) {
                            this.communications = this.communications.concat(result.data);
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        this.links = result._links;
                    }
                });
        } else {
            event.target.complete();
        }
    }
}