import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { LoginService } from '@uoa/auth';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

type GTMWindow = Window & typeof globalThis & {dataLayer?: object[]};
const PERSON_ID_PATH_MATCH=/(\/person)\/(\d+)(.*)/;

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  public isInitialised = false;

  constructor (
      private login: LoginService,
      private googleTagManager: GoogleTagManagerService,
      private router: Router,
      private title: Title,
    ) {

    }

  /**
   * Add GTM to the DOM and register router listener to automatically push route changes
   *
   * @returns
   */
  async initialise(): Promise<void> {
    if (!this.googleTagManager) {
      return;
    }

    /**
     * Initialise the data layer with custom user information before starting.
    */
    const userId = await this.getSessionId();
    const global: GTMWindow = window;

    global.dataLayer = global.dataLayer || [];
    global.dataLayer.push({
      'userId': userId,
      event: 'setUserId'
    });

    await this.googleTagManager.addGtmToDom();

    this.router.events.subscribe(item => {
      if (item instanceof NavigationEnd) {
          let path = item.url;

          /**
           * If the path contains a student id then remove the id
           * so /person/123456/submitted-applications becomes /person/submitted-applications.
           */
          const matches = item.url.match(PERSON_ID_PATH_MATCH);
          if (matches?.length > 0) {
            // For paths like /person/123456 = /person (removing the person id)
            path = matches[1];

            // If the regular expression matches a view then append this. For example, /person/123456/personal = /person/personal
            if (matches[3] !== undefined) {
              path += matches[3];
            }
          }

          // Remove any query parameters
          const base = path.split('?');
          if (base?.length > 0) {
            path = base[0];
          }

          // Removes name in title
          const pageTitle = this.title.getTitle().split('-')[1]?.trim() || this.title.getTitle();

          const gtmTag = {
              event: 'pageView',
              pagePath: path,
              pageTitle: pageTitle
          };

          this.googleTagManager.pushTag(gtmTag);
      }
    });

    this.isInitialised = true;
  }

  private userId: string;
  private async getSessionId(): Promise<string> {
    if (!this.login) {
      return undefined;
    }

    if (!this.userId) {
      const user = await this.login.getUserInfo();
      const saltedId = `${user.upi ?? 'upi'}:${user.userId ?? 999999999}`;
      this.userId = await this.digest(saltedId);
    }

    return this.userId;
  }

  private async digest(value: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(value);

    const buffer = await crypto.subtle.digest('SHA-256', data);
    const arr = Array.from(new Uint8Array(buffer));

    return arr.map(v => v.toString(16).padStart(2, '0')).join('');
  }
}

export const TagManagerServiceProvider={ provide: TagManagerService, useClass: TagManagerService };