import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Progress } from '@domain/models/progress';

@Component({
  selector: 'app-student-progress-details',
  templateUrl: './student-progress-details.component.html',
  styleUrl: './student-progress-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentProgressDetailsComponent {
    @Input() progress: Progress;
}
