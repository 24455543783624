<ng-container *ngIf="!isLoading">
    <ion-grid class="ion-no-padding">
        <ion-row class="">
            <ion-col size="3" class="padding-right-l">
                <div id="timetableMonthCalendar">
                    <full-calendar id="monthCalendar" [options]="monthOptions" [events]="events"/>
                    <app-timetable-event-details [calendarType]="calendarType" [event]="selected" [eventTypes]="types" />
                </div>
            </ion-col>
            <ion-col size="9">
                <div id="timetableWeekCalendar">
                    <full-calendar id="weekCalendar" [options]="weekOptions" [events]="events">
                        <ng-template #eventContent let-arg>
                            <div class="fc-event-main-frame">
                                <div class="fc-event-time bold">{{ arg.event.title }}</div>
                                <div class="fc-event-title-container">
                                    <div class="fc-event-title fc-sticky">
                                        {{toTime(arg.event.start)}} - {{toTime(arg.event.end)}}
                                        <br/>
                                        <span class="bold">{{ arg.event.extendedProps.component.description }}</span>
                                    </div>
                                </div>
                            </div>                            
                        </ng-template>
                    </full-calendar>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-container>