<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2>NZ School Results</h2>

        <ng-container *ngIf="externalTests?.tests?.length > 0; else noExternalTests">
            <ng-container *ngIf="externalTests?.entrance && !externalTests?.embargoed">
                <ion-grid class="entrance">
                    <ion-row class="ion-align-items-center">
                        <ion-col size="4">
                            <div class="entrance__content">
                                <app-boolean [value]="externalTests.entrance.universityEntranceAchieved"></app-boolean>
                                <ion-label>UE Achieved</ion-label>
                            </div>
                        </ion-col>
    
                        <ion-col size="4">
                            <div class="entrance__content">
                                <app-boolean *ngIf="externalTests.entrance.numeracyAchieved === true" [value]="externalTests.entrance.numeracyAchieved"></app-boolean>
                                <ion-label>Numeracy Achieved</ion-label>
                            </div>
                        </ion-col>
    
                        <ion-col size="4">
                            <div class="entrance__content">
                                <app-boolean *ngIf="externalTests.entrance.literacyAchieved === true" [value]="externalTests.entrance.literacyAchieved"></app-boolean>
                                <ion-label>Literacy Achieved</ion-label>
                            </div>
                        </ion-col>    
                    </ion-row>            
                </ion-grid>    
            </ng-container>

            <ion-grid class="table ion-no-padding">
                <div class="table__thead">
                    <ion-row>
                        <ion-col size="4">
                            Test/Qual.
                        </ion-col>                        
                        <ion-col size="2">
                            Candidate Code
                        </ion-col>                        
                        <ion-col size="2" class="ion-text-center">
                            Rank Score
                        </ion-col>
                        <ion-col size="2" class="ion-text-center">
                            Test Date
                        </ion-col>
                        <ion-col size="2" class="ion-text-center">
                            Details
                        </ion-col>
                    </ion-row>
                </div>
                <div class="table__tbody">
                    <ng-container *ngFor="let test of externalTests.tests; even as isEven; index as i">
                        <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                            <ion-col size="4">{{ test.qualification?.description ?? test.test.description }}</ion-col>
                            <ion-col size="2">{{ test.candidateCode }}</ion-col>
                            <ion-col size="2" class="ion-text-right">{{ test.rankScore }}</ion-col>
                            <ion-col size="2" class="ion-text-center">{{ test.testedOn | shortDate | default }}</ion-col>
                            <ion-col size="2" class="ion-text-center">
                                <app-details-button [disabled]="!hasDetails(test)" [id]="i" (toggled)="handleToggleDetails(i, test.test.code)"></app-details-button>
                            </ion-col>
                        </ion-row>
                        <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)">
                            <ion-col size="12">
                                <app-external-test-details [test]="test" 
                                    [testResults]="test.results" 
                                    [isLoaded]="test.isLoaded" 
                                    [isError]="test.isError" />
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </div>
            </ion-grid>
        </ng-container>
        <ng-template #noExternalTests>
            <h3 class="header__notfound">No data to display</h3>
        </ng-template>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>