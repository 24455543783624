import { Component } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import { GraduationProgramme, ServiceError } from '@domain/models';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { GraduationApplicationsViewModel } from './graduation-applications-view.model';
import { sortByProp } from '@app/util';
import dayjs from 'dayjs';

@Component({
  selector: 'app-graduation-applications-view',
  templateUrl: './graduation-applications-view.component.html',
  styleUrl: './graduation-applications-view.component.scss'
})
export class GraduationApplicationsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    graduations: GraduationApplicationsViewModel[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getGraduationInfo(super.identity.id)
            .subscribe(
                {
                    next: (result: GraduationProgramme) => {
                        this.graduations = this.buildViewModel(result);
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isError = true;
                        this.isLoading = false;
                    },
                });
    }

    render() {
        this.load();
    }

    buildViewModel(result: GraduationProgramme): GraduationApplicationsViewModel[] {
        let graduation = result.graduation;
        const programmes = result.programmes;
        const specificCode = 'P';

        // Maps plans to graduation from programmes
        graduation = graduation.map(v => {
            const found = programmes.find(p =>
                p.careerNumber === v.studentCareerNumber && p.programme.career.code === v.career.code);

            return {
                ...v,
                plans: found?.plans || []
            };
        });

        // Sort by event date
        graduation = sortByProp('descend', graduation, 'eventDate');

        // Sort in-person status first when conjoint degrees
        graduation.sort((a, b) => {
            if (dayjs(a.eventDate).isSame(b.eventDate)) {
                if (a.status.code === specificCode && b.status.code !== specificCode) {
                    return -1;
                }

                if (a.status.code !== specificCode && b.status.code === specificCode) {
                    return 1;
                }
            }

            return 0;
        });

        return graduation;
    }
}
