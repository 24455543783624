import { Component } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import { EnrolmentCart, ServiceError } from '@domain/models';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { sortByProp } from '@app/util';
import { ModalController } from '@ionic/angular';
import { EnrolmentCartCalendarComponent } from '@modules/person/components';

@Component({
  selector: 'app-enrolment-cart-view',
  templateUrl: './enrolment-cart-view.component.html',
  styleUrl: './enrolment-cart-view.component.scss'
})
export class EnrolmentCartViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    cart: EnrolmentCart[];

    constructor(private adapter: StudentAdapter, private modalCtrl: ModalController) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.adapter.getEnrolmentCart(super.identity.id)
            .subscribe({
                next: (result: EnrolmentCart[]) => {
                    this.cart = sortByProp('descend', result, 'term.start');
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isError = true;
                    this.isLoading = false;
                }
            });
    }

    render() {
        this.load();
    }

    async openEnrolmentCartCalendar() {
        const modal = await this.modalCtrl.create({
            componentProps: {
                cart: this.cart
            },
            component: EnrolmentCartCalendarComponent,
            cssClass: 'timetable-modal'
        });

        void modal.present();
    }
}
