<app-field-list classList="margin-top-0">
    <ion-row>
        <ion-col class="ion-no-padding" size="6">
            <app-field-value label="Course ID">{{ course.primaryClass.class.course.code | default }}</app-field-value>
            <app-field-value label="Description">{{ course.primaryClass.class.description | default }}</app-field-value>
        </ion-col>
        <ion-col class="ion-no-padding" size="6">
            <app-field-value label="Catalogue No">{{ course.primaryClass.class.catalogNumber | default }}</app-field-value>
        </ion-col>
    </ion-row>
</app-field-list>

<ng-container *ngFor="let item of course.classList">
    <app-field-list classList="margin-bottom-0">
        <div class="container">
            <ion-row class="padding-bottom-m">
                <ion-col size="auto">
                    <span class="bold color-dark-1 border-bottom-light-2">{{ item.class.component.description }}</span>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="ion-no-padding" size="6">
                    <app-field-value label="Class No">{{ item.classNumber | default }}</app-field-value>
                    <app-field-value label="Section">{{ item.class.section | default }}</app-field-value>
                    <app-field-value label="Class Start">{{ item.class.start | shortDate | default }}</app-field-value>
                    <app-field-value label="Class End">{{ item.class.end | shortDate }}</app-field-value>
                    <app-field-value label="Session">{{ item.session.description | default }}</app-field-value>
                </ion-col>
                <ion-col class="ion-no-padding" size="6">
                    <app-field-value label="Location">{{ item.class.campus.description | default }}</app-field-value>
                    <app-field-value label="Status">{{ item.status.description| default }}</app-field-value>
                    <app-field-value label="Status Reason">{{ item.statusReason.description | default }}</app-field-value>
                    <ng-container *ngIf="item.coursePoints && item.status.code === 'E'">
                        <app-field-value label="Points">{{ item.coursePoints | default }}</app-field-value>
                    </ng-container>
                    <app-field-value label="Class Notes">
                        <ng-container *ngIf="item.class.notes?.length > 0; else noNotes">
                            <app-text-truncate [text]="classNotes(item.class.notes)" />
                        </ng-container>
                        <ng-template #noNotes>
                            No data
                        </ng-template>
                    </app-field-value>
                </ion-col>
            </ion-row>
        </div>
    </app-field-list>
</ng-container>