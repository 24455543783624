import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { BypassErrorService } from '@uoa/error-pages';
import { AppConfiguration, ResourcePathsConfiguration } from '@domain/models';
import { allISO8601ToDate } from '@app/util';

type Parameters = {[index: string]: unknown};
type Resources = keyof ResourcePathsConfiguration;
type RequestOptions = {
    /**
     * URL path parameters
    */
    params?: Parameters;

    /**
     * URL query parameters
     */
    query?: Parameters;

    /**
     * If true, then the caller will handle the error, otherwise it will redirect to the default error page
     */
    handleError?: boolean;
};

const URL_PATTERN=/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;

export class BaseHttpService {
    constructor(
        private configuration: AppConfiguration,
        private http: HttpClient,
        private errorBypass: BypassErrorService) {

        }

    protected buildRequestUrl(resource: Resources, options?: RequestOptions): string {
        let path = this.configuration.api.paths[resource];

        if (options?.params) {
            for (const key in options.params) {
                const template = `{${key}}`;
                path = path.replace(template, `${options.params[key]}`);
            }
        }

        if (options?.query) {
            const q = Array.from(Object.keys(options.query)).map(k => `${k}=${options.query[k]}`).join('&');
            path = path + '?' + q;
        }

        if (URL_PATTERN.test(path)) {
            return path;
        }

        return new URL(path, this.configuration.api.baseUrl).href;
    }

    private getRandomHex(bytesLength=16): string {
        // Bytes Length is the number of bytes to generate. Each byte is two hex digits.
        const random = crypto.getRandomValues(new Uint8Array(bytesLength));

        return random
                .reduce((hex, value) => hex + value.toString(16).padStart(2, '0'), '');
    }

    protected get<T>(url: string): Observable<T> {
        /**
         * X-B3-TraceId is a 128 bit random value
         * X-B3-SpanId is a 64 bit random value
         */
        const headers = {
            'X-B3-TraceId': this.getRandomHex(),
            'X-B3-SpanId': this.getRandomHex(8)
        };

        return this.http.get<T>(url, {headers});
    }

    /**
     * Get resource by name in configuration.api.paths
     *
     * @param resource
     * @param options
     *
     * @returns JSON object of type T
     */
    protected getResource<T>(resource: Resources, options?: RequestOptions): Observable<T> {
        const url = this.buildRequestUrl(resource, options);

        if (options?.handleError) {
            this.errorBypass.bypassError(url, [400, 401, 404, 403, 500, 501, 502, 503, 504]);
        }

        return this.get<T>(url).pipe(map(data => allISO8601ToDate<T>(data)));
    }

    /**
     * Get resource by URL provided
     *
     * @param url
     * @param handleError
     *
     * @returns JSON object of type T
     */
    protected getResourceByUrl<T>(url: string, handleError: boolean): Observable<T> {
        if (handleError) {
            this.errorBypass.bypassError(url, [400, 401, 404, 403, 500, 501, 502, 503, 504]);
        }

        return this.get<T>(url).pipe(map(data => allISO8601ToDate<T>(data)));
    }
}