<ng-container *ngIf="transcripts?.length > 0; else noTranscripts">
    <ion-grid class="table ion-no-padding margin-top-l">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">
                    Request Date
                </ion-col>
                <ion-col size="3">
                    Doc Type
                </ion-col>
                <ion-col size="3">
                    Current Status
                </ion-col>
                <ion-col size="2">
                    Action Reason
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">

            <ng-container *ngFor="let transcript of transcripts; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2" class="ion-text-center">{{ transcript.requestedOn | shortDate | default }}</ion-col>
                    <ion-col size="3">{{ transcript?.documentType?.description | default }}</ion-col>
                    <ion-col size="3">{{ transcript.status.description | default }}</ion-col>
                    <ion-col size="2">{{ transcript?.reason?.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)" />
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-transcript-details [transcript]="transcript" />
                    </ion-col>
                </ion-row>
            </ng-container>

        </div>
    </ion-grid>
</ng-container>

<ng-template #noTranscripts>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>