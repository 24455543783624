import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Delna } from '@domain/models';

@Component({
  selector: 'app-delna',
  templateUrl: './delna.component.html',
  styleUrl: './delna.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelnaComponent {
    @Input() delna: Delna[];
}
