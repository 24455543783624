import { Component } from '@angular/core';
import { RecentlyViewed, RecentlyViewedService } from '@core/services/recently-viewed.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-recently-viewed',
  templateUrl: './recently-viewed.component.html',
  styleUrl: './recently-viewed.component.scss'
})
export class RecentlyViewedComponent {
  recent: RecentlyViewed[] = [];

  constructor(private recentlyViewed: RecentlyViewedService) {
    this.recentlyViewed
          .getRecentlyViewed()
          .pipe(takeUntilDestroyed())
          .subscribe(recent => this.recent = recent);
  }

  clear() {
    this.recentlyViewed.clearAll();
  }
}
