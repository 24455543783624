import { Component, Input } from '@angular/core';
import { Checklist, ChecklistItem } from '@domain/models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styleUrl: './checklist-modal.component.scss'
})
export class ChecklistModalComponent {
    @Input() checklist: Checklist;
    @Input() item: ChecklistItem;

    constructor(private modalCtrl: ModalController) {}

    close() {
        return this.modalCtrl.dismiss(null, 'close');
    }
}
