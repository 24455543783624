import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
    @Input() selectedTab: string;
    @Input() tabLabels: string[];
    @Output() tabChange = new EventEmitter<string>();

    handleChange(e: CustomEvent) {
        this.tabChange.emit(e.detail.value);
    }
}
