<ng-container *ngIf="identity">
    <ion-toolbar class="identity-summary font-default" color="primary">
        <ion-title class="ion-no-padding">
            <ion-grid class="ion-no-padding">
                <ion-row>
                    <ion-col size="12" class="display-flex">
                        <ion-menu-button class="ion-hide-xl-up ion-no-padding" (click)="menuClick()"></ion-menu-button>
                        <ion-back-button class="ion-text-capitalize not-bold ion-hide-xl-down" icon="chevron-back-outline" text="Back to Search" default-href="#"></ion-back-button>
                        <div class="identity-summary__container">
                            <div class="identity-summary__details font-default ion-text-uppercase">
                                {{identity.firstName}} {{identity.lastName}}
                            </div>
                            <div class="identity-summary__details not-bold font-default ion-align-self-center">
                                ID: {{identity.id}}
                            </div>
                            <div class="identity-summary__details not-bold font-default ion-align-self-center">
                                {{ residency }}
                            </div>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-title>

        <app-banner (dismissed)="dismissErrorBanner()" *ngIf="isErrorBannerOpen && errorMessage && errorMessage !==''">
            <div class="banner-content">
                <div class="banner-content__icon">
                    <app-icon name="warning" size="large" colour="primary"></app-icon>
                </div>
                <p class="banner-content__message">{{errorMessage}}</p>
            </div>
        </app-banner>
    </ion-toolbar>
</ng-container>
