import { Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ResearchProgramme, ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import {
    ResearchInformationViewModel
} from '@modules/person/components/research-information-view/research-information-view.model';

@Component({
  selector: 'app-research-information-view',
  templateUrl: './research-information-view.component.html',
  styleUrl: './research-information-view.component.scss'
})
export class ResearchInformationViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    research: ResearchInformationViewModel[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getResearchProgramme(super.identity.id)
            .subscribe(
                {
                    next: (result: ResearchProgramme) => {
                        this.research = this.buildViewModel(result);
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isError = true;
                        this.isLoading = false;
                    },
                });
    }

    render() {
        this.load();
    }

    buildViewModel(result: ResearchProgramme) {
        const research = result.research;
        const programmes = result.programmes;

        return research.map((r) => ({
                    ...r,
                    studentProgramme: programmes.filter(p => r.careerNumber === p.careerNumber)[0]
                }));
    }
}
