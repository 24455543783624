<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <div class="content-header">
            <h2>Concessions</h2>
            <ion-button color="primary" fill="outline" shape="round" size="small" [href]="concessionsLink" target="_blank">
                Concessions in CS9
            </ion-button>
        </div>

        <app-concessions [concessions]="concessions" />

    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>