import { Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ServiceError, StudentFees, Transaction } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { groupBy } from 'ramda';
import { sortByProp } from '@app/util';
import { FeesFreeView, TransactionsView, TuitionFeesViewModel } from './tuition-fees-view.model';
import dayjs from 'dayjs';

@Component({
  templateUrl: './tuition-fees-view.component.html'
})
export class TuitionFeesViewComponent extends PersonViewComponent {
    tuitionFees: TuitionFeesViewModel;
    isLoading = true;
    isError = false;
    message: string;

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getStudentFees(super.identity.id)
            .subscribe({
                next: result => {
                    this.tuitionFees = this.buildViewModel(result);
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    buildViewModel(result: StudentFees): TuitionFeesViewModel {
        // Sort latest transactions by transaction date
        const transactions = sortByProp('descend', result.fees.transactions, 'transactionDatetime');

        // Group transactions by academic year
        const groupTransactions = groupBy((v: Transaction) => {
            if (v?.term?.academicYear) {
                return v.term.academicYear;
            }

            return dayjs(v.transactionDatetime).format('YYYY');
        }, transactions);

        // Maps transactions view model
        const transactionsView: TransactionsView[] = Object
                            .keys(groupTransactions)
                            .map(key => ({ term: key, transactions: groupTransactions[key] }));

        let feesFree: FeesFreeView;

        if (result.feesFree) {
            feesFree = {
                year: result.feesFree.startTerm?.academicYear,
                effectiveDate: result.feesFree.startOn,
                eligible: result.feesFree.positive ? 'Yes' : 'No'
            };
        }

        return {
            accountBalance: result.fees.accountBalance,
            transactions: sortByProp('descend', transactionsView, 'term'),
            feesFree
        };
    }
}
