import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';
import { StudentAdapter } from '@core/adapters';
import { Checklist, Comment } from '@app/domain/models';

@Component({
  selector: 'app-offer-conditions-modal',
  templateUrl: './offer-conditions-modal.component.html',
  styleUrl: './offer-conditions-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OfferConditionsModalComponent implements OnInit {
  isLoading = true;
  isError = false;

  @Input() application: SubmittedApplicationsViewModel;
  offerConditionsChecklist: Checklist;
  offerConditionsComment: Comment;


  constructor(private modalCtrl: ModalController, private studentAdapter: StudentAdapter) {}

  ngOnInit() {
    this.studentAdapter.getConditionalOfferChecklists(this.application.studentId, this.application.applicationNumber).subscribe({
      next: (result) => {
        this.offerConditionsChecklist = result.checklist;
        this.offerConditionsComment = result.comment;
        this.isLoading = false;
        this.isError = false;
      },
      error: () => {
        this.isLoading = false;
        this.isError = false;
      },
    });
  }

  close() {
    void this.modalCtrl.dismiss();
  }
}
