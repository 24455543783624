import { Component, Input } from '@angular/core';
import { Identity } from '@app/domain/models';

/**
 * Abstract base component class for view components that are context aware
 */
@Component({
    template: ''
})
export abstract class PersonViewComponent {
    private personInView: Identity;

    @Input({required: true})
    set identity(identity: Identity) {
        this.personInView = identity;
        if (this.personInView) {
            this.render(this.personInView);
        }
    }

    /**
     * Get the current person/identity in view
     */
    get identity(): Identity {
        return this.personInView;
    }

    abstract render(identity: Identity): void;
}