import { Component, Input } from '@angular/core';
import { ResearchAdvisor } from '@domain/models';
import { ModalController } from '@ionic/angular';
import { ResearchInformationViewModel } from '../research-information-view.model';

@Component({
  selector: 'app-research-information-modal',
  templateUrl: './research-information-modal.component.html',
  styleUrl: './research-information-modal.component.scss'
})
export class ResearchInformationModalComponent {
    @Input() research: ResearchInformationViewModel;
    @Input() advisor: ResearchAdvisor;

    constructor(private modalCtrl: ModalController) {
    }


    close() {
        void this.modalCtrl.dismiss();
    }
}
