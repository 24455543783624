import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Identity, PhoneNumber, Address, Name } from '@domain/models';

/**
 * Identity Search result card (Immutable)
 */
@Component({
  selector: 'app-search-result-card',
  templateUrl: './search-result-card.component.html',
  styleUrls: ['./search-result-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultCardComponent {
    @Input({required: true}) identity!: Identity;

    public toTelephone(phone: PhoneNumber): string {
      return (phone ? (phone.countryCode ? '+' + phone.countryCode : '') +
              (phone.areaCode ?? '') +
              phone.number +
              (phone.extension ? ` ext ${phone.extension}` : '') : '');
    }

    public toAddress(addr: Address): string {
      return addr ? [addr.line1, addr.line2, addr.line3, addr.suburb, `${addr.city} ${addr.postcode}`, addr.state, addr.country]
          .filter((f) => f !== undefined)
          .join(', ') : '';
    }

    public toFullName(name: Name): string {
      return name ? [name.first, name.middle, name.last, name.suffix].join(' ') : 'None';
    }

    public get mailingAddress(): Address {
      if (!this.identity) {
        return null;
      }

      return this.identity.addresses?.find((a) => a.type.toLowerCase() === 'mailing');
    }

    public get preferredName(): Name {
      if (!this.identity) {
        return null;
      }

      return this.identity.names?.find((a) => a.type.toLowerCase() === 'preferred');
    }

    public get primaryName(): Name {
      if (!this.identity) {
        return null;
      }

      return this.identity.names?.find((a) => a.type.toLowerCase() === 'primary');
    }
}
