<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col>
                Event Description
            </ion-col>
            <ion-col>
                Degree
            </ion-col>
            <ion-col>
                In Person/Absentia
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="graduations?.length > 0; else emptyGraduations">
            <ng-container *ngFor="let graduation of graduations; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col>{{ graduation.description | default }}</ion-col>
                    <ion-col>{{ graduation.degree.description | default }}</ion-col>
                    <ion-col>{{ graduation.status.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-graduation-application-details [highlight]="isEven" [details]="graduation" [index]="i" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #emptyGraduations>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>