/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-rest-params */
import { CacheManager } from './cache-manager';
import { InMemoryCache } from './in-memory-cache';
import { Observable, of, map } from 'rxjs';
import {clone} from 'ramda';

const cacheManager = new CacheManager(new InMemoryCache());

/**
 * Method signature for cacheable
 */
type CacheableFunction = (...args: any[])=> Observable<unknown>;

/**
 * Cache or invalidate cache based method name and cache key as first parameter.
 *
 * The cacheable function must conform to (key: string, ...other: any[]) => Promise<any>
 *
 * @param manager
 */
export function cacheable(expiry?: number) {
	return function factory(
		target: any,
		propertyKey: string,
		descriptor: TypedPropertyDescriptor<CacheableFunction>
	) {
		// Wrapped method
		const method = descriptor.value;
		// Name of method
		const name = propertyKey;

		// eslint-disable-next-line no-param-reassign
		descriptor.value = function interceptor(...args: any[]): Observable<any> {
			// Calculate the key from all the args.
			const key = args?.map(arg => ''+arg).join('.') ?? '-';

			// If the value is availble in the cache then return immediately and do not invoke method
			const cached = cacheManager.get<any>(name, key);
			if (cached) {
				return of(clone(cached));
			}

			// Invoke method and observe result
			const result: Observable<any> = method.apply(this, [...args]);
			return result.pipe(
				map(value => {
					cacheManager.cache(name, key, value, expiry);

					return clone(value);
				})
			);
		};
	};
}