import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PriorityGroup, ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { sortByProp } from '@app/util';

@Component({
  templateUrl: './priority-groups-view.component.html',
  styleUrl: './priority-groups-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class PriorityGroupsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    priorityGroups: PriorityGroup[];

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getPriorityGroups(super.identity.id)
            .subscribe({
                next: result => {
                    // Sort alphabetical
                    this.priorityGroups = sortByProp('ascend', result, 'description');

                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    get hasPriorityGroups() {
        return this.priorityGroups?.filter(group => group.hasPriority === true)?.length > 0;
    }
}
