import { TagManagerService } from '@core/services';

/**
 * Perform actions immediately post application initialisation.
 */
export function applicationInitFactory(tagManager: TagManagerService) {
    return async () => {
        // Application init should never fail
        try {
            tagManager.initialise();
        } catch (ex) {
            // Do nothing
        }

    };
}