import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    EducationBackground,
    EnglishLanguageEducation,
    EnglishLanguageProficiency
} from '@app/domain/models';

@Component({
  selector: 'app-english-language-info',
  templateUrl: './language-info.component.html',
  styleUrl: './language-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnglishLanguageInfoComponent {
    openPanels = new Set<number>();
    educationBackground: EducationBackground;
    englishLanguageEducation: EnglishLanguageEducation;

    @Input() set englishProficiency(englishLangProf: EnglishLanguageProficiency) {
        this.educationBackground = englishLangProf?.educationBackground;
        this.englishLanguageEducation = englishLangProf?.englishLanguageEducation;
    }

    handleToggleDetails(rowId: number) {
        if (!this.openPanels.has(rowId)) {
            this.openPanels.add(rowId);
        } else {
            this.openPanels.delete(rowId);
        }
    }

    isHideDetails(rowId: number) {
        return this.openPanels.has(rowId) ?? false;
    }
}
