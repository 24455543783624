import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnsubmittedApplications } from '@app/domain/models';
import { UnsubmittedApplication, UnsubmittedApplicationsView } from './unsubmitted-applications-view-model';
import dayjs from 'dayjs';

@Component({
    selector: 'app-unsubmitted-applications',
    templateUrl: './unsubmitted-applications.component.html',
    styleUrl: './unsubmitted-applications.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubmittedApplicationsComponent {
    openPanels = new Set<number>();
    private viewModel: UnsubmittedApplicationsView;

    @Input() set unsubmittedApplications(value) {
        if (value) this.viewModel = this.toView(value);
    }

    get applications(): UnsubmittedApplicationsView {
        return this.viewModel;
    }

    isApplicationOpen(applicationId: number): boolean {
        return this.openPanels.has(applicationId) ?? false;
    }

    handleToggleDetails(applicationId: number) {
        if (!this.openPanels.has(applicationId)) {
            this.openPanels.add(applicationId);
        } else {
            this.openPanels.delete(applicationId);
        }
    }

    private toView(applications: UnsubmittedApplications): UnsubmittedApplicationsView {
        let items: UnsubmittedApplication[] = [];

        if (applications.data) {
            items = applications.data.map((app) => ({
                id: app.id,
                status: app.status,
                statusMessage: app.statusMessage,
                programme: app.programme,
                admitTerm: {
                    description: app.admitTerm.description,
                    longDescription: this.termDisplay(app.admitTerm)
                },
                termSession: this.termDisplay(app.programme.termSession),
                admitType: app.admitType,
                updateDate: dayjs(app.updateDate).format('DD/MM/YYYY'),
                createDate: dayjs(app.createDate).format('DD/MM/YYYY'),
                lastVisitedPage: this.lastPageVisitedFormat(app.lastVisitedPage, app.lastVisitedPageName),
                // eslint-disable-next-line no-underscore-dangle
                applicationUrl: app._links.application.href,
                agent: app?.agent?.name,
            }));
        }

        const viewModel: UnsubmittedApplicationsView = {
            // eslint-disable-next-line no-underscore-dangle
            applicantUrl: applications._links?.applicant?.href,
            items
        };

        // English Language Proficiency
        const englishProficiency = {
            englishLanguageRequired: applications?.englishLanguageRequired,
            englishTestCompleted: applications?.englishTestCompleted,
            interestedInEla: applications?.interestedInEla
        };

        const showEnglishProficiency = Object.keys(englishProficiency)
            .filter(field => englishProficiency[field] != null)
            .length !== 0;

        if (showEnglishProficiency) {
            viewModel.englishProficiency = englishProficiency;
        }

        return viewModel;
    }

    private lastPageVisitedFormat(lastPageNumber: number, lastPageName: string): string {
        if (lastPageNumber != null && lastPageName != null) {
            return `${lastPageName} (Page ${lastPageNumber})`;
        } else if (lastPageNumber != null && lastPageName == null) {
            return `Page ${lastPageNumber}`;
        } else {
            return 'Unknown';
        }
    }

    private termDisplay(term?: {description: string; startDate: string; endDate: string}) {
        if (term && term.startDate && term.endDate) {
            const startDate = dayjs(term.startDate).format('DD/MM/YYYY');
            const endDate = dayjs(term.endDate).format('DD/MM/YYYY');

            if (term.description) {
                return `${term.description} (${startDate} - ${endDate})`;
            } else {
                return `${startDate} - ${endDate}`;
            }
        }
        return null;
    }
}

