<ion-accordion-group [value]="expandedAccordian" expand="compact" co>
    <ion-accordion value="first" [ngClass]="highlight === true ? 'background-light-1':''">
        <ion-item slot="header" [color]="highlight === true ? 'light':'none'" class="header">
            <ion-label>{{header}}</ion-label>
        </ion-item>

        <div class="ion-no-padding" slot="content">
            <ng-content></ng-content>
        </div>
    </ion-accordion>
</ion-accordion-group>