import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Progress } from '@domain/models/progress';

@Component({
  selector: 'app-student-progress',
  templateUrl: './student-progress.component.html',
  styleUrl: './student-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class StudentProgressComponent {
  openPanels = new Set<number>();
  @Input() progress: Progress[];

  handleToggleDetails(index: number) {
    if (!this.openPanels.has(index)) {
        this.openPanels.add(index);
    } else {
        this.openPanels.delete(index);
    }
  }

  isHideDetails(index: number) {
      return this.openPanels.has(index) ?? false;
  }
}
