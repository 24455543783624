import { Component, Input } from '@angular/core';
import { Degree } from '@domain/models';
import { sortByProp } from '@app/util';

@Component({
  selector: 'app-degree-details',
  templateUrl: './degree-details.component.html',
  styleUrl: './degree-details.component.scss'
})
export class DegreeDetailsComponent {
    @Input() degree: Degree;

    get statusDate () {
        const item = sortByProp('descend', this.degree.plans, 'statusDate')[0];
        return item?.statusDate;
    }
}
