import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { Identity, ServiceError, StudentId } from '@domain/models';
import { IdentityService, PersonService } from '@core/services';
import { isUpi, isIdentityId } from '@app/util/types';

/**
 * Retrieve and orchestrate information about people. This includes identity and related entities.
 *
 */
@Injectable({
    providedIn: 'root',
})
export class PersonAdapter {
    constructor(
        private identity: IdentityService,
        private person: PersonService
    ) {
    }

    /**
     * Get a person by id or upi
     *
     * @param id
     * @returns List of student visas
     */
    getPerson(idOrUpi: string): Observable<Identity> {
        return this.identity.getById(idOrUpi);
    }

    /**
     * Get a list of affiliations by id or upi
     *
     * @param idOrUpi
     * @returns List of affiliations
     */
    getAffiliations(idOrUpi: StudentId): Observable<string[]> {
        return this.getStudentId(idOrUpi)
                .pipe(
                    switchMap(id => this.person.getAffiliations(id)),
                    catchError((err) => throwError( () => new ServiceError('Sorry, there was an unexpected problem displaying student affiliations', err)) )
                );
    }

    private getStudentId(idOrUpi: StudentId): Observable<string> {
        if (typeof idOrUpi === 'number') {
            return of(`${idOrUpi}`);
        }

        if (isUpi(idOrUpi)) {
            return this.identity.getById(idOrUpi)
                    .pipe(
                        map(identity => `${identity.id}`)
                    );
        } else if (isIdentityId(idOrUpi)) {
            return of(idOrUpi);
        }

        throw new Error(`Student identifier ${idOrUpi} must be a upi or id`);
    }
}

export const PersonAdapterProvider={ provide: PersonAdapter };