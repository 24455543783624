<div class="desktop-menu" *ngIf="display==='desktop'">
    <ion-content class="desktop-menu--primary">
        <ion-list lines="none" class="primary">
            <ng-template ngFor let-menu let-i="index" [ngForOf]="menus">
                <a [href]="menu.url" [target]="menu.target" *ngIf="menu.type==='link'" [title]="menu.title">
                    <ion-item
                            [button]="true"
                            [detail]="true"
                            detail-icon="link-outline"
                            aria-roledescription="menu">

                        <ion-label [tabindex]="i" [title]="menu.title">{{menu.name}}</ion-label>
                    </ion-item>
                </a>

                <ion-item *ngIf="menu.type==='button'"
                          [button]="false"
                          [detail]="false"
                          aria-roledescription="menu">

                    <ion-button fill="solid" size="small" [href]="menu.url" [target]="menu.target" [title]="menu.title" expand="block">
                        {{menu.name}}
                    </ion-button>
                </ion-item>

                <ion-item *ngIf="menu.type==='menu'"
                          [class.selected]="menu.isSelected"
                          [button]="true"
                          [detail]="true"
                          (click)="selectGroup($event, menu)"
                          (keydown)="selectGroup($event, menu)"
                          aria-roledescription="menu">

                    <ion-label [tabindex]="i" [title]="menu.title">{{menu.name}}</ion-label>
                </ion-item>
            </ng-template>
        </ion-list>
        <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
    </ion-content>
    <ion-content class="desktop-menu--secondary">
        <ion-list lines="none" class="secondary">
            <ng-template ngFor let-menu let-i="index" [ngForOf]="selectedGroupMenu.views">
                <a [routerLink]="menu.url" *ngIf="menu.type==='component'" [title]="menu.title">
                    <ion-item
                            [class.selected]="menu.isSelected"
                            button="true"
                            detail="true"
                            (click)="selectView($event, menu)"
                            (keydown)="selectView($event, menu)"
                            aria-roledescription="menu">

                        <ion-label [tabindex]="i" [title]="menu.title">{{menu.name}}</ion-label>
                    </ion-item>
                </a>

                <a [href]="menu.url" [target]="menu.target" *ngIf="menu.type==='link'" [title]="menu.title">
                    <ion-item
                            button="true"
                            detail="true"
                            detail-icon="link-outline"
                            aria-roledescription="menu">

                        <ion-label [tabindex]="i" [title]="menu.title">{{menu.name}}</ion-label>
                    </ion-item>
                </a>

                <ion-item *ngIf="menu.type==='button'"
                          button="false"
                          detail="false"
                          aria-roledescription="menu">

                    <ion-button fill="solid" size="small" [href]="menu.url" [target]="menu.target" [title]="menu.title" expand="block">
                        {{menu.name}}
                    </ion-button>
                </ion-item>
            </ng-template>
        </ion-list>
    </ion-content>
</div>

<ion-menu contentId="main" menuId="mobileMenu" side="start" *ngIf="display==='mobile'">
    <ion-content>
        <ion-list lines="none" class="primary">
            <ng-template ngFor let-menu let-i="index" [ngForOf]="menus">
                <ion-item *ngIf="menu.type === 'menu'"
                          [class.selected]="menu.isSelected"
                          button="true"
                          detail="true"
                          [detailIcon]="menu.isSelected ? 'chevron-down':'chevron-forward'"
                          (click)="selectGroup($event, menu)"
                          (keydown)="selectGroup($event, menu)"
                          aria-roledescription="menu">
                    <ion-label [tabindex]="i" [title]="menu.title">{{menu.name}}</ion-label>
                </ion-item>

                <ion-menu-toggle *ngIf="menu.isSelected" class="sub-menu">
                    <ng-container *ngFor="let subMenu of selectedGroupMenu.views">
                        <a [routerLink]="subMenu.url" *ngIf="subMenu.type==='component'" [title]="subMenu.title">
                            <ion-item
                                    [class.selected]="subMenu.isSelected"
                                    button="true"
                                    detail="true"
                                    (click)="selectView($event, subMenu)"
                                    (keydown)="selectView($event, subMenu)"
                                    aria-roledescription="menu">

                                <ion-label [tabindex]="i" [title]="subMenu.title">{{subMenu.name}}</ion-label>
                            </ion-item>
                        </a>

                        <a [href]="subMenu.url" [target]="subMenu.target" *ngIf="subMenu.type==='link'" [title]="subMenu.title">
                            <ion-item
                                    button="true"
                                    detail="true"
                                    detail-icon="link-outline"
                                    aria-roledescription="menu">

                                <ion-label [tabindex]="i" [title]="subMenu.title">{{subMenu.name}}</ion-label>
                            </ion-item>
                        </a>

                        <ion-item *ngIf="subMenu.type==='button'"
                                  class="submenu-btn"
                                  button="true"
                                  detail="false"
                                  aria-roledescription="menu">

                            <ion-button fill="solid" size="small" [href]="subMenu.url" [target]="subMenu.target" [title]="subMenu.title" expand="block">
                                {{subMenu.name}}
                            </ion-button>
                        </ion-item>
                    </ng-container>
                </ion-menu-toggle>
            </ng-template>
        </ion-list>
        <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
    </ion-content>
    
</ion-menu>

<ng-template #projectedContent>
    <ng-content></ng-content>
</ng-template>