import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss']
})
export class BooleanComponent {
    /**
     * Indicator vaue
     */
    @Input() value = true;

    /**
     * Display a tick if positive, otherwise no indicator
     */
    @Input() positive = false;
}
