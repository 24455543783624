import { sortWith, path, descend, ascend } from 'ramda';

/**
 * Sorts array of object by ascending/descending order and wth given property name
 *
 * @param order - ascend/descend
 * @param props - path to prop either single or nested (e.g. {id: 123}/['id'] or {id: {number: 123}}/['id', 'number'])
 * @param list - array of object to sort
 *
 * @returns sorted array of object
 */
export function sortByProp<T>(order: 'ascend' | 'descend', list: T[], prop: string): T[] {
    const props = prop.split('.');
    if (order === 'descend') {
        return sortWith([descend(path(props))], list);
    } else {
        return sortWith([ascend(path(props))], list);
    }
}