import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Color } from '@ionic/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit, OnDestroy {
  @Input() colour: Color = 'warning';
  @Input({transform: toNumber}) duration: number;
  @Output() dismissed = new EventEmitter<unknown>();

  private timer: number;

  ngOnInit(): void {
    if (this.duration) {
      this.timer = window.setTimeout(() => {
        this.timer = 0;
        this.dismiss();
      }, this.duration);
    }
  }

  ngOnDestroy(): void {
    /**
     * Cancel the timer if it is still on-going when the component is destroyed.
     */
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
  }

  handleClick(event?: Event) {
    event?.preventDefault();
    this.dismiss();
  }

  /**
   * Clicking the dismiss button. This does not automatially close the banner. This is at the discretion of the consumer.
   */
  dismiss() {
    this.dismissed.emit();
  }
}

function toNumber(val: string | undefined): number {
  return parseInt(val);
}