import { Component, Input } from '@angular/core';
import { ScholarshipAwarded } from '@app/domain/models/scholarship-awarded';

@Component({
  selector: 'app-scholarship-history-details',
  templateUrl: './scholarship-history-details.component.html',
  styleUrl: './scholarship-history-details.component.scss'
})
export class ScholarshipHistoryDetailsComponent {
    @Input() details: ScholarshipAwarded;
    @Input() highlight: boolean;

    constructor() {
    }
}
