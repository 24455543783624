import { Component, Input } from '@angular/core';
import { ServiceIndicator } from '@domain/models';
import { ServiceIndicatorsModalComponent } from '@modules/person/components';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-service-indicators',
  templateUrl: './service-indicators.component.html',
  styleUrl: './service-indicators.component.scss'
})
export class ServiceIndicatorsComponent {
    @Input() serviceIndicators: ServiceIndicator[];

    constructor(private modalCtrl: ModalController) {
    }

    async openModal(serviceIndicator: ServiceIndicator) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                serviceIndicator: serviceIndicator
            },
            component: ServiceIndicatorsModalComponent,
        });

        void modal.present();
    }
}
