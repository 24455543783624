import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs';
import { ModalController, ModalOptions } from '@ionic/angular';
import { resizeObservable } from '@app/util/resize-observable';

@Component({
  selector: 'app-text-truncate',
  templateUrl: './text-truncate.component.html',
  styleUrl: './text-truncate.component.scss'
})
export class TextTruncateComponent implements AfterViewInit {
    @Input() text: string;
    @Input() modalConfig?: ModalOptions;
    @ViewChild('comment') $comment: ElementRef;

    isExpanded = false;

    constructor(private modalCtrl: ModalController) {}

    ngAfterViewInit() {
        // Logic to add truncate class for read more button
        resizeObservable(this.$comment.nativeElement)
            .pipe(debounceTime(500))
            .subscribe((entries) => {
                if (entries?.length === 0) {
                    return;
                }

                const entry = entries[0];

                if (entry?.target.scrollHeight > entry?.contentRect.height) {
                    entry.target.classList.add('truncated');
                } else {
                    entry?.target.classList.remove('truncated');
                }
            });
    }

    async openModal() {
        const modal = await this.modalCtrl.create(this.modalConfig);

        void modal.present();
    }

    expandComment() {
        this.isExpanded = !this.isExpanded;
    }
}
