import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StudyLink } from '@domain/models';

@Component({
  selector: 'app-study-link-application-details',
  templateUrl: './study-link-application-details.component.html',
  styleUrl: './study-link-application-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudyLinkApplicationDetailsComponent {
    @Input() studyLink: StudyLink;
}
