import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-error-retry',
    templateUrl: './error-retry.component.html',
    styleUrls: ['./error-retry.component.scss'],
})
export class ErroryRetryComponent {
    @Input() visible = false;
    @Input() message;
    @Output() retry = new EventEmitter<boolean>();

    public doRetry(): void {
        this.retry.emit(true);
    }
}
