import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Transaction } from '@domain/models';

@Component({
  selector: 'app-tuition-fee-details',
  templateUrl: './tuition-fee-details.component.html',
  styleUrl: './tuition-fee-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TuitionFeeDetailsComponent {
    @Input() transaction: Transaction;
}
