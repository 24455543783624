import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { AcademicStanding, AELR, ServiceError, Delna } from '@domain/models';
import { sortByProp } from '@app/util';
import { Progress } from '@domain/models/progress';

@Component({
  templateUrl: './student-progress-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class StudentProgressViewComponent extends PersonViewComponent {
  isLoading = true;
  isError = false;
  message: string;
  aelr: AELR;
  academicStanding: AcademicStanding[];
  delna: Delna[];
  progress: Progress[];

  constructor(private adapter: StudentAdapter) {
    super();
  }

  public load(): void {
    this.isError = false;
    this.isLoading = true;

    this.adapter.getStudentProgress(super.identity.id).subscribe({
      next: (result) => {
        if (result.aelr && result.aelr?.length > 0) {
          // Sort descending by assessment date and gets the latest AELR assessment
          this.aelr = sortByProp('descend', result.aelr, 'assessedOn')[0];
        }

        if (result.academicStanding && result.academicStanding?.length > 0) {
          // Sort descending by term start and gets the latest academic standing
          this.academicStanding = sortByProp('descend', result.academicStanding, 'term.start');
        }

        this.delna = sortByProp('descend', result.delna, 'testedOn');

        this.progress = sortByProp('ascend', result.progress, 'programmeStatus.description');

        this.isLoading = false;
      },
      error: (error) => {
        if (error instanceof ServiceError) {
          this.message = error.message;
        }
        this.isError = true;
        this.isLoading = false;
      },
    });
  }

  render() {
    this.load();
  }
}
