import { Component, Input } from '@angular/core';
import { StudentCourseCredit } from '@domain/models';

@Component({
  selector: 'app-course-credit-details',
  templateUrl: './course-credit-details.component.html',
  styleUrl: './course-credit-details.component.scss'
})
export class CourseCreditDetailsComponent {
    currentPage: number[] = [];
    itemsPerPage = 5;

    @Input() credit: StudentCourseCredit;
    @Input() index: number;
}
