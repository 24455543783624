import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { TuitionFeesViewModel } from '../tuition-fees-view.model';
import { AppConfiguration, Transaction } from '@domain/models';
import { APP_CONFIG } from '@core/providers';

@Component({
  selector: 'app-tuition-fees',
  templateUrl: './tuition-fees.component.html',
  styleUrl: './tuition-fees.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TuitionFeesComponent {
    customPopoverOptions = {
        side: 'bottom',
        alignment: 'start'
    };

    openPanels = new Set<number>();
    defaultTerm: string;
    transactions: Transaction[];
    viewModel: TuitionFeesViewModel;

    constructor(@Inject(APP_CONFIG) private configuration: AppConfiguration) {
    }

    @Input() set tuitionFees(tuitionFees: TuitionFeesViewModel) {
        if (tuitionFees) {
            this.viewModel = tuitionFees;
            this.defaultTerm = tuitionFees?.transactions[0]?.term;
            this.transactions = tuitionFees?.transactions[0]?.transactions;
        }
    }

    get tuitionFees(): TuitionFeesViewModel {
        return this.viewModel;
    }

    onChangeTerm(e: CustomEvent) {
        const term = e.detail.value;

        this.transactions = this.tuitionFees.transactions.find(v => v.term === term).transactions;
        this.openPanels.clear();
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    get feesLink(): string {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.fees}`;
    }
}
