<app-field-list>
    <ion-row>
        <ion-col size="6">
            <app-field-value label="Career">
                {{ progress.career?.description | default }}
            </app-field-value>
            <app-field-value label="Programme Action">
                {{ progress.programmeAction?.description | default }}
            </app-field-value>
            <app-field-value label="Programme Status">
                {{ progress.programmeStatus.description | default }}
            </app-field-value>            
        </ion-col>
        <ion-col size="6">
            <app-field-value label="Report Date">
                {{ progress.reportDate | date }}
            </app-field-value>
            <app-field-value label="Report Type">
                {{ progress.type?.description | default }}
            </app-field-value>
            <app-field-value label="Units Failed">
                {{ progress.unitsFailed | default }}
            </app-field-value>            
        </ion-col>        
    </ion-row>
    <ion-row>
        <h3>Requirement {{ progress.requirement.description | default }}</h3>
        <p [innerHtml]="progress.requirement?.longDescription"></p>
    </ion-row>
</app-field-list>