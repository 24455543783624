import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { PersonViewComponent } from '../../model/person-view.component';
import { PersonView, toViewModel } from './person-view-model';
import { AppConfiguration, Identity } from '@app/domain/models';
import { APP_CONFIG } from '@app/core/providers';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './personal-details-view.component.html',
    styleUrl: './personal-details-view.component.scss',
    changeDetection: ChangeDetectionStrategy.Default
})
export class PersonalDetailsViewComponent extends PersonViewComponent implements OnInit {
    isLoading = true;
    eprLink: string;
    person: PersonView;
    isMergedAccount: boolean;

    constructor(@Inject(APP_CONFIG) private configuration: AppConfiguration, private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.isMergedAccount = params['id'] !== super.identity.id.toString();
        });
    }

    render(identity: Identity): void {
        this.person = toViewModel(identity);
        this.eprLink = `${this.configuration.links.epr}/identity/${identity.id}`;
        this.isLoading = false;
    }

}