import { Component, Input } from '@angular/core';
import { ScholarshipPayment } from '@domain/models';
import { uniq } from 'ramda';

@Component({
  selector: 'app-scholarship-payments',
  templateUrl: './scholarship-payments.component.html',
  styleUrl: './scholarship-payments.component.scss'
})
export class ScholarshipPaymentsComponent {
  openPanels = new Set<number>();
  selectedScholarship: string = null;
  selectedStatus: string = null;

  @Input() payments: ScholarshipPayment[];

  get scholarshipList() {
    return uniq(this.payments.flatMap(v => v.scholarship));
  }

  get statusList() {
    return uniq(this.payments?.flatMap(v => v.status));
  }

  get paymentList() {
    return this.payments?.filter(v => {
      if (this.selectedScholarship && this.selectedStatus) {
        return v.scholarship.code === this.selectedScholarship && v.status.code === this.selectedStatus;
      }

      if (this.selectedScholarship || this.selectedStatus) {
        return v.scholarship.code === this.selectedScholarship || v.status.code === this.selectedStatus;
      }

      return true;
    });
  }

  handleToggleDetails(index: number) {
    if (!this.openPanels.has(index)) {
      this.openPanels.add(index);
    } else {
      this.openPanels.delete(index);
    }
  }

  isHideDetails(index: number) {
    return this.openPanels.has(index) ?? false;
  }

  trackBy(index: number): number {
    return index;
  }

  onChangeScholarship(e: CustomEvent) {
    this.selectedScholarship = e.detail.value;
    this.openPanels.clear();
  }

  onChangeStatus(e: CustomEvent) {
    this.selectedStatus = e.detail.value;
    this.openPanels.clear();
  }
}
