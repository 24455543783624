<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <div class="content-header">
            <h2>Enrolment Cart</h2>
            <ion-button *ngIf="cart?.length > 0" (click)="openEnrolmentCartCalendar()" color="primary" fill="outline" shape="round" size="small">
                Calendar View
            </ion-button>
        </div>
        <ng-container *ngIf="cart?.length > 0; else empty">
            <app-enrolment-cart [items]="cart"></app-enrolment-cart>
        </ng-container>
        <ng-template #empty>
            <h3 class="header__notfound">No data to display</h3>
        </ng-template>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>