import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { StudentAdapter } from '@core/adapters';
import { AppConfiguration, Comment, ServiceError, StudentChecklists } from '@domain/models';
import { zip } from 'rxjs';
import { sortByProp } from '@app/util';
import { APP_CONFIG } from '@core/providers';


@Component({
  templateUrl: './comments-checklists-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class CommentsChecklistsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    checklists: StudentChecklists;
    comments: Comment[];

    constructor(private student: StudentAdapter, @Inject(APP_CONFIG) private configuration: AppConfiguration) {
        super();
    }

    load(): void {
        this.isError = false;
        this.isLoading = true;

        zip(this.student.getStudentChecklists(super.identity.id), this.student.getStudentComments(super.identity.id))
            .subscribe({
                next: ([checklists, comments]) => {
                    this.checklists = checklists;
                    this.comments = sortByProp('descend', comments, 'commentDate');
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isError = true;
                    this.isLoading = false;
                },
            });
    }

    render() {
        this.load();
    }

    get checklistLink(): string {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.checklistManagement}?COMMON_ID=${super.identity.id}`;
    }
}
