import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CourseViewModel } from '../programme-courses-view.model';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrl: './course-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseDetailsComponent {
    @Input() course: CourseViewModel;

    classNotes(notes) {
        notes = notes.map(v => {
            return v.note;
        });

        return notes.join('. ');
    }
}
