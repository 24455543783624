import {Component, Input} from '@angular/core';
import { ScholarshipPayment } from '@domain/models';

@Component({
  selector: 'app-scholarship-payment-details',
  templateUrl: './scholarship-payment-details.component.html',
  styleUrl: './scholarship-payment-details.component.scss'
})
export class ScholarshipPaymentDetailsComponent {
  @Input() payment: ScholarshipPayment;
}
