<app-field-list classList="margin-0">
    <app-field-value label="Test Centre">{{ test.testCentre?.testCentre || test.school?.testCentre | default }}</app-field-value>
    <app-field-value label="School">{{ test.school?.description | default }}</app-field-value>
    <app-field-value label="Region" *ngIf="test.school?.region">{{ test.school?.region | default }}</app-field-value>
</app-field-list>

<ion-grid class="test-results table ion-no-padding" *ngIf="isNceaTest">
    <div class="table__thead">
        <ion-row>
            <ion-col size="2">Subject</ion-col>
            <ion-col size="">Standard</ion-col>
            <ion-col size="2">Credits</ion-col>
            <ion-col size="2">Result</ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">
        <ng-container *ngIf="!isError; else error">
            <ng-container *ngIf="isLoaded; else fetching">
                <ng-container *ngIf="testRes?.length > 0; else empty">
                    <ng-container *ngFor="let result of testRes; even as isEven; index as i">
                        <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                            <ion-col size="2">{{ result.component?.description | default }}</ion-col>
                            <ion-col size="">{{ result.standard?.description | default }}</ion-col>
                            <ion-col size="2">{{ result.credits | default }}</ion-col>
                            <ion-col size="2">{{ result.result?.description | default }}</ion-col>
                        </ion-row>
                    </ng-container>
                </ng-container>
                <ng-template #empty>
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">No data to display</ion-col>
                    </ion-row>
                </ng-template>
            </ng-container>
            <ng-template #fetching>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">Fetching data...</ion-col>
                </ion-row>
            </ng-template>
        </ng-container>
        <ng-template #error>
            <ion-row>
                <ion-col size="12" class="ion-text-center">Errror fetching data</ion-col>
            </ion-row>
        </ng-template>
    </div>
</ion-grid>