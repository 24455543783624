import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AcademicStanding } from '@domain/models';

@Component({
  selector: 'app-academic-standing',
  templateUrl: './academic-standing.component.html',
  styleUrl: './academic-standing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcademicStandingComponent {
    mostRecentStanding: AcademicStanding;
    acadStandingHistory: AcademicStanding[];

    @Input() set academicStanding(val: AcademicStanding[]) {
        if (val?.length > 0) {
            this.mostRecentStanding = val[0];
            this.acadStandingHistory = val.slice(1, val.length);
        }
    }
}
