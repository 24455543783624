<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">

        <div class="content-header">
            <h2>Checklists</h2>
            <ion-button [href]="checklistLink" target="_blank" fill="outline" color="primary" size="small" shape="round">
                CS9 Checklist
            </ion-button>
        </div>
        <app-checklists [checklists]="checklists"></app-checklists>

    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>