import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MenuItem, PrimaryMenuItem, ViewMenuItem } from '../../model/menu';
import { IonMenu, MenuController, Platform } from '@ionic/angular';
import { debounceTime, map } from 'rxjs';

const DESKTOP_SCREEN_MIN = 1200;

type DisplayType = 'desktop' | 'mobile';

@Component({
    selector: 'app-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class NavigationMenuComponent implements OnInit {
    display: DisplayType = 'desktop';

    @ViewChild(IonMenu) mobileMenu: MenuController;
    @Output() navigation: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

    /**
     * A list of all primary menus with their sub-views for navigating to components
     */
    @Input({required: true}) menus: PrimaryMenuItem[];

    /**
     * Toggle the display of the mobile menu.
     */
    @Input()
    set isOpenMenu(isOpen: boolean) {
        if (this.mobileMenu && this.display === 'mobile') {
            if (isOpen) {
                void this.mobileMenu.open();
            } else {
                void this.mobileMenu.close();
            }
        }
    }

    get selectedGroupMenu(): PrimaryMenuItem {
        // Get the currently selected group menu, or the first one if none is selected.
        const selected = this.menus.find(m => m.isSelected);

        if (selected===undefined) {
            return this.menus[0];
        }

        return selected;
    }

    constructor(private platform: Platform) {
        // Toggle ion menu on screen resize
        platform.resize
            .pipe(
                debounceTime(100),
                map(() => this.platform.width())
            )
            .subscribe(windowWidth => {
                this.display = windowWidth >= DESKTOP_SCREEN_MIN ? 'desktop' : 'mobile';
            });
    }

    ngOnInit() {
        this.display = this.platform.width() >= DESKTOP_SCREEN_MIN ? 'desktop' : 'mobile';

        if (this.mobileMenu) {
            void this.mobileMenu.enable(this.display === 'mobile');
        }
    }

    /**
     * Select the top-level group menu and show sub-menus
     *
     * @param event
     * @param selected
     * @returns
     */
    selectGroup(event: Event, selected: PrimaryMenuItem): void {
        event.preventDefault();
        if (selected.isSelected) {
            return;
        }
        this.deselectAllGroups();
        selected.isSelected = true;
    }

    /**
     * Select the view within the group
     *
     * @param event
     * @param selected
     * @returns
     */
    selectView(event: Event, selected: ViewMenuItem): void {
        event.preventDefault();
        if (selected.isSelected) {
            return;
        }

        this.deselectAllViews();
        selected.isSelected = true;
        this.navigation.emit(selected);
    }

    private deselectAllGroups(): void {
        this.menus.forEach(menu => menu.isSelected = false);
    }

    private deselectAllViews(): void {
        this.menus
            .flatMap(menu => menu.views)
            .forEach(view => view.isSelected = false);
    }
}