/* eslint-disable no-loop-func */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StudentAdapter } from '@core/adapters';
import { CustomCalendarEventInput } from '@modules/person/components/timetable-view/timetable-view.model';
import { ServiceError, Timetable } from '@domain/models';
import dayjs from 'dayjs';
import { sortByProp } from '@app/util';

@Component({
  selector: 'app-timetable-view',
  templateUrl: './timetable-view.component.html',
  styleUrl: './timetable-view.component.scss',
})
export class TimetableViewComponent implements OnInit {
    isLoading = true;
    isError = false;
    message: string;
    events: CustomCalendarEventInput[];
    year: number;

    constructor(private student: StudentAdapter, private modalCtrl: ModalController) {
        this.year = new Date().getFullYear();
    }

    @Input() studentId: number;

    ngOnInit () {
        this.load();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getTimetables(this.studentId, this.year)
            .subscribe({
                next: result => {
                    this.events = this.toCalendarEvents(result);
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    close() {
        void this.modalCtrl.dismiss();
    }

    private toCalendarEvents(data: Timetable[]): CustomCalendarEventInput[] {
        let id = 0;
        const dayOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

        const eventClassName = (type)=> {
            return type.replace(/\s+/g, '-');
        };

        const events = data.flatMap( enroled =>
            enroled.timetables.map( timetable => ({
                id: `${++id}`,
                title: `${enroled.subject.code} ${enroled.catalogNumber}`,
                startRecur: timetable.start,
                endRecur: dayjs(timetable.end).endOf('day').toDate(),
                startTime: timetable.startTime,
                endTime: timetable.endTime,
                daysOfWeek: timetable.days.map(day => dayOfWeek.indexOf(day)),
                className: `event-${eventClassName(enroled.component.description).toLowerCase()}`,
                extendedProps: {
                    description: enroled.description,
                    subject: enroled.subject,
                    component: enroled.component,
                    catalogNumber: enroled.catalogNumber,
                    classNumber: enroled.classNumber,
                    building: timetable.building,
                    room: timetable.room,
                    location: timetable.location,
                    instructors: timetable.instructors
                }
            }))
        );

        return sortByProp('ascend', events, 'startRecur');
    }

    onNavigation(date: Date) {
        if (date.getFullYear() !== this.year) {
            this.year = date.getFullYear();

            // Load the data without a spinner (as this causes the control to reset)
            this.student.getTimetables(this.studentId, this.year)
                .subscribe({
                    next: result => {
                        this.events = this.toCalendarEvents(result);
                    }
                });
        }
    }
}