import { Component, Input} from '@angular/core';

@Component({
    selector: 'app-dataload-spinner',
    templateUrl: './dataload-spinner.component.html',
    styleUrls: ['./dataload-spinner.component.scss'],
})
export class DataloadSpinnerComponent {
    @Input({required: true}) isLoading: boolean;
}
