import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';
import * as JWTDecode from 'jwt-decode';
import { AccessToken } from '@domain/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private accessToken: AccessToken;

  constructor(private storage: AppStorageService) {}

  /**
   * Get the access token from local storage. Cached locally until expiry.
   *
   * @returns decoded token
   */
  private async getAccessToken(): Promise<AccessToken> {
    const now = new Date();
    if (this.accessToken && this.accessToken.exp < now.getTime()) {
      return this.accessToken;
    }

    try {
      const token = await this.storage.getItem('accessToken');
      return JWTDecode.jwtDecode(token);
    } catch (ex) {
      return null;
    }
  }

  /**
   * Get the token expiry
   *
   * @returns Duration in seconds of the token from issue
   */
  public async getAccessExpiry(): Promise<number> {
    const token = await this.getAccessToken();
    if (!token) {
      return 0;
    }

    return token.exp;
  }

  /**
   * Get a list of access scopes for the logged in user
   *
   * @returns List of scopes
   */
  public async getAccessScopes(): Promise<string[]> {
    const token = await this.getAccessToken();
    if (!token) {
      return [];
    }

    return token.scope.split(' ');
  }
}

export const UserServiceProvider={ provide: UserService, useClass: UserService };