<app-tabs [tabLabels]="tabLabels" [selectedTab]="selectedTab" (tabChange)="handleChangeTab($event)" />
<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="1">
                Offer Year
            </ion-col>
            <ion-col size="1">
                Schol. Code
            </ion-col>
            <ion-col size="4">
                Description
            </ion-col>
            <ion-col size="2">
                Status
            </ion-col>
            <ion-col size="2">
                Reason
            </ion-col>
            <ion-col size="2"  class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="tabScholarships?.length > 0; else empty">
            <ng-container *ngFor="let scholarshipsInfo of tabScholarships; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="1"> {{ scholarshipsInfo.offerYear | default }}</ion-col>
                    <ion-col size="1">{{ scholarshipsInfo.scholarship.code | default }}</ion-col>
                    <ion-col size="4">{{ scholarshipsInfo.scholarship.description | default }}</ion-col>
                    <ion-col size="2">{{ scholarshipsInfo.status.description | default }}</ion-col>
                    <ion-col size="2">{{ scholarshipsInfo.reason.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-scholarship-history-details [highlight]="isEven" [details]="scholarshipsInfo" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #empty>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>
