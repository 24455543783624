import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '@modules/shared/shared.module';
import { SearchRoutes } from './search-routes';
import {RouterModule} from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SearchPageComponent } from './pages';
import {
    SearchResultCardComponent,
    SearchResultCardSkeletonComponent,
    SearchResultEmptyComponent
} from '@modules/search/components';

@NgModule({
  declarations: [
    SearchPageComponent,
    SearchResultCardComponent,
    SearchResultCardSkeletonComponent,
    SearchResultEmptyComponent
  ],

    imports: [
        CommonModule,
        IonicModule,
        SharedComponentsModule,
        SearchRoutes,
        RouterModule
    ],
  providers: [

  ],
})
export class SearchModule {}