import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StudyLinkLoan } from '@domain/models/study-link-loan';
import { StudyLinkLoanModalComponent } from '@modules/person/components';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-study-link-loan-details',
  templateUrl: './study-link-loan-details.component.html',
  styleUrl: './study-link-loan-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudyLinkLoanDetailsComponent {
    @Input() loan: StudyLinkLoan;

    constructor(private modalCtrl: ModalController) {}

    async openLoanDetailsModal(fileNumber: number, loan: StudyLinkLoan) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                loan: loan,
                loanDetail: loan.details.filter(v => v.fileNumber === fileNumber)[0]
            },
            component: StudyLinkLoanModalComponent,
            cssClass: 'study-link-loan-modal'
        });

        void modal.present();
    }
}
