import { Component, Input } from '@angular/core';
import { toBoolean } from '@app/util/booleans';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent {
    @Input() header: string;
    @Input({transform: toBoolean}) expanded = false;
    @Input({transform: toBoolean}) highlight?: boolean;

    get expandedAccordian(): string {
      return this.expanded ? 'first' : undefined;
    }
}
