<app-field-list classList="margin-top-0">
    <ion-row class="fields-two-column-layout">
        <ion-col size="6">
            <app-field-value label="Course ID">{{ item.class.course.code | default }}</app-field-value>
            <app-field-value label="Catalogue No">{{ item.class.catalogNumber | default }}</app-field-value>
            <app-field-value label="Course Points">{{ item.coursePoints | default }}</app-field-value>
            <app-field-value label="Enrolment Status">{{ item.studentEnrolmentStatus?.description | default }}</app-field-value>
            <app-field-value label="Availability">{{ item.class.enrolmentStatus.description | default }}</app-field-value>
        </ion-col>
        <ion-col size="6">
            <app-field-value label="Class Number">{{ item.classNumber | default }}</app-field-value>
            <app-field-value label="Class Component">{{ item.class.component.description | default }}</app-field-value>
            <app-field-value label="Class Section">{{ item.class.section | default }}</app-field-value>
            <app-field-value label="Request Message">{{ item.status.description | default }}</app-field-value>
        </ion-col>
    </ion-row>
</app-field-list>
<app-field-list>
    <app-field-value label="Class Notes">
        <ng-container *ngIf="item.class.notes?.length > 0; else noNotes">
            <ion-text *ngFor="let note of item.class.notes">
                {{ note.note }}
            </ion-text>
        </ng-container>
        <ng-template #noNotes>
            No data
        </ng-template>
    </app-field-value>
</app-field-list>
<app-field-list>
    <app-field-value label="Instructors">
        <ng-container *ngIf="item.class.instructors?.length> 0; else noInstructors">
            <ng-container *ngFor="let instructor of item.class.instructors; let isLast = last">
                {{ instructor.name }}<span *ngIf="!isLast">; </span>
            </ng-container>
        </ng-container>
        <ng-template #noInstructors>No data</ng-template>
    </app-field-value>
</app-field-list>
<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col>
                Date
            </ion-col>
            <ion-col>
                Time
            </ion-col>
            <ion-col size="2">
                Building
            </ion-col>
            <ion-col size="2">
                Room
            </ion-col>
            <ion-col size="2">
                Campus
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="item.class.timetables?.length > 0; else empty">
            <ng-container *ngFor="let timetable of timetables; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col>
                        {{classDay(timetable)}}
                    </ion-col>
                    <ion-col>
                        {{classTime(timetable)}}
                    </ion-col>
                    <ion-col size="2">{{ timetable.building | default }}</ion-col>
                    <ion-col size="2">{{ timetable.room | default }}</ion-col>
                    <ion-col size="2">{{ item.class.campus.description | default }}</ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #empty>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>

