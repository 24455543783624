import { Pipe, PipeTransform } from '@angular/core';

/*
 * If a value is null or undefined or an empty string, then return the configured no data app message.
 * Note: empty arrays should return empty arrays for iteration purposes.
 *
 * Usage:
 *   any | default
 */
@Pipe({ name: 'default' })
export class DefaultNoDataPipe implements PipeTransform {
    transform(data: unknown): unknown {
        if (data === null || data === undefined || data === '' || data === ' ') {
            return 'No data';
        }

        return data;
    }
}
