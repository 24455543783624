import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ServiceError, SubmittedApplications } from '@app/domain/models';
import { PersonViewComponent } from '../../model/person-view.component';
import { StudentAdapter } from '@core/adapters';
import { SubmittedApplicationsViewModel } from './submitted-applications-view.model';
import { sortByProp } from '@app/util';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './submitted-applications-view.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class SubmittedApplicationsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    submittedApplications: SubmittedApplicationsViewModel[];
    selected: string;

    constructor(private student: StudentAdapter, route: ActivatedRoute) {
        super();
        route
            .queryParams
            .subscribe(params => {
                this.selected = params['applicationNumber'];
            });
    }

    public load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getSubmittedApplications(super.identity.id)
            .subscribe({
                next: (result) => {
                    this.submittedApplications = this.buildViewModel(result);
                    this.isLoading = false;
                    this.isError = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    render() {
        this.load();
    }

    buildViewModel(submittedApplications: SubmittedApplications): SubmittedApplicationsViewModel[] {
        let submittedApp = sortByProp('descend', submittedApplications?.submitted, 'term.start');
        // Maps student id
        submittedApp = submittedApp.map(v => ({studentId: super.identity.id.toString(), ...v}));
        const researchApp = submittedApplications?.research;

        // Deferred applications link to the new deferred application. Link the new application back to the deferred.
        const deferredApplications = submittedApp.filter(app => app.deferred !== undefined);
        for (const application of deferredApplications) {
            const found = submittedApp.find(app => app.applicationNumber == application.deferred.applicationNumber) as SubmittedApplicationsViewModel;

            if (found) {
                found.defers = {
                    applicationNumber: application.applicationNumber,
                    term: application.term
                };
            }
        }

        if (researchApp && researchApp?.length > 0) {
            for (const research of researchApp) {
                const found = submittedApp.find(app => app.applicationNumber === research.applicationNumber) as SubmittedApplicationsViewModel;

                if (found) {
                    found.research = research;
                }
            }
        }

        return submittedApp;
    }
}

