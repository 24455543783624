import { Component, Input } from '@angular/core';
import { StudentChecklists, SubmittedApplication } from '@app/domain/models';
import { ChecklistView } from '@modules/person/components/checklists-view/checklists-view.model';

@Component({
    selector: 'app-checklists',
    templateUrl: './checklists.component.html',
    styleUrls: ['./checklists.component.scss'],
})
export class ChecklistsComponent {
    openPanels = new Set<number>();
    checklist: ChecklistView[];

    @Input() set checklists(checklists: StudentChecklists) {
        if (!checklists) {
            return;
        }

        const submittedApplications: SubmittedApplication[] = checklists.submitted ?? [];

        // Merges checklist and submitted applications data
        this.checklist = checklists.checklist?.map(
            t1 => ({...t1, application: submittedApplications.find(t2 => t2.applicationNumber === t1.admissionApplication) ?? null })
        );
    }

    isHideDetails(checklistId: number) {
        return this.openPanels.has(checklistId) ?? false;
    }

    handleToggleDetails(checklistId: number) {
        if (!this.openPanels.has(checklistId)) {
            this.openPanels.add(checklistId);
        } else {
            this.openPanels.delete(checklistId);
        }
    }
}