import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Visa } from '@domain/models';

@Component({
  selector: 'app-visa-details',
  templateUrl: './visa-details.component.html',
  styleUrl: './visa-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisaDetailsComponent {
    @Input() visa: Visa;
    @Input() highlight: boolean;
}
