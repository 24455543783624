import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Identity } from '@app/domain/models';
import { SearchAdapter } from '@core/adapters';
import {
    InfiniteScrollCustomEvent,
    Platform,
    ToastController
} from '@ionic/angular';

@Component({
    selector: 'app-search-page',
    templateUrl: './search.page.html',
    styleUrls: ['./search.page.scss'],
})
export class SearchPageComponent {
    searchResult: Identity[];
    hasNextPage = false;
    message: string;
    isLoading = true;
    hasResults = false;
    isMobile = false;

    constructor(
        private searchAdapter: SearchAdapter,
        private route: ActivatedRoute,
        private toastController: ToastController,
        platform: Platform,
        private title: Title) {

        this.isMobile = platform.is('mobile') || platform.is('mobileweb');

        this.route.queryParams
            .pipe(takeUntilDestroyed())
            .subscribe((params) => {
                const criteria = params.criteria;

                this.title.setTitle(`Search ${criteria}`);

                this.isLoading = true;
                this.hasResults = false;

                this.searchAdapter.search(criteria)
                        .subscribe(
                            {next: result => {
                                this.isLoading = false;
                                this.searchResult = result?.data;
                                this.hasNextPage = result?.hasNextPage;

                                if (this.searchResult?.length === 0) {
                                    this.message = "Sorry, we couldn't find anyone with those details. Please try searching something else.";
                                } else {
                                    this.hasResults = true;
                                }
                            },
                            error: () => {
                                this.message = 'Oops, something went wrong when searching';
                                this.isLoading = false;
                            }});
            }
        );
    }

    async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            position: this.isMobile ? 'bottom' : 'top',
            keyboardClose: true,
            duration: 3000,
            buttons: [{
                icon: 'close-outline',
                role: 'cancel'
            }]
        });

        await toast.present();
    }

    identityTrackBy(index: number, identity: Identity) {
        return identity.id;
    }

    onInfiniteScroll(ev: InfiniteScrollCustomEvent) {
        this.searchAdapter.nextPage()
                .subscribe(
                    {next: result => {
                        this.searchResult = result.data;
                        this.hasNextPage = result.hasNextPage;
                        void ev.target.complete();
                    },
                    error: () => {
                        void this.presentToast('Oops, something went wrong when loading the next page of results');
                        void ev.target.complete();
                    }});
    }
}