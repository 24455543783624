<ion-header id="header" mode="md">
    <ion-toolbar>
        <ion-grid class="ion-no-padding">
            <ion-row class="search-bar ion-justify-content-center">
                <ion-col size-lg="12" size-md="auto" class="ion-no-padding display-flex search-bar__wrapper">
                    <div class="search-bar__logo ion-align-self-center">
                        <a routerLink="/" title="Customer Profile Page">
                            <uoa-logo logo-type="horizontal" color="primary" size="xsmall"></uoa-logo>
                        </a>
                    </div>
                    <div class="search-bar__input ion-align-self-center ion-hide-lg-down">
                        <ion-searchbar
                                #searchbar
                                mode="md"
                                animated="true"
                                placeholder="Search Students"
                                show-clear-button="focus"
                                debounce="1000"
                                (keyup.enter)="onSearch($event)"
                        >
                        </ion-searchbar>
                        <ion-button mode="md" color="primary" fill="solid" (click)="onSearch()">
                            <app-icon name="search" colour="light" size="large" icon-style="outlined"></app-icon>
                        </ion-button>
                    </div>
                </ion-col>
                <ion-col size="12" class="ion-hide-lg-up display-flex ion-align-self-center">
                    <div class="search-bar__mobile">
                        <ion-searchbar
                            #searchbarMobile
                            mode="md"
                            animated="true"
                            placeholder="Search Students"
                            show-clear-button="focus"
                            debounce="1000"
                            (keyup.enter)="onSearch($event)"
                        >
                        </ion-searchbar>
                        <ion-button mode="md" color="primary" fill="solid" (click)="onSearch()">
                            <app-icon name="search" colour="light" size="large" icon-style="outlined"></app-icon>
                        </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
 
    <ng-content></ng-content>
    <ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
    <app-banner *ngIf="isAuditBannerOpen" colour="warning" duration="300000" (dismissed)="dismissAuditBanner()">
        <app-icon style="rounded" name="warning amber" size="small"></app-icon>
        <span class="ion-padding">Attention: Information accessed on the Customer Profile webpage is <em>AUDITED</em></span>
    </app-banner>
</ion-header>