import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FoundationEducation } from '@domain/models';

@Component({
  selector: 'app-foundation-education',
  templateUrl: './foundation-education.component.html',
  styleUrl: './foundation-education.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoundationEducationComponent {
    @Input() education: FoundationEducation[];

    providerName(education: FoundationEducation): string {
        if (education.qualification.code === 'OTH') {
            return education.providerNameOther;
        } else {
            return education.qualification.provider.description;
        }
    }
}
