import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Transcript } from '@domain/models';
import dayjs from 'dayjs';


@Component({
  selector: 'app-transcript-details',
  templateUrl: './transcript-details.component.html',
  styleUrl: './transcript-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranscriptDetailsComponent {
    @Input() transcript: Transcript;

    get requestAge() {
        const today = dayjs();

        return today.diff(dayjs(this.transcript.requestedOn), 'days');
    }

    get requestTime() {
        return dayjs(this.transcript.requestedOn).format('DD/MM/YYYY hh:mm A');
    }

    get updateTime() {
        return dayjs(this.transcript.updatedOn).format('DD/MM/YYYY hh:mm A');
    }
}
