import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Identity, Visa } from '@domain/models';

@Component({
  selector: 'app-visa-information',
  templateUrl: './visa-information.component.html',
  styleUrl: './visa-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisaInformationComponent {
    openPanels = new Set<number>();

    @Input()identity: Identity;
    @Input() visas: Visa[];

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }
}
