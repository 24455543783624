<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary" class="color-dark-1">
            <div class="full-screen display-flex ion-justify-content-center">Enrolment Cart Timetable</div>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <app-timetable-calendar [events]="events" calendarType="enrolment"></app-timetable-calendar>
</ion-content>