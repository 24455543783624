<div class="academic-standing">
    <ng-container *ngIf="mostRecentStanding; else noStanding">
        <app-accordian expanded="true" header="Academic Standing">

            <ion-grid class="table ion-no-padding">
                <div class="table__thead">

                    <ion-row>
                        <ion-col size="3">
                            Term
                        </ion-col>
                        <ion-col size="2">
                            Action
                        </ion-col>
                        <ion-col size="3">
                            Description
                        </ion-col>
                        <ion-col size="2">
                            Status
                        </ion-col>
                        <ion-col size="2" class="ion-text-center">
                            Action Date
                        </ion-col>
                    </ion-row>

                </div>
                <div class="table__tbody">

                    <ion-row>
                        <ion-col size="3">
                            {{ mostRecentStanding.term.description | default }}
                        </ion-col>
                        <ion-col size="2">
                            {{ mostRecentStanding.action.code | default }}
                        </ion-col>
                        <ion-col size="3">
                            {{ mostRecentStanding.action.description | default }}
                        </ion-col>
                        <ion-col size="2">
                            {{ mostRecentStanding.status.description | default }}
                        </ion-col>
                        <ion-col size="2" class="ion-text-center">
                            {{ mostRecentStanding.actionedOn | shortDate | default }}
                        </ion-col>
                    </ion-row>
                </div>
            </ion-grid>

            <app-accordian expanded="false" header="Academic Standing History">
                <ion-grid class="table ion-no-padding">
                    <div class="table__thead">

                        <ion-row>
                            <ion-col size="3">
                                Term
                            </ion-col>
                            <ion-col size="2">
                                Action
                            </ion-col>
                            <ion-col size="3">
                                Description
                            </ion-col>
                            <ion-col size="2">
                                Status
                            </ion-col>
                            <ion-col size="2" class="ion-text-center">
                                Action Date
                            </ion-col>
                        </ion-row>

                    </div>
                    <div class="table__tbody">

                        <ng-container *ngIf="acadStandingHistory?.length > 0; else empty">
                            <ion-row *ngFor="let history of acadStandingHistory">
                                <ion-col size="3">
                                    {{ history.term.description | default }}
                                </ion-col>
                                <ion-col size="2">
                                    {{ history.action.code | default }}
                                </ion-col>
                                <ion-col size="3">
                                    {{ history.action.description | default }}
                                </ion-col>
                                <ion-col size="2">
                                    {{ history.status.description | default }}
                                </ion-col>
                                <ion-col size="2" class="ion-text-center">
                                    {{ history.actionedOn | shortDate | default }}
                                </ion-col>
                            </ion-row>
                        </ng-container>
                        <ng-template #empty>
                            <ion-row>
                                <ion-col size="12" class="ion-text-center">No data to display</ion-col>
                            </ion-row>
                        </ng-template>

                    </div>
                </ion-grid>
            </app-accordian>

        </app-accordian>
    </ng-container>
    <ng-template #noStanding>
        <h3 class="header__notfound">
            No data to display for Academic Standing
        </h3>
    </ng-template>
</div>
