<app-field-list classList="margin-0">
    <ion-row class="programme-details">
        <ion-col class="ion-no-padding" size="8">
            <app-field-value label="Student Career No." labelSize="6" valSize="6">
                {{ programme.careerNumber | default }}
            </app-field-value>
            <app-field-value label="Academic Career" labelSize="6" valSize="6">
                {{ programme.programme.career.description | default }}
            </app-field-value>
            <app-field-value label="Plan(s)" labelSize="6" valSize="6">
                <ng-container *ngIf="programme.plans.length > 0; else emptyPlans">
                    <ng-container *ngFor="let plan of programme.plans">
                        {{ plan.code }} {{ plan.description }} ({{plan.option?.description ?? 'Taught'}})<br/>
                    </ng-container>
                </ng-container>
                <ng-template #emptyPlans>
                    No data
                </ng-template>
            </app-field-value>
            <app-field-value label="Academic Group" labelSize="6" valSize="6">
                <ng-container *ngIf="programme.groups.length > 0; else emptyGroups">
                    <ng-container *ngFor="let group of programme.groups">
                        {{ group }}<br />
                    </ng-container>
                </ng-container>
                <ng-template #emptyGroups>
                    No data
                </ng-template>
            </app-field-value>
            <app-field-value label="Campus" labelSize="6" valSize="6">
                {{ programme.programme.campus?.description | default }}
            </app-field-value>
        </ion-col>
        <ion-col class="ion-no-padding" size="4">          
            <app-field-value label="Programme Action" labelSize="6" valSize="6">
                {{ programme.action.description | default }}
            </app-field-value>
            <app-field-value label="Degree Checkout Status" labelSize="6" valSize="6">
                {{ programme.degreeStatus?.description | default }}
            </app-field-value>
            <app-field-value label="Required Points" labelSize="6" valSize="6">
                {{ programme.programme.requiredUnits | default }}
            </app-field-value>
            <app-field-value label="Programme GPA" labelSize="6" valSize="6">
                {{ programme.programmeGpa | default }}
            </app-field-value>
        </ion-col>
    </ion-row>
</app-field-list>

<ion-grid *ngIf="programme.milestones?.length > 0" class="table ion-no-padding ion-margin-top">
    <div class="table__thead">
        <ion-row>
            <ion-col size="3">Milestone</ion-col>
            <ion-col size="3" class="ion-text-center">Attempted On</ion-col>
            <ion-col size="3" class="ion-text-center">Is Completed?</ion-col>
            <ion-col size="3">Grade</ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">
        <ng-container *ngFor="let milestone of programme.milestones; even as isEven; index as i">
            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                <ion-col size="3">{{ milestone.description | default }}</ion-col>
                <ion-col size="3" class="ion-text-center">{{ milestone.attemptedOn | shortDate | default }}</ion-col>
                <ion-col size="3" class="ion-text-center">{{ milestone.isComplete | yesNo | default }}</ion-col>
                <ion-col size="3">{{ milestone.grade?.description | default }}</ion-col>
            </ion-row>
        </ng-container>
    </div>
</ion-grid>