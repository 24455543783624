import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';
import { StudentAdapter } from '@core/adapters';
import { IntendedCourse } from '@app/domain/models';

@Component({
  selector: 'app-intended-courses-modal',
  templateUrl: './intended-courses-modal.component.html',
  styleUrl: './intended-courses-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IntendedCourseModalComponent implements OnInit {
  isLoading = true;
  isError = false;

  @Input() application: SubmittedApplicationsViewModel;
  intendedCourses: IntendedCourse[];

  constructor(private modalCtrl: ModalController, private studentAdapter: StudentAdapter) {}

  ngOnInit() {
    this.studentAdapter.getIntendedCourses(this.application.studentId, this.application.applicationNumber).subscribe({
      next: (result) => {
        this.intendedCourses = result;
        this.isLoading = false;
        this.isError = false;
      },
      error: () => {
        this.isLoading = false;
        this.isError = false;
      },
    });
  }

  close() {
    void this.modalCtrl.dismiss();
  }
}
