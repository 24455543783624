<ng-container *ngIf="contracts?.length > 0; else noContracts">
    <ng-container *ngFor="let info of contracts">
        <div *ngIf="info.sponsor" class="sponsors">
            <h3 class="color-primary">Sponsor Details</h3>
            <ion-grid class="ion-no-padding">
                <ion-row class="background-light-1 ion-padding">
                    <ion-col size="6">
                        <app-field-list>
                            <app-field-value label="Organisation ID">{{ info.sponsor.code | default }}</app-field-value>
                            <app-field-value label="Organisation ID Description">{{ info.sponsor.description | default }}</app-field-value>
                        </app-field-list>
                    </ion-col>
                    <ion-col size="6">
                        <app-field-list>
                            <app-field-value label="Type">{{ info.sponsor.type.description | default }}</app-field-value>
                            <app-field-value label="Status">{{ info.sponsor.status.description | default }}</app-field-value>
                        </app-field-list>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <div class="ion-padding">
                <app-field-list>
                    <app-field-value label="Academic Career">{{ info.sponsor.academicCareer.code | default }}</app-field-value>
                </app-field-list>

                <app-field-list>
                    <app-field-value label="Academic Programme">{{ info.sponsor.programme.code | default }}</app-field-value>
                    <app-field-value label="Academic Programme Description">{{ info.sponsor.programme.description | default }}</app-field-value>
                </app-field-list>

                <app-field-list>
                    <app-field-value label="Academic Plan">{{ info.sponsor?.plan?.code | default }}</app-field-value>
                    <app-field-value label="Academic Plan Description">{{ info.sponsor?.plan?.description | default }}</app-field-value>
                </app-field-list>

                <app-field-list>
                    <app-field-value label="Start Term">{{ info.sponsor.startTerm.description | default }}</app-field-value>
                    <app-field-value label="End Term">{{ info.sponsor.endTerm.description | default }}</app-field-value>
                </app-field-list>

                <app-field-list>
                    <app-field-value label="Comments">{{ info.sponsor.comments | default }}</app-field-value>
                </app-field-list>
            </div>
        </div>

        <hr class="margin-top-l margin-bottom-l" />

        <div *ngIf="info.contract" class="contracts ion-padding-top">
            <h3 class="color-primary margin-top-0">Contract Summary</h3>
            <ion-grid class="ion-no-padding">
                <ion-row class="background-light-1 ion-padding">
                    <ion-col size="6">
                        <app-field-list>
                            <app-field-value label="Organisation">{{ info.contract.organisationName | default }}</app-field-value>
                        </app-field-list>

                        <app-field-list>
                            <app-field-value label="Contract Number">{{ info.contract.contractNum | default }}</app-field-value>
                            <app-field-value label="Contract Description">{{ info.contract.description | default }}</app-field-value>
                        </app-field-list>

                        <app-field-list>
                            <app-field-value label="Student Maximum">{{ info.sponsor?.studentPaymentCap | currency | default }}</app-field-value>
                        </app-field-list>
                    </ion-col>
                    <ion-col size="6">
                        <app-field-list>
                            <app-field-value label="Term">{{ info.contract?.contractTerm?.description | default }}</app-field-value>
                            <app-field-value label="Status">{{ info.contract.status.description | default }}</app-field-value>
                        </app-field-list>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ng-container>
</ng-container>
<ng-template #noContracts>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>
