<div *ngIf="recent.length > 0">
  <ion-list>
    <ng-template ngFor let-viewed [ngForOf]="recent">
      <a [routerLink]="viewed.path">
        <ion-item  [button]="true" [detail]="true">
          {{viewed.description}}
        </ion-item>
      </a>
    </ng-template>    
  </ion-list>

  <div class="ion-text-center">
    <ion-button size="small" color="secondary" (click)="clear()">Clear</ion-button>
  </div>
</div>