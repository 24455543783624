import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-result-empty',
  templateUrl: './search-result-empty.component.html',
  styleUrls: ['./search-result-empty.component.scss']
})
export class SearchResultEmptyComponent {
  @Input() message: string;
}
