import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AuthModule } from '@uoa/auth';
import { ErrorPagesModule } from '@uoa/error-pages';
import { AppComponent } from './app.component';
import { ApplicationRouting, RouteStrategyProviders } from './app-routes';
import { ServiceProviders, TagManagerService, NoticeService } from '@core/services';
import { AdapterProviders } from '@core/adapters';
import { ConfigurationProvider } from '@core/providers';
import { PersonModule } from '@modules/person/person.module';
import { SearchModule } from '@modules/search/search.module';
import { HomeModule } from '@app/modules/home/home.module';
import { GTMModules } from './gtm.config';
import { HomePageComponent } from '@app/modules/home/pages';
import { SharedComponentsModule } from '@modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import {applicationInitFactory} from './app-init';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
  ],

  imports: [
    BrowserModule,
    CommonModule,
    // Core modules
    IonicModule.forRoot(),
    ApplicationRouting,
    AuthModule,
    ErrorPagesModule,
    IonicStorageModule.forRoot(),
    ...GTMModules,

    // Application modules
    HomeModule,
    SearchModule,
    PersonModule,
    SharedComponentsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: applicationInitFactory,
      deps: [
        TagManagerService, NoticeService

      ],
      multi: true,
    },
    ...RouteStrategyProviders,
    ...ServiceProviders,
    ...AdapterProviders,
    ConfigurationProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
