import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomCalendarEventInput, CustomCalendarEvent } from '@modules/person/components/timetable-view/timetable-view.model';
import { uniq } from 'ramda';
import { CalendarOptions, EventClickArg, CalendarApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayjs from 'dayjs';
@Component({
  selector: 'app-timetable-calendar',
  templateUrl: './timetable-calendar.component.html',
})
export class TimetableCalendarComponent {
    @Input() isLoading: boolean;
    @Input() events: CustomCalendarEventInput[];
    @Input() calendarType: 'default' | 'enrolment' = 'default';
    @Output() eventSelected = new EventEmitter<CustomCalendarEvent>();
    @Output() navigated = new EventEmitter<Date>();

    selected: CustomCalendarEvent;
    weekOptions: CalendarOptions;
    monthOptions: CalendarOptions;

    private weekCalendar: CalendarApi;
    private monthCalendar: CalendarApi;

    private eventTypes: string[];
    get types() {
        if (!this.eventTypes && this.events) {
            if (this.calendarType === 'default') {
                this.eventTypes = uniq(this.events.map((v) => v.extendedProps.component.description.toLowerCase()));
            }

            if (this.calendarType === 'enrolment') {
                this.eventTypes = uniq(this.events.map((v) => v.extendedProps.studentEnrolmentStatus?.description.toLowerCase()));
            }
        }

        // Filters out falsy values
        return this.eventTypes.filter(type => type);
    }

    constructor() {
        this.monthOptions = {
            plugins: [dayGridPlugin],
            initialView: 'dayGridMonth',
            height: 'auto',
            headerToolbar: {
                left: 'prev',
                center: 'title',
                right: 'next'
            },
            editable: false,
            selectable: false,
            firstDay: 1,
            dayMaxEvents: 1,
            stickyHeaderDates: false,
            eventContent: () => {
                return '';
            },
            dayHeaderContent: (arg) => {
                const day = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
                return day[arg.date.getDay()];
            },
            viewDidMount: (arg) => {
                this.monthCalendar = arg.view.calendar;
            },
            eventClick: (arg: EventClickArg) => {
                this.weekCalendar.gotoDate(arg.event.start);
                this.navigated.emit(this.weekCalendar.getDate());
                this.selected = null;
            }
        };

        this.weekOptions = {
            plugins: [timeGridPlugin],
            initialView: 'timeGridWeek',
            height: 'auto',
            headerToolbar: {
                left: 'prev',
                center: 'title',
                right: 'next'
            },
            editable: false,
            selectable: false,
            firstDay: 1,
            handleWindowResize: true,
            allDaySlot: false,
            expandRows: true,
            slotEventOverlap: true,
            slotMinTime: '08:00:00',
            slotMaxTime: '20:00:00',
            dayHeaderContent: (arg) => ({
                html: arg.date.toLocaleDateString('en-US', { weekday: 'short' }) + '<br/>' + arg.date.getDate()
            }),
            customButtons: {
                next: {
                    click: () => {
                        this.weekCalendar.next();
                        this.navigated.emit(this.weekCalendar.getDate());
                    }
                },
                prev: {
                    click: () => {
                        this.weekCalendar.prev();
                        this.navigated.emit(this.weekCalendar.getDate());
                    }
                },
            },
            viewDidMount: (arg) => {
                this.weekCalendar = arg.view.calendar;
                if (this.events) {
                    const now = new Date();

                    // Find the next event closest to today in the future
                    const next = this.events.find(ev => ev.startRecur >= now);
                    if (next) {
                        this.weekCalendar.gotoDate(next.startRecur);
                        this.monthCalendar.gotoDate(next.startRecur);
                    } else {
                        // Find the latest event in the past closest to today
                        const latest = this.events
                                        .filter(ev => ev.startRecur < now)
                                        .reduce((previous, current) => previous?.startRecur >= current?.startRecur ? previous : current);

                        if (latest) {
                            this.weekCalendar.gotoDate(latest.endRecur);
                            this.monthCalendar.gotoDate(latest.endRecur);
                        }
                    }
                }
            },
            eventClick: (arg: EventClickArg) => {
                this.selected = arg.event as CustomCalendarEvent;
                this.eventSelected.emit(this.selected);
                this.monthCalendar.gotoDate(arg.event.start);
            },
        };
    }

    toTime(dt: Date): string {
        return dayjs(dt).format('hh:mm A');
    }
}
