<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2>Entrance Qualification</h2>
        <ng-container *ngIf="entranceQualification; else noQualification">
            <ion-grid class="entranceQualification">
                <ion-row class="ion-justify-content-between">
                    <ion-col size="auto" size-xl="6">
                        <span class="label">Entrance Qualification:</span>
                        <span class="value">{{ entranceQualification.qualification?.description | default }}</span></ion-col>
                    <ion-col size="auto" size-xl="3">
                        <span class="label">Status:</span>
                        <span class="value">{{ entranceQualification.status?.description | default }} </span>
                    </ion-col>
                    <ion-col size="auto" size-xl="3">
                        <span class="label">Date:</span>
                        <span class="value">{{ entranceQualification.verifiedOn | shortDate | default }}</span>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="auto">
                        <span class="label">Comments:</span>
                    </ion-col>
                    <ion-col size="">
                        <span class="value">{{ entranceQualification.comment | default }}</span>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ng-container>
        <ng-template #noQualification>
            <h3 class="header__notfound">No data to display</h3>
        </ng-template>

        <h2>Comments</h2>
        <app-comments [comments]="comments" accordianHeader="Linked application data" />
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>