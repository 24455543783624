import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Concession } from '@domain/models';

@Component({
  selector: 'app-concession-details',
  templateUrl: './concession-details.component.html',
  styleUrl: './concession-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcessionDetailsComponent {
    @Input() concession: Concession;

    concessionStatusReason() {
      if (this.concession.enrolMessage == null && this.concession.enrolDescription == null) {
        return null;
      }

      let reason = this.concession.enrolMessage ?? '';
      if (this.concession.enrolDescription) {
        reason += ' ' + this.concession.enrolDescription;
      }
      return reason;
    }
}
