<ion-list class="ion-no-padding">
    <ng-container  *ngFor="let indicator of serviceIndicators; let isLast = last">
        <ion-item class="ion-no-padding" [lines]="isLast ? 'none':''">
            <app-icon *ngIf="indicator.positive"
                      aria-hidden="true"
                      name="check circle"
                      icon-style="outlined"
                      slot="start"
                      size="large"
                      colour="success"></app-icon>
            <app-icon  *ngIf="!indicator.positive"
                       aria-hidden="true"
                       name="cancel"
                       slot="start"
                       icon-style="outlined"
                       size="large"
                       color="danger"></app-icon>
            <ion-label class="ion-padding-start">{{indicator.indicator.description}}</ion-label>
            <ion-button slot="end" fill="clear" size="default" (click)="openModal(indicator)">
                More details...
            </ion-button>
        </ion-item>
    </ng-container>
</ion-list>