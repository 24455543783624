import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SecondaryEducation} from '@domain/models';

@Component({
  selector: 'app-secondary-education',
  templateUrl: './secondary-education.component.html',
  styleUrl: './secondary-education.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecondaryEducationComponent {
    @Input() education: SecondaryEducation[];
}
