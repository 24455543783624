import { Injectable } from '@angular/core';
import { PersonAdapter, SearchAdapter } from '@app/core/adapters';
import { Identity } from '@domain/models';
import { Observable, catchError, BehaviorSubject, filter } from 'rxjs';

/**
 * Hold a single instance of the person in the view so that components can get the current instance without
 * excessive prop drilling
 *
 */
@Injectable()
export class PersonViewService {
    private instance: Identity;
    private publisher: BehaviorSubject<Identity> = new BehaviorSubject(undefined);

    constructor(
        private personAdapter: PersonAdapter,
        private searchAdapter: SearchAdapter
        ) {}

    /**
     * Get the current instance if the passed id or upi matches the stored identity. Otherwise replace the current instance with
     * the new identity.
     *
     * This should be called by the high order component which has access to the route parameter
     *
     * @param idOrUpi
     * @returns Observable of Identity
     */
    getOrRetrieveInstance(idOrUpi: string): Observable<Identity> {
        if (! (this.instance?.id === Number.parseInt(idOrUpi) || this.instance?.upi === idOrUpi) ) {
            this.publisher.complete();
            this.publisher = new BehaviorSubject(undefined);

            // Get the value first from the search results, otherwise retrieve from EPR (via experience API)
            this.searchAdapter
                    .getPerson(idOrUpi)
                    .pipe(
                        catchError(() => this.personAdapter.getPerson(idOrUpi))
                    )
                    .subscribe({
                        next: (person) => {
                            this.instance = person;
                            this.publisher.next(person);
                        },
                        error: () => {
                            // We cannot publish an error as this will kill the subject
                            this.publisher.next(null);
                        }
                    });
        }

        return this.publisher.pipe(filter(value => value !== undefined));
    }

    /**
     * Get the current observable instance with full history
     *
     * @returns Observable of Identity
     */
    getInstance(): Observable<Identity> {
        return this.publisher;
    }

    toRouterLink(viewName: string) {
        return ['/person', this.instance.id, viewName];
    }
}

export const PersonViewServiceProvider={ provide: PersonViewService };