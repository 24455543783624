<div class="invoice-summary">
    <app-field-list>
        <ion-row>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value label="Email Address">{{ invoice.emailAddress | default }}</app-field-value>
                <app-field-value label="Type Description">{{ invoice.type.description | default }}</app-field-value>
                <app-field-value label="Amount">{{ invoice.total | currency | default }}</app-field-value>
            </ion-col>
            <ion-col class="ion-no-padding" size="6">
                <app-field-value label="Postal Address">
                    <div *ngIf="invoice.address1">{{ invoice.address1 }} <br /></div>
                    <div *ngIf="invoice.address2">{{ invoice.address2 }} <br /></div>
                    <div *ngIf="invoice.address3">{{ invoice.address3 }} <br /></div>
                    <div *ngIf="invoice.address4">{{ invoice.address4 }} <br /></div>
                </app-field-value>
            </ion-col>
        </ion-row>
    </app-field-list>
</div>
<ion-grid *ngIf="invoice.items?.length > 0" class="table ion-no-padding margin-top-l">
    <div class="table__thead">
        <ion-row>
            <ion-col size="" class="ion-text-center">
                Item Number
            </ion-col>
            <ion-col size="">
                Type
            </ion-col>
            <ion-col size="" class="ion-text-center">
                Item Amount
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">
        <ng-container *ngFor="let item of invoice.items; even as isEven">
            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                <ion-col size="" class="ion-text-right">{{ item.id | default }}</ion-col>
                <ion-col size="" class="">{{ item.type?.description | default }}</ion-col>
                <ion-col size="" class="ion-text-right">{{ item.amount | currency | default }}</ion-col>
            </ion-row>
        </ng-container>
    </div>
</ion-grid>
