<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary" class="padding-right-l padding-left-l">
            <div class="display-flex ion-justify-content-between ion-align-self-center padding-right-l padding-left-l">
                Assessment Referral Information
                <span *ngIf="referralView" class="color-medium not-bold font-default ion-align-self-center">
                    Last updated: {{ referralView.assessment?.lastUpdated | date:'h:mm a dd/MM/yy' | default }}
                </span>
            </div>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="padding-l">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <div *ngIf="referralView" class="container padding-right-l padding-left-l">
            <app-field-list classList="margin-top-0">
                <ion-row class="details-header">
                    <ion-col size="12" class="bold color-primary">
                        {{ application.career.description }} Application
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="6" class="padding-right-l">
                        <app-field-value labelSize="6" valSize="6" label="User ID">
                            {{ application.studentId | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Source">
                            {{ referralView.assessment?.calculationMethod?.description | default}}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Programme">
                            {{ application.programme.code | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Prog Description">
                            {{ application.programme.description | default }}
                        </app-field-value>
                    </ion-col>
                    <ion-col size="6">
                        <app-field-value labelSize="6" valSize="6" label="Assigned To">
                            {{ application.programme?.faculty?.description | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Admit Type">
                            {{ referralView.assessment?.admitType?.description | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Plan">
                            <ng-container *ngFor="let plan of application.plans">
                                <span class="ion-no-margin">{{ plan.code | default }}</span>
                            </ng-container>
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Plan Description">
                            <ng-container *ngFor="let plan of application.plans">
                                <span class="ion-no-margin">{{ plan.description | default }}</span>
                            </ng-container>
                        </app-field-value>
                    </ion-col>
                </ion-row>
            </app-field-list>

            <hr />

            <app-field-list>
                <ion-row>
                    <ion-col size="6" class="padding-right-l">
                        <!--Doctoral-->
                        <ng-container *ngIf="isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="Refer To">
                                {{ referralView.assignment?.description | default }}
                            </app-field-value>
                            <app-field-value labelSize="6" valSize="6" label="Supervisor ID">
                                {{ referralView.supervisor?.code | default }}
                            </app-field-value>
                        </ng-container>

                        <!--Non-doctoral-->
                        <ng-container *ngIf="!isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="Referred To">
                                {{ referralView.assessment?.owner?.description | default }}
                            </app-field-value>
                            <app-field-value labelSize="6" valSize="6" label="Plan Group">
                                {{ referralView.assessment?.admitType?.group?.description }}
                            </app-field-value>
                        </ng-container>
                    </ion-col>
                    <ion-col size="6">
                        <!--Doctoral-->
                        <ng-container *ngIf="isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="Supervisor Name">
                                {{ referralView.supervisor?.description | default }}
                            </app-field-value>
                            <app-field-value labelSize="6" valSize="6" label="Supervisor Model">
                                {{ referralView.supervisorModel?.description | default }}
                            </app-field-value>
                        </ng-container>

                        <!--Non-doctoral-->
                        <ng-container *ngIf="!isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="Qualifying Plan">
                                {{ referralView.qualifyingPlan?.code | default }}
                            </app-field-value>
                            <app-field-value labelSize="6" valSize="6" label="Qualifying Description">
                                {{ referralView.qualifyingPlan?.description | default }}
                            </app-field-value>
                        </ng-container>
                    </ion-col>
                </ion-row>
            </app-field-list>

            <hr />

            <app-field-list>
                <ion-row>
                    <ion-col size="6" class="padding-right-l">
                        <!--Doctoral-->
                        <ng-container *ngIf="isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="Result">
                                {{ referralView.assessment?.result?.description | default }}
                            </app-field-value>
                        </ng-container>

                        <!--Non-doctoral-->
                        <ng-container *ngIf="!isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="GPA Type">
                                {{ referralView.assessment?.gpaType?.description | default }}
                            </app-field-value>
                            <app-field-value labelSize="6" valSize="6" label="GPA">
                                {{ referralView.assessment?.gpa | default }}
                            </app-field-value>
                        </ng-container>
                    </ion-col>
                    <ion-col size="6">
                        <!--Doctoral-->
                        <ng-container *ngIf="isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="Recommendation">
                                {{ referralView.assessment?.recommendation?.description | default }}
                            </app-field-value>
                        </ng-container>

                        <!--Non-doctoral-->
                        <ng-container *ngIf="!isDoctoral">
                            <app-field-value labelSize="6" valSize="6" label="GPA Status">
                                {{ referralView.assessment?.gpaStatus?.description | default }}
                            </app-field-value>
                        </ng-container>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12">
                        <app-field-value labelSize="6" valSize="6" label="Assessment Notes" />
                        {{ referralView.assessment?.comments | default }}
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="!isDoctoral" class="margin-top-m">
                    <ion-col size="12">
                        <app-field-value labelSize="6" valSize="6" label="Calculation Process Log" />
                        <div [innerHTML]="referralView.assessment?.processLog | default"></div>
                    </ion-col>
                </ion-row>
            </app-field-list>

            <hr />

            <div>
                <app-accordian expanded="false" header="Assessment History">
                    <ion-grid class="table ion-no-padding">
                        <div class="table__thead">
                            <ion-row>
                                <ion-col size="" class="ion-text-center">
                                    Last updated
                                </ion-col>
                                <ion-col size="">
                                    Result
                                </ion-col>
                                <ion-col size="2">
                                    Action
                                </ion-col>
                                <ion-col size="2">
                                    Admit type
                                </ion-col>
                                <ion-col size="2">
                                    Source
                                </ion-col>
                            </ion-row>
                        </div>
                        <div class="table__tbody">
                            <ng-container *ngIf="referralView.assessments?.length > 0; else noAssessments">
                                <ng-container *ngFor="let assessment of referralView.assessments even as isEven">
                                    <ion-row [class]="!isEven ? 'row-grey':'row-white'">
                                        <ion-col size="" class="ion-text-center">
                                            {{ assessment.lastUpdated | date:'h:mm a dd/MM/yy' }}
                                        </ion-col>
                                        <ion-col size="">
                                            {{ assessment.result?.description | default }}
                                        </ion-col>
                                        <ion-col size="2">
                                            {{ application.programme?.reason?.action?.description | default }}
                                        </ion-col>
                                        <ion-col size="2">
                                            {{ assessment?.admitType?.description | default }}
                                        </ion-col>
                                        <ion-col size="2">
                                            {{ assessment.calculationMethod?.description | default }}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row [class]="!isEven ? 'row-grey':'row-white'" *ngIf="assessment.comments">
                                        <ion-col size="12">
                                            <p class="color-primary bold font-default margin-top-0 margin-bottom-m">Assessment Notes:</p>
                                            {{ assessment.comments | default }}
                                        </ion-col>
                                    </ion-row>
                                </ng-container>
                            </ng-container>
                            <ng-template #noAssessments>
                                <ion-row>
                                    <ion-col size="12" class="ion-text-center">No data to display</ion-col>
                                </ion-row>
                            </ng-template>
                        </div>
                    </ion-grid>
                </app-accordian>
            </div>

            <div>
                <app-accordian expanded="false" header="Assignment History">
                    <ion-grid class="table ion-no-padding">
                        <div class="table__thead">
                            <ion-row>
                                <ion-col size="">
                                    Code
                                </ion-col>
                                <ion-col size="">
                                    Description
                                </ion-col>
                                <ion-col size="" class="ion-text-center">
                                    Assigned On
                                </ion-col>
                            </ion-row>
                        </div>
                        <div class="table__tbody">
                            <ng-container *ngIf="referralView.assignments?.length > 0; else noAssignments">
                                <ng-container *ngFor="let assignment of referralView.assignments even as isEven">
                                    <ion-row [class]="!isEven ? 'row-grey':'row-white'">
                                        <ion-col size="">
                                            {{ assignment.code | default }}
                                        </ion-col>
                                        <ion-col size="">
                                            {{ assignment.description | default }}
                                        </ion-col>
                                        <ion-col size="" class="ion-text-center">
                                            {{ assignment.assignedOn | date:'h:mm a dd/MM/yy' | default }}
                                        </ion-col>
                                    </ion-row>
                                    <ion-row [class]="!isEven ? 'row-grey':'row-white'" *ngIf="assignment.note">
                                        <ion-col size="12">
                                            <p class="color-primary bold font-default margin-top-0 margin-bottom-m">Notes:</p>
                                            {{ assignment.note }}
                                        </ion-col>
                                    </ion-row>
                                </ng-container>
                            </ng-container>
                            <ng-template #noAssignments>
                                <ion-row>
                                    <ion-col size="12" class="ion-text-center">No data to display</ion-col>
                                </ion-row>
                            </ng-template>
                        </div>
                    </ion-grid>
                </app-accordian>
            </div>
        </div>
    </app-dataload-spinner>

    <div class="container padding-right-l padding-left-l" *ngIf="!isLoading && !referralView">
        <p class="header__notfound ">No data to display</p>
    </div>
</ion-content>

