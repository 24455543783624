<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2>Priority Groups</h2>

        <ion-grid class="ion-no-padding">
            <ion-row>
                <ion-col size="12" size-lg="6" class="ion-padding background-light-1">
                    <ion-list class="ion-no-padding">
                        <ng-container *ngIf="hasPriorityGroups; else noPriorityGroups">
                            <ion-item lines="none" color="light" *ngFor="let group of priorityGroups">
                                <span class="tick">
                                    <app-icon *ngIf="group.hasPriority" name="check" slot="start" colour="success" size="large" aria-hidden="true"></app-icon>
                                    <app-icon *ngIf="!group.hasPriority" name="clear" slot="start" colour="dark" size="large" aria-hidden="true"></app-icon>
                                </span>

                                <ion-label>
                                    <span [class]="group.hasPriority ? 'color-dark':'color-dark-3-tint'">
                                        {{ group.description }}
                                    </span>
                                </ion-label>
                            </ion-item>
                        </ng-container>
                        <ng-template #noPriorityGroups>
                            <ion-item lines="none" color="light">
                                <ion-label color="dark">Student does not have any active priority groups.</ion-label>
                            </ion-item>
                        </ng-template>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>