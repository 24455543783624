import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '../../model/person-view.component';
import { Identity } from '@app/domain/models';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrl: './not-available.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotAvailableComponent extends PersonViewComponent {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render(identity: Identity): void {
    // Not used
  }
}
