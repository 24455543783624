<ng-container *ngIf="comments?.length > 0; else noComments">
    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">
                    Date
                </ion-col>
                <ion-col size="2">
                    Admin function
                </ion-col>
                <ion-col size="6">
                    Admin function description
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let comment of comments; even as isEven">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2" class="ion-text-center">{{ comment.commentDate | shortDate | default }}</ion-col>
                    <ion-col size="2">{{ comment.adminFunction.code | default }}</ion-col>
                    <ion-col size="6">{{ comment.adminFunction.description| default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="comment.id"
                            (toggled)="handleToggleDetails(comment.id)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(comment.id)"
                    class="more-details">
                    <ion-col size="12">
                        <app-comment-details [comment]="comment" [accordianHeader]="accordianHeader"
                            [highlight]="isEven" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </div>
    </ion-grid>
</ng-container>
<ng-template #noComments>
    <h3 class="header__notfound">No data to display</h3>
</ng-template>