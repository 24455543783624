<ion-card class="result-card">
    <ion-card-header>
        <ion-card-title class="font-xl">
            <a [routerLink]="['/person', identity.id]" title="View {{identity.firstName}} {{identity.lastName}} details">{{identity.firstName}} {{identity.lastName}}</a>
        </ion-card-title>
        <ion-card-subtitle class="font-l">{{identity.upi}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
        <ion-grid class="ion-no-padding">
            <ion-row>
                <ion-col size="6">UoA ID:</ion-col>
                <ion-col size="6">{{identity.id}}</ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="6">Date of Birth:</ion-col>
                <ion-col size="6">{{identity.dob | date:'dd/MM/YYYY'}}</ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="6">Legal Name:</ion-col>
                <ion-col size="6">{{toFullName(primaryName)}}</ion-col>
            </ion-row>            
            <ion-row>
                <ion-col size="6">Emails:</ion-col>
                <ion-col size="6">
                    <ul class="ion-no-margin ion-no-padding">
                        <li class="ion-no-padding" *ngFor="let email of identity.emails">
                            <a href="mailto:{{email.email}}" title="Send email">{{email.email}} ({{email.type}})</a>
                        </li>
                    </ul>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="6">Phone Numbers:</ion-col>
                <ion-col size="6">
                    <ul class="ion-no-margin ion-no-padding">
                        <li class="ion-no-padding" *ngFor="let ph of identity.phones">
                            {{toTelephone(ph)}}
                        </li>
                    </ul>
                </ion-col>
            </ion-row>           
            <ion-row>
                <ion-col size="6">Mailing Address:</ion-col>
                <ion-col size="6">
                    <ul class="ion-no-margin ion-no-padding">
                        <li class="ion-no-padding">
                            {{toAddress(mailingAddress)}}
                        </li>
                    </ul>
                </ion-col>
            </ion-row>               
            <ion-row>
                <ion-col size="12" class="margin-top-l display-flex ion-justify-content-center">
                    <ion-button fill="clear" [routerLink]="['/person', identity.id]" size="medium">
                        <span class="">More info</span>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>