import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StudentAdapter } from '@app/core/adapters';
import { ServiceError, ServiceIndicator } from '@app/domain/models';
import { PersonViewComponent } from '../../model/person-view.component';
import { ServiceIndicatorsViewModel } from './service-indicators-view.model';
import { groupBy } from 'ramda';

@Component({
  templateUrl: './service-indicators-view.component.html',
  styleUrl: './service-indicators-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ServiceIndicatorsViewComponent extends PersonViewComponent {
    tabLabels = ['active', 'inactive'];
    isLoading = true;
    isError = false;
    message: string;
    serviceIndicators: ServiceIndicatorsViewModel;
    private tab: string;

    constructor(private adapter: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.adapter.getServiceIndicators(super.identity.id)
            .subscribe( {
                next: (result: ServiceIndicator[]) => {
                    this.serviceIndicators = this.buildViewModel(result);
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isError = true;
                    this.isLoading = false;
                }
        });
    }

    render() {
        this.load();
    }

    buildViewModel (result: ServiceIndicator[]) {
        return groupBy((v: ServiceIndicator) => {
            const currentDate = new Date();
            if (currentDate > v.startOn && (v.endOn == null || currentDate < v.endOn)) {
                return 'active';
            }

            return 'inactive';
        }, result);
    }

    handleChange(tabValue: string) {
        this.tab = tabValue;
    }

    /**
     * The custom getter allows us to calculate the selected tab after all the inputs have been set.
     */
    get selectedTab(): string {
        if (!this.tab) {
            if (this.serviceIndicators?.inactive?.length > 0) {
                this.tab = 'inactive';
            } else {
                this.tab = 'active';
            }
        }

        return this.tab;
    }
}
