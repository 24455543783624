<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary" class="color-dark-1">
            <div class="full-screen display-flex ion-justify-content-center">Timetable</div>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <app-timetable-calendar [events]="events" [isLoading]="isLoading" (navigated)="onNavigation($event)"></app-timetable-calendar>
    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</ion-content>