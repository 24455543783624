import { Component } from '@angular/core';
import { Degree, ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { sortByProp } from '@app/util';

@Component({
  selector: 'app-degrees-view',
  templateUrl: './degrees-view.component.html',
  styleUrl: './degrees-view.component.scss'
})
export class DegreesViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    degrees: Degree[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getDegrees(super.identity.id)
            .subscribe(
                {
                    next: (result: Degree[]) => {
                        this.degrees = sortByProp('descend', result, 'conferredOn');
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isError = true;
                        this.isLoading = false;
                    },
                });
    }

    render() {
        this.load();
    }
}
