<ng-container *ngIf="studyLink && studyLink?.length > 0; else noApplications">
    <ng-container *ngFor="let item of studyLink;">
        <h3 class="color-primary">{{item.year}}</h3>

        <ion-grid class="table ion-no-padding" slot="content">
            <div class="table__thead">
                <ion-row>
                    <ion-col class="ion-text-center">
                        Study Period Start
                    </ion-col>
                    <ion-col>
                        Status
                    </ion-col>
                    <ion-col class="ion-text-center">
                        Last Updated
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                        Details
                    </ion-col>
                </ion-row>
            </div>
            <div class="table__tbody">
                <ng-container *ngFor="let data of item.studyLink; even as isEven; index as i">

                    <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                        <ion-col class="ion-text-center">{{ data.studyStart | shortDate | default }}</ion-col>
                        <ion-col>{{ data.status.code | default }}</ion-col>
                        <ion-col class="ion-text-center">{{ data.lastUpdated | shortDate | default }}</ion-col>
                        <ion-col size="2" class="ion-text-center">
                            <app-details-button [id]="item.year + i" (toggled)="handleToggleDetails(item.year + i)"></app-details-button>
                        </ion-col>
                    </ion-row>
                    <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(item.year + i)" class="more-details">
                        <ion-col size="12">
                            <app-study-link-application-details [studyLink]="data" />
                        </ion-col>
                    </ion-row>
                </ng-container>
            </div>
        </ion-grid>
    </ng-container>
</ng-container>

<ng-template #noApplications>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>