<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
  <ng-container *ngIf="notices.length > 0; else noNotices">
    <ion-grid>
      <ng-container *ngFor="let notice of notices">
          <ion-row>
              <ion-col size="1" class="ion-text-center">
                <app-icon name="info" size="xl" *ngIf="notice.severity.code==='M'"></app-icon>
                <app-icon name="warning" size="xl" colour="warning" *ngIf="notice.severity.code==='W'"></app-icon>
                <app-icon name="error" size="xl" colour="danger" *ngIf="notice.severity.code==='E'"></app-icon>
              </ion-col>
              <ion-col size="11">
                <h4>{{ notice.title }}</h4>
                <p>{{notice.message}}</p>
              </ion-col>
         </ion-row>
      </ng-container>
    </ion-grid>
  </ng-container>
</app-dataload-spinner>
<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>

<ng-template #noNotices>
  <h3 class="header__notfound">No notices</h3>
</ng-template>