import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Transcript } from '@domain/models';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrl: './transcript.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranscriptComponent {
    openPanels = new Set<number>();

    @Input() transcripts: Transcript[];

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }
}
