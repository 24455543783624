import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { Group, ServiceError } from '@domain/models';
import { StudentAdapter } from '@app/core/adapters';

@Component({
  templateUrl: './groups-view.component.html',
  styleUrl: './groups-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class GroupsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    groups: Group[];

    constructor(private studentAdapter: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.studentAdapter.getGroups(super.identity.id)
            .subscribe(
                {
                    next: result => {
                        this.groups = result;
                        this.sort(this.groups);
                        this.isLoading = false;
                    },

                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isLoading = false;
                        this.isError = true;
                    },
        });
    }

    render(): void {
        this.load();
    }

    private sort(groups: Group[]) {
        return groups.sort((a: Group, b: Group) => {
                if (a.isActive && !b.isActive) {
                    return -1;
                } else if (!a.isActive && b.isActive) {
                    return 1;
                } else {
                    return b.effectiveDate.getTime() - a.effectiveDate.getTime();
                }
        });
    }
}
