import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThirdPartyInfoViewModel } from '../third-party-info-view.model';

@Component({
  selector: 'app-third-party-info',
  templateUrl: './third-party-info.component.html',
  styleUrl: './third-party-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThirdPartyInfoComponent {
    @Input() contracts: ThirdPartyInfoViewModel[];
}
