import { Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ScholarshipPayment, ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { sortByProp } from '@app/util';
import { InfiniteScrollCustomEvent } from '@ionic/angular';

@Component({
  selector: 'app-scholarship-payments-view',
  templateUrl: './scholarship-payments-view.component.html',
  styleUrl: './scholarship-payments-view.component.scss'
})
export class ScholarshipPaymentsViewComponent extends PersonViewComponent {
  isLoading = true;
  isError = false;
  nextPageLink: string;
  message: string;
  payments: ScholarshipPayment[];

  constructor(private student: StudentAdapter) {
    super();
  }

  public load(): void {
    this.isError = false;
    this.isLoading = true;

    this.student.getScholarshipsPayments(super.identity.id)
        .subscribe({
          next: (result) => {
            this.payments = sortByProp('descend', result.data, 'paymentOn');
            this.isLoading = false;
            // eslint-disable-next-line no-underscore-dangle
            this.nextPageLink = result._links?.next?.href;
          },
          error: (error) => {
            if (error instanceof ServiceError) {
              this.message = error.message;
            }
            this.isError = true;
            this.isLoading = false;
          },
        });
  }

  render() {
    this.load();
  }

  onInfiniteScroll(ev: InfiniteScrollCustomEvent) {
    this.student.getScholarshipsPaymentsPages(this.nextPageLink)
        .subscribe({
          next: (result) => {
              if (result.data) {
                  this.payments = this.payments.concat(result.data);
                  this.payments = sortByProp('descend', this.payments, 'paymentOn');
              }
              // eslint-disable-next-line no-underscore-dangle
              this.nextPageLink = result._links?.next?.href;
              void ev.target.complete();
          },
          error: () => {
            void ev.target.complete();
          }
        });
  }
}
