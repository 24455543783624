import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Invoice } from '@domain/models';

@Component({
  selector: 'app-tax-invoice-details',
  templateUrl: './tax-invoice-details.component.html',
  styleUrl: './tax-invoice-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxInvoiceDetailsComponent {
    @Input() invoice: Invoice;
    @Input() highlight: boolean;
}
