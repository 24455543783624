import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConcessionsViewModel } from '../concessions-view.model';

@Component({
  selector: 'app-concessions',
  templateUrl: './concessions.component.html',
  styleUrl: './concessions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcessionsComponent {
    selectedTab = 'current';
    tabLabels = ['current', 'past'];
    viewModel: ConcessionsViewModel;

    @Input() set concessions(concessions: ConcessionsViewModel) {
        this.viewModel = concessions;

        if (concessions?.past?.length > 0 && !concessions?.current) {
            this.selectedTab = 'past';
        }
    }

    get concessions() {
        return this.viewModel;
    }

    handleChange(tabValue: string) {
        this.selectedTab = tabValue;
    }
}
