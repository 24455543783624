import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonViewService } from '../services/person-view.service';
import { isUpi, isIdentityId } from '@app/util';
import { AppConfiguration, Identity } from '@app/domain/models';
import { RecentlyViewedService } from '@app/core/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewMenuItem, MenuItem, PrimaryMenuItem } from '../model/menu';
import { APP_CONFIG } from '@app/core/providers';
import { ModalController } from '@ionic/angular';
import { PersonMenuService } from '../services/person-menu-service';
import { TimetableViewComponent } from '@modules/person/components';

/**
 * Master component to dynamically display person views.
 *
 * This component is cached by the ionic framework between view navigation
 */
@Component({
    selector: 'app-person-page',
    templateUrl: './person.page.html',
    styleUrls: ['./person.page.scss']
})
export class PersonPageComponent {
    isLoading = true;
    idOrUpi: string;
    identity: Identity;
    currentView: ViewMenuItem;
    isMobileMenuOpen: boolean;
    menu: PrimaryMenuItem[]=[];

    private viewComponents: ViewMenuItem[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private title: Title,
        @Inject(APP_CONFIG) public configuration: AppConfiguration,
        private menuService: PersonMenuService,
        private personViewService: PersonViewService,
        private recentlyViewedService: RecentlyViewedService,
        private modalCtrl: ModalController) {

        /**
         * Watch for view/route changes.
        */
        this.route.params
            .pipe(
                takeUntilDestroyed()
            )
            .subscribe((params) => this.initialise(params.id, params.view));
    }

    private async initialise(idOrUpi: string, view: string) {
        if (this.menu.length === 0) {
            this.menu = await this.menuService.getMenuItems();
            this.viewComponents = this.menu.flatMap((menu) => menu.views);
        }

        // If this component is already initialised with the id, then just display the view.
        if (this.idOrUpi === idOrUpi) {
            this.gotoView(view);
            return;
        }

        if ( !(isIdentityId(idOrUpi) || isUpi(idOrUpi))) {
            throw new Error(`${idOrUpi} is not a valid person identifier`);
        }

        this.isLoading = true;

        // Build all the navigation view urls
        this.viewComponents.forEach(view => {
            if (view.type === 'component') {
                view.url = `/person/${idOrUpi}/${view.id}`;
            } else if (view.url) {
                view.url = view.url.replace('{id}', idOrUpi);
            }
            view.isSelected = false;
        });

        this.personViewService.getOrRetrieveInstance(idOrUpi)
            .subscribe({
                next: (identity) => {
                    this.identity = identity;
                    // A search for an unknown upi is published as a null identity and render a person not found message.
                    if (identity !== null) {
                        this.idOrUpi = idOrUpi;
                        this.recentlyViewedService.add(
                            `${this.identity.id}`,
                            `${this.identity.firstName} ${this.identity.lastName}`,
                            `/person/${identity.id}/${view}`
                        );
                        this.gotoView(view);
                    }
                    this.isLoading = false;
                },
                error: () => {
                    this.identity = null;
                    this.isLoading = false;
                }
            });
    }

    onMenuSelect(menu: MenuItem) {
        if (menu.type === 'menu') {
            this.selectMenu(menu.id);
        } else {
            this.gotoView(menu.id);
        }
    }

    /**
     * Goto a specific view
     *
     * @param viewId
     * @returns
     */
    private gotoView(viewId: string) {
        const view = this.viewComponents.find(comp => comp.id === viewId);
        if (!view) {
            this.selectMenu('person');
            return;
        }

        const parent = this.menu.find(menu => menu.views.indexOf(view) >= 0);
        this.selectMenu(parent.id, viewId);
    }

    /**
     * Select a group menu and optionally a sub-view. If the view id is not specified, the first view is selected.
     *
     * @param groupId
     * @param viewId
     */
    private selectMenu(groupId: string, viewId?: string) {
        const groupMenu = this.menu.find((menu) => menu.id===groupId);

        if (groupMenu) {
            const menu = this.menu.find((menu) => menu.id === groupId);
            this.menu.forEach(item => item.isSelected = false);
            menu.isSelected = true;

            if (groupMenu.views.length > 0) {
                // Deselects all view
                this.menu.flatMap(menu => menu.views).forEach(view => view.isSelected = false);
                let selectedView: MenuItem;

                // The selected menu is either the matching view id or the first in the list if no view id specified.
                if (viewId) {
                    selectedView = this.viewComponents.find(menu => menu.id === viewId);
                } else {
                    selectedView = menu.views[0];

                    /**
                     * Replace the current url with the first view's generated url
                     */
                    const url = this.router.createUrlTree([selectedView.url]).toString();
                    this.router.navigate([url]);

                    // No need to keep processing as the router will navigate back to this component with the view.
                    return;
                }

                selectedView.isSelected = true;
                this.displayView(selectedView.id);
            } else {
                this.currentView = null;
            }
        }
    }

    /**
     * Display a component view in the view panel
     *
     * @param viewId
     */
    private displayView(viewId: string) {
        this.currentView = this.viewComponents.find(comp => comp.id === viewId);

        if (this.currentView) {
            this.title.setTitle(`${this.identity.firstName} ${this.identity.lastName} - ${this.currentView.title}`);
        }
    }

    async openTimeTable() {
        const modal = await this.modalCtrl.create({
            component: TimetableViewComponent,
            componentProps: {
                studentId: this.identity.id,
            },
            cssClass: 'timetable-modal'
        });

        void modal.present();
    }

    openMobileMenu(event: boolean) {
        this.isMobileMenuOpen = event;
    }
}