<ion-grid class="banner {{colour}} ion-no-padding">
    <ion-row class="ion-align-items-center">
        <ion-col size="11" class="ion-text-center">
            <div class="content">
                <ng-content></ng-content>
            </div>
        </ion-col>
        <ion-col size="1" class="ion-text-right">
            <a href="#" (keypress)="handleClick($event)" (click)="handleClick($event)" title="Close banner" class="btn-dismiss">
                <ion-icon name="close-outline" size="small"></ion-icon>
            </a>
        </ion-col>
    </ion-row>
</ion-grid>