<ng-container *ngIf="selectedExams?.length > 0; else empty">

    <app-tabs [selectedTab]="selectedTab" [tabLabels]="tabLabels" (tabChange)="onTabChange($event)" />
    <ion-grid class="table ion-no-padding">
        <div class="table__thead table-header">
            <ion-row class="ion-align-items-center">
                <ion-col size="">
                    Course
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Exam Date
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Start Time
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    End Time
                </ion-col>
                <ion-col size="2">
                    Mode
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngFor="let exam of selectedExams; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="">{{ course(exam) | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ exam.examStart | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ exam.examStart | date: 'HH:mm' | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ exam.examEnd | date: 'HH:mm' | default }}</ion-col>
                    <ion-col size="2">{{ exam.mode?.code | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)" />
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)">
                    <ion-col size="12">
                        <app-exam-details [exam]="exam" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </div>
    </ion-grid>

</ng-container>
<ng-template #empty>
    <h3 class="header__notfound">No data to display</h3>
</ng-template>