import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FooterComponent,
  HeaderComponent,
  LoadingIndicatorComponent,
  DataloadSpinnerComponent,
  BooleanComponent,
  AccordianComponent,
  TextTruncateComponent,
  DetailsButtonComponent,
  ErroryRetryComponent,
  FieldListComponent,
  FieldValueComponent,
  TabsComponent,
  IconComponent,
  BannerComponent,
  AlertBannerComponent
} from './components';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import {
    DefaultNoDataPipe,
    LinkifyPipe,
    ShortDatePipe,
    YearPipe,
    YesNoPipe
} from '@modules/shared/pipes';
import { FullCalendarModule } from '@fullcalendar/angular';

const components = [
  FooterComponent,
  HeaderComponent,
  LoadingIndicatorComponent,
  DataloadSpinnerComponent,
  BooleanComponent,
  AccordianComponent,
  TextTruncateComponent,
  DetailsButtonComponent,
  ErroryRetryComponent,
  FieldListComponent,
  FieldValueComponent,
  TabsComponent,
  IconComponent,
  BannerComponent,
  AlertBannerComponent
];

const pipes = [
    DefaultNoDataPipe,
    ShortDatePipe,
    YearPipe,
    YesNoPipe,
    LinkifyPipe
];


@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        FullCalendarModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}