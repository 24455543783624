import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PersonAdapter } from '@core/adapters';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';

@Component({
  selector: 'app-submitted-applications-table',
  templateUrl: './submitted-applications-table.component.html',
  styleUrl: './submitted-applications-table.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class SubmittedApplicationsTableComponent implements OnInit {
    openPanels = new Set<string>();

    @Input() submittedApplications: SubmittedApplicationsViewModel[];
    @Input() selected: string;

    constructor(private person: PersonAdapter) {
    }

    ngOnInit(): void {
        if (this.selected && this.submittedApplications.find(appl => appl.applicationNumber === this.selected)) {
            this.handleToggleDetails(this.selected);
        }
    }

    handleToggleDetails(applicationNumber: string) {
        if (!this.openPanels.has(applicationNumber)) {
            this.openPanels.add(applicationNumber);
        } else {
            this.openPanels.delete(applicationNumber);
        }
    }

    isHideDetails(applicationNumber: string) {
        return this.openPanels.has(applicationNumber) ?? false;
    }
}
