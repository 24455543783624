<ng-container *ngIf="applications?.items?.length > 0; else noUnsubmittedApplications">

    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2">Admit Term</ion-col>
                <ion-col size="3">Acad Programme</ion-col>
                <ion-col size="2">Acad Plan</ion-col>
                <ion-col size="3" class="ion-text-center">ATS Application No.</ion-col>
                <ion-col size="2" class="ion-text-center">Details</ion-col>
            </ion-row>
        </div>

        <div class="table__tbody">
            <ng-container *ngFor="let application of applications.items; even as isEven">
                <ion-row  [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ application.admitTerm.description | default }}</ion-col>
                    <ion-col size="3">{{ application.programme.description | default }}</ion-col>
                    <ion-col size="2">
                        <ng-container *ngIf="application.programme.academicPlans?.length > 0; else noAcadPlan">
                            <ion-text *ngFor="let plan of application.programme.academicPlans; index as i">
                                {{plan.code}}<br *ngIf="i<=application.programme.academicPlans.length" />
                            </ion-text>
                        </ng-container>
                        <ng-template #noAcadPlan>
                            <ion-text>No data</ion-text>
                        </ng-template>
                    </ion-col>
                    <ion-col size="3" class="ion-text-right">{{ application.id | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="application.id" (toggled)="handleToggleDetails(application.id)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isApplicationOpen(application.id)" class="more-details">
                    <ion-col size="12">
                        <app-unsubmitted-application-details [application]="application" [highlight]="isEven" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </div>
        
    </ion-grid>

    <div *ngIf="applications?.englishProficiency">
        <ion-text color="primary">
            <h6>English Language Proficiency</h6>
        </ion-text>
        <ion-grid class="table ion-no-padding">
            <div class="table__thead">
                <ion-row>
                    <ion-col size="4">
                        <ion-label>Admin Term</ion-label>
                    </ion-col>
                    <ion-col size="4">
                        <ion-label>Acad Programme</ion-label>
                    </ion-col>
                    <ion-col size="4">
                        <ion-label>Acad Plan</ion-label>
                    </ion-col>
                </ion-row>
            </div>
            <div class="table__tbody">
                <ion-row>
                    <ion-col size="4" class="ion-text-center">
                        {{ applications.englishProficiency.englishLanguageRequired | yesNo | default }}
                    </ion-col>
                    <ion-col size="4" class="ion-text-center">
                        {{ applications.englishProficiency.englishTestCompleted | yesNo | default }}
                    </ion-col>
                    <ion-col size="4" class="ion-text-center">
                        {{ applications.englishProficiency.interestedInEla | yesNo | default }}
                    </ion-col>
                </ion-row>
            </div>
        </ion-grid>
    </div>

</ng-container>

<ng-template #noUnsubmittedApplications>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>

