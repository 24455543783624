import { Component, Input } from '@angular/core';
import { ScholarshipAwarded } from '@app/domain/models/scholarship-awarded';
import { StudentProgramme } from '@domain/models';


@Component({
  selector: 'app-scholarships-history',
  templateUrl: './scholarships-history.component.html',
  styleUrl: './scholarships-history.component.scss'
})
export class ScholarshipsHistoryComponent {
    openPanels = new Set<number>();
    programme: StudentProgramme;

    selectedTab = 'active';
    tabLabels = ['active', 'inactive', 'unsuccessful'];

    allScholarships: ScholarshipAwarded[] = [];
    tabScholarships: ScholarshipAwarded[] = [];


    @Input() set scholarships(scholarships: ScholarshipAwarded[]) {
        this.allScholarships = scholarships;
        this.setTabScholarships();
    }

    handleToggleDetails(index: number): void {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number): boolean {
        return this.openPanels.has(index) ?? false;
    }

    handleChangeTab(tabValue: string): void {
        this.selectedTab = tabValue;
        this.openPanels.clear();
        this.setTabScholarships();
    }

    private setTabScholarships(): void {
        if (this.allScholarships) {
            this.tabScholarships = this.allScholarships.filter(
                (scholarship) => this.getScholarshipTab(scholarship) == this.selectedTab);
        }
    }

    private getScholarshipTab(scholarship: ScholarshipAwarded): string {
        const code = scholarship.status?.code;

        if (code == 'INA') {
            return 'inactive';
        } else if (['DEC', 'WIT', 'NOF', 'INE'].includes(code)) {
            return 'unsuccessful';
        } else {
            return 'active';
        }
    }
}
