<div class="delna">
    <ng-container *ngIf="delna?.length > 0; else noResults">
        <app-accordian expanded="true" header="Diagnostic English Language Needs (DELNA)">

            <ion-grid class="table ion-no-padding">
                <div class="table__thead">
                    <ion-row>
                        <ion-col size="2" class="ion-text-center">
                            Test Date
                        </ion-col>                    
                        <ion-col size="3">
                            Component
                        </ion-col>
                        <ion-col size="1" class="ion-text-center">
                            Score
                        </ion-col>
                        <ion-col size="1" class="ion-text-center">
                            Letter Score
                        </ion-col>                        
                        <ion-col size="3">
                            Notes
                        </ion-col>
                        <ion-col size="2" class="ion-text-center">
                            Loaded On
                        </ion-col>                            
                    </ion-row>
                </div>

                <div class="table__tbody">
                    <ion-row *ngFor="let row of delna">
                        <ion-col size="2" class="ion-text-center">
                            {{ row?.testedOn | shortDate }}
                        </ion-col>                   
                        <ion-col size="3">
                            {{ row?.component.description | default }}
                        </ion-col>
                        <ion-col size="1" class="ion-text-right">
                            {{ row?.score | default }}
                        </ion-col>
                        <ion-col size="1" class="ion-text-right">
                            {{ row?.letterScore | default }}
                        </ion-col>                        
                        <ion-col size="3">
                            {{ row?.notes1 }}<br/>
                            {{ row?.notes2 }}
                        </ion-col>
                        <ion-col size="2" class="ion-text-center">
                            {{ row?.loadedOn | shortDate }}
                        </ion-col>                             
                    </ion-row>
                </div>
            </ion-grid>

        </app-accordian>
    </ng-container>
    <ng-template #noResults>
        <h3 class="header__notfound">
            No data to display for Diagnostic English Language Needs
        </h3>
    </ng-template>
</div>
