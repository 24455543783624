<ng-container *ngIf="aelr; else noAELR" >
    <app-accordian expanded="true" header="AELR Assessment">

        <ion-grid class="table ion-no-padding">
            <div class="table__thead">

                <ion-row>
                    <ion-col size="2" class="ion-text-center">
                        Date
                    </ion-col>
                    <ion-col size="4">
                        AELR Status
                    </ion-col>
                    <ion-col size="4">
                        Status Reason
                    </ion-col>
                    <ion-col size="2">
                        Entry Type
                    </ion-col>
                </ion-row>

            </div>
            <div class="table__tbody">

                <ion-row>
                    <ion-col size="2" class="ion-text-center">
                        {{ aelr?.assessedOn | shortDate | default }}
                    </ion-col>
                    <ion-col size="4">
                        {{ aelr?.status?.description | default }}
                    </ion-col>
                    <ion-col size="4">
                        {{ aelr?.reason?.description | default }}
                    </ion-col>
                    <ion-col size="2">
                        {{ aelr?.method?.description | default }}
                    </ion-col>
                </ion-row>

            </div>
        </ion-grid>

        <ion-item-group>

            <ion-item lines="none">
                <ion-label class="ion-no-margin ion-margin-top">
                    <ion-grid>
                        <ion-row class="bold">
                            <ion-col></ion-col>
                            <ion-col size="3" offset="1">
                                Pathway available
                            </ion-col>
                            <ion-col size="4">
                                Pathway met
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                    <hr class="background-medium" />
                </ion-label>
            </ion-item>

            <ion-item lines="none">
                <ion-label class="ion-no-margin ion-margin-bottom">
                    <ion-grid>
                        <ion-row>
                            <ion-col class="ion-text-right bold">Test Results:</ion-col>
                            <ion-col size="3" offset="1">{{ aelr?.pathway.school.isAvailable | yesNo | default }}</ion-col>
                            <ion-col size="4">{{ aelr?.pathway.school?.status?.description || 'N/A' }}</ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col class="ion-text-right bold">DELNA:</ion-col>
                            <ion-col size="3" offset="1">{{ aelr?.pathway.delna.isAvailable | yesNo | default }}</ion-col>
                            <ion-col size="4">{{ aelr?.pathway.delna?.status?.description || 'N/A' }}</ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col class="ion-text-right bold">AELR Course:</ion-col>
                            <ion-col size="3" offset="1">{{ aelr?.pathway.aelr.isAvailable | yesNo | default }}</ion-col>
                            <ion-col size="4">{{ aelr?.pathway.aelr?.status?.description || 'N/A' }}</ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col class="ion-text-right bold">UOA Foundation/Other Completion:</ion-col>
                            <ion-col size="3" offset="1">{{ aelr?.pathway.foundation.isAvailable | yesNo | default }}</ion-col>
                            <ion-col size="4">{{ aelr?.pathway.foundation?.status?.description || 'N/A' }}</ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col class="ion-text-right bold">ACG/TAY level achieved:</ion-col>
                            <ion-col size="3" offset="1">{{ aelr?.pathway.taylors.isAvailable | yesNo | default }}</ion-col>
                            <ion-col size="4">{{ aelr?.pathway.taylors?.status?.description || 'N/A' }}</ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-label>
            </ion-item>

        </ion-item-group>
    </app-accordian>
</ng-container>
<ng-template #noAELR>
    <h3 class="header__notfound">
        No data to display for AELR Assessment
    </h3>
</ng-template>