import dayjs from 'dayjs';
import { CacheStorage } from './cache';

/**
 * In memory cache entry
 */
interface CacheEntry {
	key: string;
	expiresAfter?: Date;
	value: unknown;
}

/**
 * In memory cache storage using a javascript Map (which is supported in all browsers we target)
 *
 */
export class InMemoryCache implements CacheStorage {
	private cache: Map<string, CacheEntry> = new Map();

	/**
	 * Add a value to the cache with an option expiry time (from now)
	 *
	 * @param key
	 * @param value
	 * @param expiry
	 */
	put(key: string, value: unknown, expiry?: number): boolean {
		if (this.cache.has(key)) {
			return true;
		}

		let expiresAfter: Date;
		if (expiry > 0) {
			expiresAfter = dayjs().add(expiry, 'seconds').toDate();
		}

		this.cache.set(key, {
			key,
			value,
			expiresAfter
		});

		// Schedule on next tick
		setTimeout(() => this.expireAll(), 0);

		return true;
	}

	/**
	 * Get the cached entry if it exists and has not expired
	 *
	 * @param key
	 */
	get<T>(key: string): T {
		if (!this.cache.has(key)) {
			return undefined;
		}

		const entry = this.cache.get(key);

		if (entry.expiresAfter && dayjs().isAfter(entry.expiresAfter)) {
			this.cache.delete(key);
			return undefined;
		}

		return entry.value as T;
	}

	/**
	 * Delete all cached items based on key regular expression
	 *
	 * @param match
	 */
	delete(match: RegExp): void {
		const keys = this.cache.keys();
		for (const key of keys) {
			if (match.test(key)) {
				this.cache.delete(key);
			}
		}
	}

	private expireAll(): void {
		for (const entry of this.cache.entries()) {
			const [key, value] = entry;

			if (value.expiresAfter && dayjs().isAfter(value.expiresAfter)) {
				this.cache.delete(key);
			}
		}
	}
}
