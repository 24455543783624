import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { sortByProp } from '@app/util';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { StudyLinkLoan } from '@domain/models/study-link-loan';

@Component({
  templateUrl: './studylink-loan-view.component.html',
  styleUrl: './studylink-loan-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class StudyLinkLoanViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    openPanels = new Set<number>();
    studyLinkLoans: StudyLinkLoan[];

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getStudyLinkLoans(super.identity.id)
            .subscribe({
                next: result => {
                    // Sort most recent StudyLink loan
                    result = sortByProp('descend', result, 'studyStart');

                    this.studyLinkLoans = result;
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }
}
