<ng-container *ngIf="identity && visas && visas?.length > 0; else noVisas">

    <ion-item lines="none" color="light" class="ion-margin-bottom">
        <ion-label>
            <ion-grid>
                <ion-row>
                    <ion-col size="auto" class="bold margin-right-l">Country</ion-col>
                    <ion-col size="auto" *ngFor="let citizenship of identity.citizenships">
                        {{ citizenship.country }}
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-label>
    </ion-item>
    <ion-grid class="table ion-no-padding" slot="content">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">Issue Date</ion-col>
                <ion-col size="2" class="ion-text-center">Expiry Date</ion-col>
                <ion-col size="3">Visa Type</ion-col>
                <ion-col size="3">Programme</ion-col>
                <ion-col size="2" class="ion-text-center">Details</ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">

            <ng-container *ngFor="let visa of visas; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2" class="ion-text-center">{{ visa.issuedOn | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ visa.expiresOn | shortDate | default }}</ion-col>
                    <ion-col size="3">{{ visa.type.description | default }} ({{ visa.type.code | default }})</ion-col>
                    <ion-col size="3">{{ visa.academicProgramme?.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-visa-details [visa]="visa" [highlight]="isEven" />
                    </ion-col>
                </ion-row>
            </ng-container>

        </div>
    </ion-grid>
</ng-container>
<ng-template #noVisas>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>