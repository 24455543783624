import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AELR } from '@domain/models';

@Component({
  selector: 'app-aelr',
  templateUrl: './aelr.component.html',
  styleUrl: './aelr.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AelrComponent {
    @Input() aelr: AELR;
}
