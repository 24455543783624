import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({ name: 'shortDate' })
export class ShortDatePipe implements PipeTransform {
    transform(data: string | Date | number): string {
        if (data === null || data === undefined || data === '' || data === '1900-01-01') {
            return null;
        }

        if (data instanceof Date && data.getFullYear() === 1900) {
            return null;
        }

        return dayjs(data).format('DD/MM/YYYY');
    }
}
