<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary">{{ item?.type?.description || 'Comments' }}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-text *ngIf="item">
        <span [innerHTML]="item?.type?.longDescription | default | linkify"></span>
    </ion-text>
    <ion-text *ngIf="checklist">
        {{ checklist.comment | default }}
    </ion-text>
</ion-content>