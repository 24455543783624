import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { AppStorageService } from '@core/services';

/**
 * Keep track of recently viewed pages
 *
 */
@Injectable({
  providedIn: 'root',
})
export class RecentlyViewedService {
  private readonly STORAGE_KEY = 'recentlyViewed';
  private readonly LIMIT = 10;
  private subject: BehaviorSubject<RecentlyViewed[]> = new BehaviorSubject([]);

  public constructor(private storage: AppStorageService) {

  }

  public async add(key: string, description: string, path: string): Promise<void> {
    if (key === undefined || description === undefined || path === undefined) {
      return;
    }

    const stored = await this.getStored();

    while (stored.length >= this.LIMIT) {
      stored.pop();
    }

    const inserted: RecentlyViewed = {
      viewedAt: new Date(),
      key,
      description,
      path,
    };

    const found = stored.findIndex((entry) => entry.key == key);
    if (found >= 0) {
      const updated = stored[found];
      updated.viewedAt = new Date();
      stored.splice(found, 1);
      stored.unshift(updated);
    } else {
      stored.splice(0, stored.length - this.LIMIT, inserted);
    }

    this.subject.next(stored);

    return this.storage.setItem(this.STORAGE_KEY, JSON.stringify(stored));
  }

  public async clearAll(): Promise<void> {
    await this.storage.removeItem(this.STORAGE_KEY);
    this.subject.next([]);
  }

  private async getStored(): Promise<RecentlyViewed[]> {
    const stored = await this.storage.getItem(this.STORAGE_KEY);

    try {
      if (!stored) {
        return [];
      }

      return JSON.parse(stored as string, (key, value) => {
        if (key === 'viewedAt') {
          return new Date(Date.parse(value));
        }

        return value;
      });
    } catch (ex) {
      return [];
    }
  }

  public getRecentlyViewed(): Observable<RecentlyViewed[]> {
    from(this.getStored()).subscribe(stored => this.subject.next(stored));

    return this.subject;
  }
}

export interface RecentlyViewed {
  viewedAt: Date;
  key: string;
  description: string;
  path: string;
}

export const RecentlyViewedServiceProvider = { provide: RecentlyViewedService };
