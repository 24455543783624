<app-page-header [isLoading]="isLoading"></app-page-header>
<ion-content>
    <div class="content-wrapper">

        <ng-container *ngIf="isLoading; else searchResultView">
            <section class="search-results">
                <app-search-result-card-skeleton></app-search-result-card-skeleton>
            </section>
        </ng-container>

        <ng-template #searchResultView>
            <ng-container *ngIf="hasResults; else emptyResult">
                <section class="search-results">
                    <app-search-result-card
                        *ngFor="let identity of searchResult; trackBy: identityTrackBy"
                        [identity]="identity">
                    </app-search-result-card>
                    <ion-infinite-scroll *ngIf="hasNextPage" (ionInfinite)="onInfiniteScroll($event)">
                        <ion-infinite-scroll-content></ion-infinite-scroll-content>
                    </ion-infinite-scroll>
                </section>
            </ng-container>

            <ng-template #emptyResult>
                <app-search-result-empty [message]="message"></app-search-result-empty>
            </ng-template>
        </ng-template>

    </div>
</ion-content>
<app-page-footer></app-page-footer>
