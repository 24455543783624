<ng-container *ngIf="education?.length > 0; else noEducation">
    <app-accordian expanded="true" header="Secondary Qualification">
        <ion-grid class="table ion-no-padding" slot="content">
            <div class="table__thead">
                <ion-row>
                    <ion-col size="2" class="ion-text-center">
                        End Year
                    </ion-col>
                    <ion-col size="2">
                        Country
                    </ion-col>
                    <ion-col size="2">
                        Code
                    </ion-col>
                    <ion-col>
                        Qualification
                    </ion-col>
                    <ion-col size="1" class="ion-text-center">
                        UE Equiv.
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                        Awarded
                    </ion-col>
                </ion-row>
            </div>
            <div class="table__tbody">
                <ng-container *ngFor="let ed of education">
                    <ion-row>
                        <ion-col size="2" class="ion-text-right">{{ ed.academicYear | default }}</ion-col>
                        <ion-col size="2">{{ ed.country?.description | default }}</ion-col>
                        <ion-col size="2">{{ ed.qualification.code | default }}</ion-col>
                        <ion-col>{{ ed.qualification.description | default }}</ion-col>
                        <ion-col size="1" class="ion-text-center"><app-boolean [positive]="true" [value]="ed.qualification.universityEntranceEquivalent"></app-boolean></ion-col>
                        <ion-col size="2" class="ion-text-center"><app-boolean [positive]="true" [value]="ed.awarded"></app-boolean></ion-col>
                    </ion-row>
                </ng-container>
            </div>
        </ion-grid>
    </app-accordian>
</ng-container>

<ng-template #noEducation>
    <h3 class="header__notfound">
        No data to display for Secondary Qualification
    </h3>
</ng-template>