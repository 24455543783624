import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { SubmittedApplicationsGroup } from './grouped-applications';
import dayjs from 'dayjs';
import { groupBy } from 'ramda';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';

@Component({
    selector: 'app-submitted-applications',
    templateUrl: './submitted-applications.component.html',
    styleUrl: './submitted-applications.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmittedApplicationsComponent {
    tabLabels = ['current', 'past'];
    submittedApplicationsGroup: SubmittedApplicationsGroup;
    @Input() selected: string;

    private tab: string;

    private isApplicationOpen(status) {
        return !['ENRL', 'DECL', 'DEIN', 'OFDC', 'OFEX', 'WADM', 'WAPP'].includes(status);
    }

    @Input() set submittedApplications(applications: SubmittedApplicationsViewModel[]) {
        const today = dayjs();
        const groupApplications = groupBy((application: SubmittedApplicationsViewModel) => {
            // Doctoral is current for the current year and greater or is not closed
            if (application.career.code === 'UC02' || application.term.code.endsWith('1')) {
                if (application.term.academicYear >= today.year() || this.isApplicationOpen(application.status?.code)) {
                    return 'current';
                }

                return 'past';
            }

            const termStart = dayjs(application.term.start).add(2, 'week');
            if (termStart.isBefore(today)) {
                return 'past';
            }

            return 'current';
        });

        if (applications) {
            this.submittedApplicationsGroup = groupApplications(applications);
        }
    }

    handleChange(tabValue: string) {
        this.tab = tabValue;
    }

    /**
     * The custom getter allows us to calculate the selected tab after all the inputs have been set.
     */
    get selectedTab(): string {
        if (!this.tab) {
            if (!this.submittedApplicationsGroup.current || this.hasPastApplications()) {
                this.tab = 'past';
            } else {
                this.tab = 'current';
            }
        }

        return this.tab;
    }

    private hasPastApplications(): boolean {
        return this.submittedApplicationsGroup.past && this.submittedApplicationsGroup.past.findIndex(appl => appl.applicationNumber === this.selected) >= 0;
    }
}
