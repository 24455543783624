import { Component, Input } from '@angular/core';
import { Graduation } from '@domain/models';

@Component({
  selector: 'app-graduation-applications',
  templateUrl: './graduation-applications.component.html',
  styleUrl: './graduation-applications.component.scss'
})
export class GraduationApplicationsComponent {
    openPanels = new Set<number>();

    @Input() graduations: Graduation[];

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }
}
