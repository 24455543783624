import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnglishLanguageProficiency, ServiceError } from '@app/domain/models';
import { PersonViewComponent } from '../../model/person-view.component';
import { StudentAdapter } from '@app/core/adapters';

@Component({
  templateUrl: './english-proficiency-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class EnglishProficiencyViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    englishLanguageProficiency: EnglishLanguageProficiency;

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getEnglishLanguageProficiency(super.identity.id)
            .subscribe(
            {
                next: (result) => {
                    this.englishLanguageProficiency = result;
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isError = true;
                    this.isLoading = false;
                },
    });
    }

    render() {
        this.load();
    }
}
