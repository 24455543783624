import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { PriorEducation, ServiceError} from '@domain/models';
import { StudentAdapter } from '@core/adapters';

@Component({
  templateUrl: './prior-education-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class PriorEducationViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;

    priorEducation: PriorEducation;

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getPriorEducation(super.identity.id)
            .subscribe({
                next: result => {
                    this.priorEducation = result;
                    this.isLoading = false;
                },
                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    render(): void {
        this.load();
    }
}
