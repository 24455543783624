<div class="alert-banner-container" *ngIf="isNameExceedLength">
    <app-alert-banner type="warning">
        The name has been shortened because it exceeds the maximum allowed length.
    </app-alert-banner>
</div>

<div class="attendee-name-container">
    <p class="margin-0 bold display-flex ion-align-items-end color-dark-1">
        Name to be used on Graduation Certificate
        <ion-button id="certificate-info-{{ index }}" fill="clear" class="ion-no-padding ion-no-margin margin-left-l" shape="round">
            <app-icon name="info" size="large" icon-style="filled" colour="primary" slot="icon-only"></app-icon>
        </ion-button>
        <ion-popover trigger="certificate-info-{{ index }}" triggerAction="click" side="right" alignment="center" size="auto" showBackdrop="false">
            <ng-template>
                <ion-content class="ion-padding">
                    Check with the student if this name is correct. If incorrect, contact to Graduation Team
                </ion-content>
            </ng-template>
        </ion-popover>
    </p>
    <p class="margin-0 color-dark-1">{{ details.attendeeName | default }}</p>
</div>

<hr />
<ion-grid class="ion-no-padding ">
    <ion-row>
        <ion-col size="6">
            <p class="bold margin-0 color-dark-1">Ceremony name</p>
            <p class="margin-0 color-dark-1">{{ details.nameOrder | default }}</p>
        </ion-col>
        <ion-col size="5" offset="1">
            <p class="bold margin-0 color-dark-1">Name pronunciation</p>
            <p class="margin-0 color-dark-1">{{ details.namePronunciation | default }}</p>
        </ion-col>
    </ion-row>
</ion-grid>

<hr />
<ion-grid class="ion-no-padding">
    <ion-row>
        <ion-col size="6">
            <p class="bold margin-0 color-dark-1">Asian Name Order Requested<span class="margin-left-l not-bold">{{ details.nameOrderRequested | yesNo | default }}</span></p>
        </ion-col>
        <ion-col size="5" offset="1">
            <p class="bold margin-0 color-dark-1">Approved<span class="margin-left-l not-bold">{{ details.nameOrderApproved | yesNo | default }}</span></p>
        </ion-col>
    </ion-row>
</ion-grid>

<hr />
<app-field-list classList="margin-top-0">
    <app-field-value label="Degree Description">
        <a [routerLink]="programmeLink" [queryParams]="{careerNumber: details.studentCareerNumber, careerCode: details.career.code}">
            {{ details.degree.description | default }}
        </a>
    </app-field-value>
    <app-field-value label="Degree Code">{{ details.degree.code | default }}</app-field-value>
    <app-field-value label="Plan">
        <ng-container *ngIf="details.plans?.length > 0; else emptyPlans">
            <ng-container *ngFor="let plan of details.plans">
                {{ plan.code }} {{ plan.description }}<br/>
            </ng-container>
        </ng-container>
        <ng-template #emptyPlans>
            No data
        </ng-template>
    </app-field-value>
</app-field-list>

<ng-container *ngIf="details.status.code === 'A'">
    <app-field-list classList="margin-top-0">
        <app-field-value label="Courier Address">
            <ng-container *ngIf="hasPostalAddress; else noPostal">
                <p *ngIf="details.absentAddress?.address1" class="margin-0">{{ details.absentAddress.address1 }}</p>
                <p *ngIf="details.absentAddress?.address2" class="margin-0">{{ details.absentAddress.address2 }}</p>
                <p *ngIf="details.absentAddress?.address3" class="margin-0">{{ details.absentAddress.address3 }}</p>
                <p *ngIf="details.absentAddress?.city" class="margin-0">{{ details.absentAddress.city }}</p>
                <p *ngIf="details.absentAddress?.postalCode" class="margin-0">{{ details.absentAddress.postalCode }}</p>
                <p *ngIf="details.absentAddress?.country?.description" class="margin-0">{{ details.absentAddress.country.description }}</p>
            </ng-container>
            <ng-template #noPostal>
                No data
            </ng-template>
        </app-field-value>
    </app-field-list>
</ng-container>
<div class="margin-bottom-l" *ngIf="isEmbargo; else eventDetails">
    <app-alert-banner type="warning">
        Event details embargoed until {{ embargoDate | default }}
    </app-alert-banner>
</div>
<ng-template #eventDetails>
    <app-accordian header="Event Details" expanded="false" [highlight]="!highlight">
        <app-field-list classList="margin-0">
            <app-field-value label="Ceremony">
                {{ details.ceremony | default }}
            </app-field-value>
            <app-field-value label="Date">
                {{ details.eventDate | shortDate | default }}
            </app-field-value>
            <app-field-value label="Start Time">
                {{ eventTime('start') }}
            </app-field-value>
            <app-field-value *ngIf="details.status.code !== 'P'" label="End Time">
                {{ eventTime('end') }}
            </app-field-value>
            <app-field-value label="Location">
                <ng-container *ngIf="hasEventLocation; else noLocation">
                    <p *ngIf="details.location?.name" class="margin-0">{{ details.location.name }}</p>
                    <p *ngIf="details.location?.address1" class="margin-0">{{ details.location.address1 }}</p>
                    <p *ngIf="details.location?.address2" class="margin-0">{{ details.location.address2 }}</p>
                    <p *ngIf="details.location?.address3" class="margin-0">{{ details.location.address3 }}</p>
                    <p *ngIf="details.location?.city" class="margin-0">{{ details.location.city }}</p>
                </ng-container>
                <ng-template #noLocation>
                    No data
                </ng-template>
            </app-field-value>
        </app-field-list>
    </app-accordian>
</ng-template>

<hr />
<app-field-list classList="margin-0">
    <app-field-value label="Last Modified">
        {{ details.lastUpdatedOn | date:'medium' }}
    </app-field-value>
    <app-field-value label="Updated by">
        {{ details.lastUpdatedBy | default }}
    </app-field-value>
</app-field-list>