<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="primary" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary">
            {{ loanDetail.fileNumber }} - Full Details
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid class="ion-no-padding">
        <div class="section">
            <ion-row>
                <ion-col size="6">
                    <ion-row class="">
                        <ion-col size="6" class="bold">File Number</ion-col>
                        <ion-col size="6">{{ loanDetail.fileNumber | default }}</ion-col>
                    </ion-row>
                </ion-col>
                <ion-col size="6">
                    <ion-row class="">
                        <ion-col size="6" class="bold">Status</ion-col>
                        <ion-col size="6">{{ loanDetail.status || loanDetail.status.description | default }}</ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="6">
                    <ion-row class="">
                        <ion-col size="6" class="bold">File Type</ion-col>
                        <ion-col size="6">
                            {{ loanDetail.fileType.code | default }}<br/>
                            {{ loanDetail.fileType.description | default }}
                        </ion-col>
                    </ion-row>
                </ion-col>
                <ion-col size="6">
                    <ion-row class="">
                        <ion-col size="6" class="bold">Prospectus code</ion-col>
                        <ion-col size="6">{{ loanDetail.reference | default }}</ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row class="">
                <ion-col size="6">
                    <ion-row>
                        <ion-col size="6" class="bold">Transaction Date</ion-col>
                        <ion-col size="6">{{ loanDetail.transactionDate | shortDate | default }}</ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </div>

        <div class="section">
            <ion-row>
                <ion-col size="6">
                    <ion-row>
                        <ion-col size="6" class="bold">Fee Flag</ion-col>
                        <ion-col size="6">
                            {{ loanDetail.feeType.code | default }}<br/>
                            {{ loanDetail.feeType.description | default }}
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col size="6">
                    <ion-row>
                        <ion-col size="6" class="bold">Study start</ion-col>
                        <ion-col size="6">{{ loan.startsOn | shortDate | default }}</ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </div>

        <div class="section">
            <ion-row>
                <ion-col size="6">
                    <ion-row>
                        <ion-col size="6" class="bold">Total fees</ion-col>
                        <ion-col size="6">{{ loanDetail.totalFees | currency | default }}</ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </div>
    </ion-grid>
</ion-content>