import { Component, Inject, Input } from '@angular/core';
import { AppConfiguration, Course } from '@domain/models';
import { ModalController } from '@ionic/angular';
import { CourseViewModel, TermViewModel } from '../programme-courses-view.model';
import { pipe, groupBy, map, path } from 'ramda';
import { sortByProp } from '@app/util';
import { APP_CONFIG } from '@core/providers';

@Component({
    selector: 'app-courses-modal',
    templateUrl: './courses-modal.component.html',
    styleUrl: './courses-modal.component.scss'
})
export class CoursesModalComponent {
    customPopoverOptions = {
        side: 'bottom',
        alignment: 'start'
    };
    courseTerms: TermViewModel[];
    courseList: CourseViewModel[];
    defaultTerm: string;
    openPanels = new Set<number>();
    programmeDesc: string;
    termGPA: number;

    constructor(private modalCtrl: ModalController, @Inject(APP_CONFIG) private configuration: AppConfiguration) {
    }

    @Input() studentId: number;
    @Input() set courses(courses: Course[]) {
        // Groups list of courses by term and course ID
        const groupCourses = pipe(
            groupBy(path(['term', 'description'])),
            map(groupBy(path(['class', 'course', 'code']))),
        )(courses);

        const mapCourses = Object.keys(groupCourses).map(term => {
            const courses = Object.keys(groupCourses[term]).map(courseKey => {
                let classes = groupCourses[term][courseKey];
                // Sort primary class first
                classes = sortByProp('descend', classes, 'class.isPrimary');
                const primaryClass = classes.find((c: Course) => c.class.isPrimary) ?? classes[0];
                // Sum-up class points for enrolled class
                const totalClassPoints = classes
                    .flatMap(v => v.coursePoints && (v.status.code === 'E') ? v.coursePoints : 0)
                    .reduce((total: number, cur: number) => total + cur, 0);

                return { id: courseKey, primaryClass: primaryClass, classList: classes, totalClassPoints: totalClassPoints };
            }) as CourseViewModel[];

            return {
                term: term,
                courses: courses
            };
        }) as TermViewModel[];

        this.defaultTerm = mapCourses[0]?.term;
        this.courseList = mapCourses[0]?.courses;
        this.programmeDesc = mapCourses[0]?.courses[0]?.primaryClass.academicProgramme.description;
        this.termGPA = mapCourses[0]?.courses[0]?.primaryClass.termGpa?.gpa;

        this.courseTerms = mapCourses;
    }

    get courses(): TermViewModel[] {
        return this.courseTerms;
    }

    close() {
        return this.modalCtrl.dismiss(null, 'close');
    }

    onChangeTerm(e: CustomEvent) {
        const term = e.detail.value;
        const selectedTerm = this.courseTerms.find(v => v.term === term);
        this.courseList = selectedTerm.courses;
        this.termGPA = selectedTerm.courses[0]?.primaryClass.termGpa?.gpa;
        this.openPanels.clear();
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    get programmePageLink() {
        return `${this.configuration.cs9.baseUrl}/${this.configuration.cs9.paths.programmePage}?EMPLID=${this.studentId}`;
    }
}