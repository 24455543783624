import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/providers';
import { BaseHttpService } from './base-http-service';
import { cacheable } from '@core/cache';
import { AppConfiguration } from '@domain/models';
import { Observable, firstValueFrom } from 'rxjs';
import { Embargo } from '@app/domain/models/embargo';
import { BypassErrorService } from '@uoa/error-pages';

/**
 * Get embargo
 *
 */
@Injectable({
    providedIn: 'root',
})
export class EmbargoService extends BaseHttpService {

    constructor(
        @Inject(APP_CONFIG) configuration: AppConfiguration,
        http: HttpClient,
        errorBypass: BypassErrorService) {
            super(configuration, http, errorBypass);
    }

    @cacheable(60000)
    private getEmbargo(): Observable<Embargo> {
        return this.getResource<Embargo>('embargo', {handleError: true});
    }

    public async isEmbargoEnabled(): Promise<boolean> {
        const embargo = await firstValueFrom(this.getEmbargo());
        return embargo.embargoInEffect;
    }
}

export const EmbargoServiceProvider={ provide: EmbargoService, useClass: EmbargoService };