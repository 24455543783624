import { Component, Input } from '@angular/core';
import { CustomCalendarEvent } from '../timetable-view.model';
import dayjs from 'dayjs';

@Component({
  selector: 'app-timetable-event-details',
  templateUrl: './timetable-event-details.component.html',
  styleUrl: './timetable-event-details.component.scss'
})
export class TimetableEventDetailsComponent {
    @Input() event: CustomCalendarEvent;
    @Input() eventTypes: string[];
    @Input() calendarType: 'default' | 'enrolment' = 'default';

    get startTime() {
        return dayjs(this.event?.start).format('HH:mm');
    }

    get endTime() {
        return dayjs(this.event?.end).format('HH:mm');
    }

    get props() {
        return this.event?.extendedProps;
    }

    get groupedEventTypes() {
        const classes = [];
        let row = 0;
        this.eventTypes.forEach((value, index) => {
            if (index % 2 === 0) {
                classes.push([value]);
            } else {
                classes[row][1] = value;
                row++;
            }
        });

        return classes;
    }

    eventClassName(type) {
        return type.replace(/\s+/g, '-');
    }
}
