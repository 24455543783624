import { Observable } from 'rxjs';

export function resizeObservable(elem: Element): Observable<ResizeObserverEntry[]> {
    return new Observable(subscriber => {
        const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            subscriber.next(entries);
        });

        // Observe one or multiple elements
        ro.observe(elem);

        return function unsubscribe(){
            ro.unobserve(elem);
            ro.disconnect();
        };
    });
}