import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ServiceError, StudentVisasInsurance } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import dayjs from 'dayjs';

@Component({
  templateUrl: './visa-view.component.html',
  styleUrl: './visa-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class VisaViewComponent extends PersonViewComponent {
    studentVisasInsurance: StudentVisasInsurance;
    isLoading = true;
    isError = false;
    message: string;

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getStudentVisasInsurance(super.identity.id)
            .subscribe({
                next: result => {
                    // Sorts visas and insurance
                    result.visas = result.visas.sort((a, b) => {
                        return dayjs(b.issuedOn).valueOf() - dayjs(b.issuedOn).valueOf();
                    });

                    // Remove cancel cover types as these are adjustments for administrative purposes
                    result.insurance = result.insurance
                                        .filter(insurance => insurance.coverageType.code !== 'CNCL')
                                        .sort((a, b) => {
                                            return dayjs(b.coverStart).valueOf() - dayjs(a.coverStart).valueOf();
                                        });

                    this.studentVisasInsurance = result;
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }
}
