import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isIdentityId, isUpi } from '@app/util';
import { Components } from '@ionic/core';
import IonSearchbar = Components.IonSearchbar;
import dayjs from 'dayjs';
import { AppStorageService } from '@app/core/services';

const STORAGE_KEY = 'auditBannerLastViewed';
const VIEW_FREQUENCY_HOURS = 24;
@Component({
  selector: 'app-page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchbar') searchbar: IonSearchbar;
  @ViewChild('searchbarMobile') searchbarMobile: IonSearchbar;
  @Input() isLoading = false;
  isAuditBannerOpen = false;

  constructor(private router: Router, private storage: AppStorageService) {}

  async ngOnInit() {
    /**
     * Get the last date (in epoch) that the banner was viewed from storage. If there
     * is no date or the date is older than the view frequency then display the banner.
     */
    const lastViewed = parseInt(await this.storage.getItem(STORAGE_KEY), 10);

    if (!isNaN(lastViewed)) {
      const lastViewedOn = dayjs(lastViewed);
      if (lastViewedOn.diff(dayjs(), 'hours') >= VIEW_FREQUENCY_HOURS) {
        this.isAuditBannerOpen = true;
      }
    } else {
      this.isAuditBannerOpen = true;
    }
  }

  onSearch($event = null) {
    const criteria = $event?.target.value.trim() || this.searchbar.value.trim() || this.searchbarMobile.value.trim();

    if (criteria !== '') {
      if (isUpi(criteria) || isIdentityId(criteria)) {
        void this.router.navigate(['person', criteria]);
      } else {
        void this.router.navigate(['search'], { queryParams: { criteria: criteria } });
      }
    }
  }

  dismissAuditBanner() {
    try {
      this.storage.setItem(STORAGE_KEY, '' + Date.now());
      this.isAuditBannerOpen = false;
    } catch (ex) {
      // Ignore
    }
  }
}
