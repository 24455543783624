import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExamViewModel } from '../exam-view.model';
import dayjs from 'dayjs';

@Component({
  selector: 'app-exam-details',
  templateUrl: './exam-details.component.html',
  styleUrl: './exam-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExamDetailsComponent {
    @Input() exam: ExamViewModel;

    get location() {
        // Yesterday at 17:00
        const embargoedDate = dayjs(this.exam.examStart).add(-1, 'days').set('hours', 17).set('minutes', 0);

        if (dayjs().isAfter(embargoedDate) &&
            (this.exam.facility.description || this.exam.facility.location || this.exam.facility.room || this.exam.facility.code)) {
            return this.exam.facility.description || this.exam.facility.location || this.exam.facility.room || this.exam.facility.code;
        }

        return `Embargoed until ${embargoedDate.format('HH:mm DD/MM/YY')}`;
    }

    get examMode() {
        if (this.exam.mode?.code && this.exam.mode?.description) {
            return `${this.exam.mode.code} - ${this.exam.mode.description}`;
        }

        return null;
    }
}
