import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import { PersonViewService } from '@modules/person/services/person-view.service';
import { GraduationApplicationsViewModel } from '../graduation-applications-view.model';

const MAX_CHAR_LENGTH = 254;

@Component({
  selector: 'app-graduation-application-details',
  templateUrl: './graduation-application-details.component.html',
  styleUrl: './graduation-application-details.component.scss'
})
export class GraduationApplicationDetailsComponent {
    @Input() details: GraduationApplicationsViewModel;
    @Input() highlight: boolean;
    @Input() index: number;

    constructor(private view: PersonViewService) {
    }

    get hasPostalAddress() {
        return Object.keys(this.details.absentAddress)?.length > 0;
    }

    get hasEventLocation() {
        return Object.keys(this.details.location)?.length > 0;
    }

    eventTime(type: 'start' | 'end') {
        if (type === 'end') {
            return this.formatEventTime(this.details.eventDate, this.details.eventEnd);
        }

        return this.formatEventTime(this.details.eventDate, this.details.eventStart);
    }

    get programmeLink() {
        return this.view.toRouterLink('programme');
    }

    get isEmbargo() {
        return !dayjs().isAfter(dayjs(this.details.embargoEnd));
    }

    get embargoDate() {
        const embargoEnd = dayjs(this.details.embargoEnd);
        return `${embargoEnd.format('HH:mm')} on ${embargoEnd.format('DD/MM/YYYY')}`;
    }

    get isNameExceedLength() {
        return this.details?.namePronunciation?.trim().length > MAX_CHAR_LENGTH;
    }

    private formatEventTime(eventDate: Date, eventTime: string): string {
        const dateTimeString = `${dayjs(eventDate).format('YYYY-MM-DD')}T${eventTime}`;
        return dayjs(dateTimeString).format('HH:mm');
    }
}
