import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ReferralViewModel } from './referral-view.model';
import { sortByProp } from '@app/util';
import { SubmittedApplicationsViewModel } from '../submitted-applications-view.model';
import { StudentAdapter } from '@core/adapters';
import { ReferralAssessment, ReferralAssignment } from '@domain/models';

@Component({
  selector: 'app-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrl: './referral-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ReferralModalComponent implements OnInit {
    isLoading = true;
    isError = false;
    referralView: ReferralViewModel;

    @Input() application: SubmittedApplicationsViewModel;

    constructor(private modalCtrl: ModalController, private student: StudentAdapter, private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.student.getApplicationReferrals(this.application.studentId, this.application.applicationNumber)
            .subscribe({
                next: (result) => {
                    if (result) {
                        let assessments: ReferralAssessment[];
                        let latestAssessment: ReferralAssessment;
                        let assignments: ReferralAssignment[];
                        let latestAssignment: ReferralAssignment;

                        if (result.assessments?.length > 0) {
                            assessments = sortByProp('descend', result.assessments, 'lastUpdated');
                            latestAssessment = assessments[0];
                            assessments = assessments.filter(v => v.lastUpdated !== latestAssessment.lastUpdated);
                        }


                        if (result.assignments?.length > 0) {
                            assignments = sortByProp('descend', result.assignments, 'lastUpdated');
                            latestAssignment = assignments[0];
                            assignments = assignments.filter(v => v.assignedOn !== latestAssignment.assignedOn);
                        }

                        this.referralView = {
                            ...result,
                            assessments: assessments,
                            assignments: assignments,
                            assessment: latestAssessment,
                            assignment: latestAssignment
                        };

                    }

                   this.isLoading = false;
                   this.isError = false;
                },
                error: () => {
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    close() {
        void this.modalCtrl.dismiss();
    }

    get isDoctoral() {
        return this.application?.career?.code === 'UC02';
    }
}
