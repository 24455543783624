<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2>Residency Data</h2>

        <ng-container *ngIf="residencies; else noResidencyData">
            <ion-grid class="table ion-no-padding">
                <div class="table__thead">
                    <ion-row>
                        <ion-col size="3">
                            Career
                        </ion-col>
                        <ion-col size="3">
                            Term
                        </ion-col>                        
                        <ion-col size="2">
                            Residency
                        </ion-col>
                        <ion-col size="4">
                            Tuition Exception
                        </ion-col>
                    </ion-row>
                </div>
                <div class="table__tbody">
                    <ng-container *ngFor="let residency of residencies">
                        <ion-row>
                            <ion-col size="3"><h3 class="group-header">{{ residency.career?.description }}</h3></ion-col>
                            <ion-col size="3">{{ residency.term?.description }}</ion-col>
                            <ion-col size="2">
                                {{ residency.residency?.description }}
                            </ion-col>
                            <ion-col size="4">
                                {{ residency.tuitionException?.description | default }}
                                <ng-container *ngIf="residency?.tuitionException?.fundingMethod">
                                    ({{ residency.tuitionException?.fundingMethod?.description | default }})
                                </ng-container>
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </div>
            </ion-grid>
        </ng-container>

        <ng-template #noResidencyData>
            <h3 class="header__notfound">
                No data to display
            </h3>
        </ng-template>
    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>