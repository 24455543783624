import { Component } from '@angular/core';
import { ServiceError, StudyLink } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { sortByProp } from '@app/util';
import { groupBy } from 'ramda';
import { StudyLinkViewModel } from './study-link-view.model';
import dayjs from 'dayjs';

@Component({
  templateUrl: './study-link-view.component.html'
})
export class StudyLinkViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    studyLink: StudyLinkViewModel[];

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getStudyLink(super.identity.id)
            .subscribe({
                next: result => {
                    this.studyLink = this.buildViewModel(result);
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    buildViewModel(result: StudyLink[]): StudyLinkViewModel[] {
        // Group by studyStart
        let groupStudyLink = groupBy((v: StudyLink) => dayjs(v.studyStart).format('YYYY'), result);

        // Maps year and studyLink
        groupStudyLink = Object.keys(groupStudyLink).map(key => {
            return { year: key, studyLink: groupStudyLink[key] };
        });

        // Sort most recent StudyLink application
        return sortByProp('descend', groupStudyLink, 'year');
    }
}
