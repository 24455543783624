<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2>Service Indicators</h2>
        <app-tabs [selectedTab]="selectedTab" [tabLabels]="tabLabels" (tabChange)="handleChange($event)" />

        <div class="indicator-container ion-padding">
            <ng-container *ngIf="selectedTab === 'active'; else inactiveTab">
                <app-service-indicators *ngIf="serviceIndicators?.active?.length > 0; else noActiveIndicators"
                                        [serviceIndicators]="serviceIndicators.active"></app-service-indicators>
                <ng-template #noActiveIndicators>
                    <h3 class="header__notfound">
                        No active service indicators
                    </h3>
                </ng-template>
            </ng-container>
            <ng-template #inactiveTab>
                <app-service-indicators *ngIf="serviceIndicators?.inactive?.length > 0; else noInactiveIndicators"
                                        [serviceIndicators]="serviceIndicators.inactive"></app-service-indicators>
                <ng-template #noInactiveIndicators>
                    <h3 class="header__notfound">
                        No inactive service indicators
                    </h3>
                </ng-template>
            </ng-template>
        </div>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>