import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { Residency, ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';

@Component({
  templateUrl: './residency-view.component.html',
  styleUrl: './residency-view.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ResidencyViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;

    residencies: Residency[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getResidency(super.identity.id)
            .subscribe({
                next: result => {
                    if (result && result.length > 0) {
                        // Sort by career, then term start
                        result.sort((a, b) => a.career.code.localeCompare(b.career.code) || b.term.start.getTime() - a.term.start.getTime());

                        // Group history by career. Remove the career from subseqent rows so only the first generates a header in the view
                        let previous: Residency;
                        for (const residency of result) {
                            if (previous && previous.career.code === residency.career.code) {
                                delete residency.career;
                            } else {
                                previous = residency;
                            }
                        }

                        this.residencies = result;
                    }

                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                }
            });
    }

    render() {
        this.load();
    }
}
