import { Component, Input } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import {
    ScholarshipApplicationDetailViewModel,
    ScholarshipApplicationViewModel
} from '../scholarship-applications-view.model';
import { ScholarshipApplicationDetail, ServiceError } from '@domain/models';

@Component({
  selector: 'app-scholarship-application-details',
  templateUrl: './scholarship-application-details.component.html',
  styleUrl: './scholarship-application-details.component.scss',
})
export class ScholarshipApplicationDetailsComponent {
  isLoading: boolean;
  isError: boolean;
  message: string;
  details: ScholarshipApplicationDetailViewModel[];

  constructor(private student: StudentAdapter) {}

  @Input() application: ScholarshipApplicationViewModel;
  @Input() set isOpen(value: boolean) {
    if (value) {
      this.load();
    }
  }

  public load(): void {
      this.isError = false;
      this.isLoading = true;

      this.student.getScholarshipsApplicationDetails(this.application.studentId, this.application.id)
        .subscribe({
          next: (result) => {
            this.details = this.buildViewModel(result);
            this.isLoading = false;
          },
          error: (error) => {
            if (error instanceof ServiceError) {
              this.message = error.message;
            }
            this.isError = true;
            this.isLoading = false;
          },
        });
  }

  buildViewModel(result: ScholarshipApplicationDetail): ScholarshipApplicationDetailViewModel[] {
      if (Object.keys(result)?.length > 0) {
          return Object.entries(result).map(([section, fields]) => ({
              section: this.unCamelCase(section),
              fields: Object.entries(fields).map(([fieldName, value]) => ({
                  fieldName: this.unCamelCase(fieldName),
                  value
              }))
          }));
      }

      return [];
  }

  private unCamelCase(str: string): string {
      const regex = new RegExp(/([A-Z])([a-z])([A-Z])([A-Z]+)/g);

      if (regex.test(str)) {
          // puts space after the last uppercase letter of the sequence
          // Example: UoAID
          return str.replace(regex, '$1$2$3 $4');
      }

      return str
          // insert a space between lower & upper
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          // space before last upper in a sequence followed by lower
          .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3');
  }
}
