<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="close()">
        <app-icon name="close" icon-style="filled" size="medium"></app-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary" class="padding-right-l padding-left-l">
      <div class="d-flex ion-justify-content-between ion-align-self-center padding-right-l padding-left-l">Intended Courses</div>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="padding-l">
  <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <div class="padding-left-l padding-right-l table" *ngIf="intendedCourses?.length > 0; else noData">
      <div class="details-header table__thead">
        <ion-row>
          <ion-col size="3" class="bold color-primary">Term</ion-col>
          <ion-col size="5" class="bold color-primary">Course</ion-col>
          <ion-col size="4" class="bold color-primary">Status</ion-col>
        </ion-row>
      </div>
      <div class="table__tbody">
        <ng-container *ngFor="let intendedCourse of intendedCourses; even as isEven">
          <ion-row [ngClass]="!isEven ? 'row-grey' : 'row-white'">
            <ion-col size="3">{{ intendedCourse.term.code }}</ion-col>
            <ion-col size="5"> {{ intendedCourse.subject.code }} {{ intendedCourse.catalogNumber }} </ion-col>
            <ion-col size="4">{{ intendedCourse.status.description }}</ion-col>
          </ion-row>
        </ng-container>
      </div>
    </div>
    <ng-template #noData>
      <div class="padding-right-l padding-left-l" *ngIf="!isLoading">
        <p class="header__notfound">No data to display</p>
      </div>
    </ng-template>
  </app-dataload-spinner>
</ion-content>
