import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ResearchApplicationViewModel } from './research-application-view.model';
import dayjs from 'dayjs';

@Component({
  selector: 'app-research-application-modal',
  templateUrl: './research-application-modal.component.html',
  styleUrl: './research-application-modal.component.scss'
})
export class ResearchApplicationModalComponent {
    @Input() researchApplication: ResearchApplicationViewModel;

    get research(): ResearchApplicationViewModel {
        this.researchApplication.startDate = dayjs(`${this.researchApplication.startYear}${this.researchApplication.startMonth}`).toDate();

        return this.researchApplication;
    }

    constructor(private modalCtrl: ModalController) {}

    close() {
        void this.modalCtrl.dismiss();
    }
}
