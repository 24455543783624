<ng-container *ngIf="educationBackground; else empty">

    <ion-grid class="table ion-no-padding">
        <div class="table__thead table-header">
            <ion-row class="ion-align-items-center">
                <ion-col size="3">
                    First Language
                </ion-col>
                <ion-col size="3">
                    Second Language
                </ion-col>
                <ion-col size="4">
                    Last Two Years in English<sup>*</sup>
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ion-row class="row-white">
                <ion-col size="3">
                    {{ educationBackground?.firstLanguage?.description | default }}
                </ion-col>
                <ion-col size="3">
                    {{ educationBackground?.otherLanguage?.description | default }}
                </ion-col>
                <ion-col size="4">
                    {{ educationBackground?.hasTwoYearsEnglish | yesNo | default }}
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    <app-details-button [id]="1"
                                        (toggled)="handleToggleDetails(1)">
                    </app-details-button>
                </ion-col>
            </ion-row>
            <ion-row [class.ion-hide]="!isHideDetails(1)" class="more-details row-white">
                <ion-col size="12">
                    <app-english-language-tests
                            [englishLanguageEducation]="englishLanguageEducation" />
                </ion-col>
            </ion-row>
        </div>
    </ion-grid>

    <p>
        <sup>*</sup>This is information was self-declared during the application process and should not be used as a basis for any assessment of English proficiency requirements.
    </p>

</ng-container>
<ng-template #empty>
    <h3 class="header__notfound">No data to display</h3>
</ng-template>