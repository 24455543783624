import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ServiceIndicator } from '@domain/models';

@Component({
  selector: 'app-service-indicators-modal',
  templateUrl: './service-indicators-modal.component.html',
  styleUrl: './service-indicators-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIndicatorsModalComponent {
    @Input() serviceIndicator: ServiceIndicator;

    constructor(private modalCtrl: ModalController) {}

    close() {
        return this.modalCtrl.dismiss(null, 'close');
    }
}
