/* eslint-disable camelcase */
import { ModuleWithProviders } from '@angular/core';
import { GoogleTagManagerConfig, GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';

// Conditionally include the Google tag manager module if configured
let module: ModuleWithProviders<GoogleTagManagerModule> = null;
if (environment.googleTagManager?.id) {
  const config: GoogleTagManagerConfig = {
    id: environment.googleTagManager.id
  };

  if (environment.googleTagManager?.auth) {
    config.gtm_auth = environment.googleTagManager?.auth;
  }

  if (environment.googleTagManager?.preview) {
    config.gtm_preview = environment.googleTagManager?.preview;
  }

  if (environment.googleTagManager?.preview) {
    config.gtm_preview = environment.googleTagManager?.preview;
  }

  if (environment.googleTagManager?.resourcePath) {
    config.gtm_resource_path = environment.googleTagManager?.resourcePath;
  }

  module = GoogleTagManagerModule.forRoot(config);
}

export const GTMModules=module ? [module] : [];