import { Routes } from '@angular/router';
import { AuthGuard } from '@uoa/auth';
import { RouterModule } from '@angular/router';
import { SearchPageComponent } from './pages/search.page';

const routes: Routes = [
  {
    path: 'search',
    canActivate: [AuthGuard],
    component: SearchPageComponent
  }
];

export const SearchRoutes=RouterModule.forChild(routes);