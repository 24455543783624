import { Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { ScholarshipAwarded } from '@app/domain/models/scholarship-awarded';
import { sortByProp } from '@app/util';

@Component({
  selector: 'app-scholarship-history-view',
  templateUrl: './scholarship-history-view.component.html',
  styleUrl: './scholarship-history-view.component.scss'
})
export class ScholarshipsHistoryViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    scholarships: ScholarshipAwarded[];

    constructor(private student: StudentAdapter) {
        super();
    }

    public load(): void {
        this.isError = false;
        this.isLoading = true;

        this.student.getScholarshipsHistory(super.identity.id)
            .subscribe(
                {
                    next: (result: ScholarshipAwarded[]) => {
                        this.scholarships = sortByProp('descend', result, 'offerYear');
                        this.isLoading = false;
                    },
                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isError = true;
                        this.isLoading = false;
                    },
                });
    }

    render() {
        this.load();
    }
}
