<ng-container *ngIf="education?.length > 0; else noEducation">
    <app-accordian expanded="true" header="Tertiary Qualification">
        <ion-grid class="table ion-no-padding" slot="content">
            <div class="table__thead">
                <ion-row>
                    <ion-col size="1" class="ion-text-center">
                        Start Year
                    </ion-col>
                    <ion-col size="1" class="ion-text-center">
                        End Year
                    </ion-col>
                    <ion-col size="2">
                        Country
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                        Degree Date
                    </ion-col>
                    <ion-col size="2">
                        Institution
                    </ion-col>
                    <ion-col size="2">
                        Qualification
                    </ion-col>
                    <ion-col size="2" class="ion-text-center">
                        Awarded
                    </ion-col>
                </ion-row>
            </div>
            <div class="table__tbody">
                <ng-container *ngFor="let ed of education">
                    <ion-row>
                        <ion-col size="1" class="ion-text-right">{{ ed.start | year }}</ion-col>
                        <ion-col size="1" class="ion-text-right">{{ ed.end | year }}</ion-col>
                        <ion-col size="2">{{ ed.country?.description }}</ion-col>
                        <ion-col size="2" class="ion-text-center">{{ ed.conferredOn | shortDate | default }}</ion-col>
                        <ion-col size="2">{{ ed.provider?.description }}</ion-col>
                        <ion-col size="2">{{ qualification(ed) }}</ion-col>
                        <ion-col size="2" class="ion-text-center"><app-boolean [value]="ed.isComplete"></app-boolean></ion-col>
                    </ion-row>
                </ng-container>
            </div>
        </ion-grid>
    </app-accordian>
</ng-container>
<ng-template #noEducation>
    <h3 class="header__notfound">
        No data to display for Tertiary Qualification
    </h3>

</ng-template>