import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'year' })
export class YearPipe implements PipeTransform {
    transform(data: string | Date | number): string {
        if (data === null || data === undefined || data === '') {
            return null;
        }

        if (data instanceof Date && data.getFullYear() > 1900) {
            return ''+data.getFullYear();
        }

        return null;
    }
}
