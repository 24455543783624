<ng-container *ngIf="invoices?.length > 0; else noData">
    <ion-grid class="table ion-no-padding margin-top-l">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">
                    Invoice Date
                </ion-col>
                <ion-col size="2">
                    Invoice Number
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Due Date
                </ion-col>
                <ion-col size="2">
                    Payer
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Amount
                </ion-col>
                <ion-col size="2" class="ion-text-center">
                    Details
                </ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">

            <ng-container *ngFor="let invoice of invoices; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2" class="ion-text-center">{{ invoice.invoicedOn | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ invoice.invoiceNumber | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ invoice.dueOn | shortDate | default }}</ion-col>
                    <ion-col size="2">{{ invoice.payer | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ invoice.total | currency | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)" />
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-tax-invoice-details [invoice]="invoice" [highlight]="isEven"  />
                    </ion-col>
                </ion-row>
            </ng-container>
        </div>
    </ion-grid>
    <div class="display-flex ion-justify-content-center margin-top-l">
        <ion-button [href]="taxInvoiceLink" target="_blank"  fill="outline" color="primary" size="large" shape="round">
            View Tax Invoices
        </ion-button>
    </div>
</ng-container>
<ng-template #noData>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>
