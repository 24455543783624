<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary">{{serviceIndicator.indicator?.description}} Service Indicator</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-item-group>   
        <ion-item>
            <ion-label>
                <strong>Code: </strong>
                {{ serviceIndicator.indicator?.code | default }}
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <strong>Type: </strong>
                {{ serviceIndicator.positive ? 'Positive' : 'Negative'}}
            </ion-label>
        </ion-item>            
        <ion-item>
            <ion-label>
                <strong>Reason: </strong>
                {{ serviceIndicator.reason?.code | default }} - {{ serviceIndicator.reason?.description | default }}
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <strong>Reference: </strong>
                {{ serviceIndicator.reference | default }}
            </ion-label>
        </ion-item>
    </ion-item-group>

    <ion-item-group>
        <ion-item-divider></ion-item-divider>

        <ion-item>
            <ion-label>
                <strong>Description: </strong>
                <span [innerHTML]="serviceIndicator.reason?.longDescription | default"></span>
            </ion-label>
        </ion-item>
    </ion-item-group>

    <ion-item-group>
        <ion-item-divider></ion-item-divider>

        <ion-item>
            <ion-label>
                <strong>Start Term: </strong>
                {{ serviceIndicator.startTerm?.start | shortDate | default }}<br />
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <strong>End Term: </strong>
                {{ serviceIndicator.endTerm?.end | shortDate | default }}<br />
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <strong>Start Date: </strong>
                {{ serviceIndicator.startOn | shortDate | default }}<br />
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-label>
                <strong>End Date: </strong>
                {{ serviceIndicator.endOn | shortDate | default }}
            </ion-label>
        </ion-item>
    </ion-item-group>

    <ion-item-group *ngIf="serviceIndicator.amount > 0">
        <ion-item-divider></ion-item-divider>

        <ion-item>
            <ion-label>
                <strong>Amount: </strong>
                {{ serviceIndicator.amount | currency | default }}
            </ion-label>
        </ion-item>
    </ion-item-group>

    <ion-item-group>
        <ion-item-divider>
            <ion-label></ion-label>
        </ion-item-divider>

        <ion-item>
            <ion-label>
                <strong>Instructions: </strong>
                <span [innerHTML]="serviceIndicator.comments | default"></span>
            </ion-label>
        </ion-item>
    </ion-item-group>

    <ion-item-group>
        <ion-item-divider>
            <ion-label></ion-label>
        </ion-item-divider>

        <ion-item>
            <ion-label>
                <strong>Services Impacted:</strong>
                <ion-list>
                    <ion-item *ngFor="let impact of serviceIndicator.serviceImpacts">
                        <ion-label>
                            <strong>{{ impact.code | default }}</strong> 
                            <span *ngIf="impact.description"> - {{ impact.description }}</span>
                            -
                            <span class="impact-text">{{ impact.impact | default }} </span>
                        </ion-label>
                    </ion-item>
                    <ion-item *ngIf="!serviceIndicator.serviceImpacts || serviceIndicator.serviceImpacts.length === 0">
                        <ion-label>(none)</ion-label>
                    </ion-item>
                </ion-list>
            </ion-label>
        </ion-item>
    </ion-item-group>
</ion-content>