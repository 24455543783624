import { Component, Input } from '@angular/core';
import { ScholarshipApplicationViewModel } from '../scholarship-applications-view.model';

@Component({
  selector: 'app-scholarship-applications',
  templateUrl: './scholarship-applications.component.html',
  styleUrl: './scholarship-applications.component.scss'
})
export class ScholarshipApplicationsComponent {
  openPanels = new Set<number>();

  @Input() applications: ScholarshipApplicationViewModel[];

  handleToggleDetails(index: number) {
    if (!this.openPanels.has(index)) {
      this.openPanels.add(index);
    } else {
      this.openPanels.delete(index);
    }
  }

  isHideDetails(index: number) {
    return this.openPanels.has(index) ?? false;
  }
}