import Dayjs from 'dayjs';

const ISO_FORMAT=/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;

/* eslint-disable @typescript-eslint/no-explicit-any */
export function allISO8601ToDate<T>(obj: any): T {
    if (Array.isArray(obj)) {
        for (const entry of obj) {
            allISO8601ToDate(entry);
        }
    }

    if (obj instanceof Object) {
        Object.entries(obj).forEach(([key, value]) => {
            if (typeof value === 'string') {
                if (ISO_FORMAT.test(value)) {
                    const converted = Dayjs(value);
                    if (converted.isValid()) {
                        obj[key] = converted.toDate();
                    }
                }
            }

            if (value instanceof Object) {
                return allISO8601ToDate(value);
            }

            if (obj instanceof Array) {
                return allISO8601ToDate(value);
            }
        });
    }

    return obj as T;
}