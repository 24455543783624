import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EnglishLanguageEducation } from '@app/domain/models';


@Component({
  selector: 'app-english-language-tests',
  templateUrl: './language-tests.component.html',
  styleUrl: './language-tests.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnglishLanguageTestsComponent {
    @Input() englishLanguageEducation: EnglishLanguageEducation;

    notBlank(value) {
        return (value ?? null) !== null;
    }
}
