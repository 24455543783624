import { Injectable } from '@angular/core';
import { StorageService } from '@uoa/auth';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService implements StorageService {
  private storage: Storage;

  constructor(private ionicStorage: Storage) {

  }

  private async getStorage(): Promise<Storage> {
    if (!this.storage) {
      this.storage = await this.ionicStorage.create();
    }

    return this.storage;
  }

  async getItem(key: string): Promise<string> {
    const instance = await this.getStorage();

    return instance?.get(key);
  }

  async setItem(key: string, val: string): Promise<void> {
    const instance = await this.getStorage();

    return instance?.set(key, val);
  }

  async removeItem(key: string): Promise<void> {
    const instance = await this.getStorage();

    return instance?.remove(key);
  }
}

export const AppStorageServiceProvider={ provide: AppStorageService, useClass: AppStorageService };

// Provide storage without referencing the Auth service
export const AuthStorageServiceProvider={ provide: StorageService, useExisting: AppStorageService };

