import { Component, Input } from '@angular/core';
import { Communication } from '@domain/models/communication';
import { uniq } from 'ramda';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrl: './communications.component.scss'
})
export class CommunicationsComponent {
    openPanels = new Set<number>();
    commType = null;

    @Input() communications: Communication[];

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    direction(comm: Communication): string {
        const words = comm?.direction?.description?.split(' ');
        if (words.length > 0) {
            return words[0];
        }

        return 'Unknown';
    }

    trackBy(index: number, _communication: Communication): number {
        return index;
    }

    get commTypeList() {
        return uniq(this.communications.map(v => ({code: v.direction.code, description: this.direction(v)})));
    }

    get communicationList() {
        if (this.commType) {
           return this.communications.filter(comm => comm.direction.code === this.commType);
        }

        return this.communications;
    }

    onChangeComm(e: CustomEvent) {
        this.commType = e.detail.value;
        this.openPanels.clear();
    }
}
