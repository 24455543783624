<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="ion-padding" color="primary">Academic Plans</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-grid class="table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">Code</ion-col>
                <ion-col>Description</ion-col>
                <ion-col>Plan Type</ion-col>
                <ion-col size="2" class="ion-text-center">Details</ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">

            <ng-container *ngIf="plans?.length > 0; else empty">
                <ng-container *ngFor="let plan of plans; even as isEven; index as i">
                    <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                        <ion-col size="2" class="ion-text-center">{{ plan.code | default }}</ion-col>
                        <ion-col>{{ plan.description | default }}</ion-col>
                        <ion-col>{{ plan.type.description | default }}</ion-col>
                        <ion-col size="2" class="ion-text-center">
                            <app-details-button [disabled]="!planHasDetail(plan)" [id]="i" (toggled)="handleToggleDetails(i)"  />
                        </ion-col>
                    </ion-row>
                    <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                        <ion-col size="12">
                            <app-plan-details [plan]="plan" />
                        </ion-col>
                    </ion-row>
                </ng-container>
            </ng-container>
            <ng-template #empty>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">
                        No data to display
                    </ion-col>
                </ion-row>
            </ng-template>

        </div>
    </ion-grid>
</ion-content>