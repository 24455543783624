<app-page-header>
    <app-identity-summary [identity]="identity" (openMenu)="openMobileMenu($event)"></app-identity-summary>
</app-page-header>

<div class="page display-flex" *ngIf="!isLoading">
    <ng-container *ngIf="identity; else personNotFound">
        <app-navigation-menu [menus]="menu"
                             [isOpenMenu]="isMobileMenuOpen"
                             (navigation)="onMenuSelect($event)">
            <div class="page__button-nav">
                <ion-button fill="solid" size="default" [href]="configuration.links.studentDocs" target="_blank" title="Open student documents">
                    <app-icon slot="start" name="format align justify" icon-style="outlined" colour="primary" />
                    <span>Student Docs</span>
                </ion-button>
                <ion-button fill="solid" size="default" (click)="openTimeTable()" title="Open timetable">
                    <app-icon slot="start" name="calendar month" icon-style="outlined" colour="primary" />
                    <span>Timetable</span>
                </ion-button>
            </div>
        </app-navigation-menu >

        <div class="page__view-panel">
            <ion-content id="main">
                <div *ngIf="currentView">
                    <ng-container *ngComponentOutlet="currentView.component; inputs: {identity}">

                    </ng-container>
                </div>
            </ion-content>
        </div>
    </ng-container>

    <ng-template #personNotFound>
        <app-person-not-found></app-person-not-found>
    </ng-template>
</div>
<app-page-footer></app-page-footer>