import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    UnsubmittedApplication
} from '@modules/person/components/unsubmitted-applications-view/unsubmitted-applications/unsubmitted-applications-view-model';

@Component({
  selector: 'app-unsubmitted-application-details',
  templateUrl: './unsubmitted-application-details.component.html',
  styleUrl: './unsubmitted-application-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsubmittedApplicationDetailsComponent {
    @Input() application: UnsubmittedApplication;
    @Input() highlight: boolean;

    openAtsLink(application: UnsubmittedApplication) {
        window.open(application.applicationUrl, '_blank');
    }

    notBlank(value) {
        return (value ?? null) !== null;
    }
}
