<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2>Loan Details</h2>

        <ng-container *ngIf="studyLinkLoans?.length > 0; else noLoans">
                <ion-grid class="table ion-no-padding" slot="content">
                    <div class="table__thead">
                        <ion-row>
                            <ion-col size="2" class="ion-text-center">
                                Loan No.
                            </ion-col>
                            <ion-col size="2">
                                Loan Declined
                            </ion-col>
                            <ion-col size="2" class="ion-text-center">
                                Study Start Date
                            </ion-col>
                            <ion-col size="2" class="ion-text-center">
                                Loan Start
                            </ion-col>
                            <ion-col size="2" class="ion-text-center">
                                Loan End
                            </ion-col>
                            <ion-col size="2" class="ion-text-center">
                                File Data
                            </ion-col>
                        </ion-row>
                    </div>
                    <div class="table__tbody">

                        <ng-container *ngFor="let loan of studyLinkLoans; even as isEven">
                            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                                <ion-col size="2" class="ion-text-right">{{ loan.loanNumber | default }}</ion-col>
                                <ion-col size="2">{{ loan.declined | yesNo | default }}</ion-col>
                                <ion-col size="2" class="ion-text-center">{{ loan.studyStart | shortDate | default }}</ion-col>
                                <ion-col size="2" class="ion-text-center">{{ loan.startsOn | shortDate | default }}</ion-col>
                                <ion-col size="2" class="ion-text-center">{{ loan.endsOn | shortDate | default }}</ion-col>
                                <ion-col size="2" class="ion-text-center">
                                    <app-details-button [id]="loan.loanNumber" (toggled)="handleToggleDetails(loan.loanNumber)"></app-details-button>
                                </ion-col>
                            </ion-row>
                            <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(loan.loanNumber)" class="more-details">
                                <ion-col size="12">
                                    <app-study-link-loan-details [loan]="loan" />
                                </ion-col>
                            </ion-row>
                        </ng-container>

                    </div>
                </ion-grid>
        </ng-container>

        <ng-template #noLoans>
            <h3 class="header__notfound">
                No data to display
            </h3>
        </ng-template>

    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>