<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col>
                Academic Period
            </ion-col>
            <ion-col>
                Course
            </ion-col>
            <ion-col class="ion-text-center">
                Requested Date
            </ion-col>
            <ion-col class="ion-text-center">
                Last Updated
            </ion-col>
            <ion-col>
                Status
            </ion-col>
            <ion-col class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="concessions?.length > 0; else emptyConcessions">
            <ng-container *ngFor="let concession of concessions; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col>{{ concession.term.description | default }}</ion-col>
                    <ion-col>{{concession.class.subject.code}} {{concession.class.catalogNumber}}</ion-col>
                    <ion-col class="ion-text-center">{{ concession.createdOn | shortDate | default }}</ion-col>
                    <ion-col class="ion-text-center">{{ concession.updatedOn | shortDate | default }}</ion-col>
                    <ion-col>{{ concession.status.description | default }}</ion-col>
                    <ion-col  class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-concession-details [concession]="concession" />
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #emptyConcessions>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>