<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="close()">
        <app-icon name="close" icon-style="filled" size="medium"></app-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="primary" class="padding-right-l padding-left-l">
      <div class="d-flex ion-justify-content-between ion-align-self-center padding-right-l padding-left-l">
        Offer Conditions
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="padding-left-l padding-right-l">
  <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <div class="padding-left-l padding-right-l table" *ngIf="offerConditionsChecklist; else noData">
      <div class=""class="padding-left-l">
      <app-field-list>
        <app-field-value label="Comments"><span [innerHTML]="offerConditionsComment?.comments"></span></app-field-value>
      </app-field-list>
      
      <h5 class="bold color-primary">Checklist</h5>
      <app-field-list>
        <app-field-value label="Comments">{{offerConditionsChecklist?.comment}}</app-field-value>
        <app-field-value label="Status">{{offerConditionsChecklist.status.description}}</app-field-value>
      </app-field-list>

      <ng-container *ngIf="offerConditionsChecklist.items?.length > 0">
        <ion-grid class="bare-table ion-no-padding">
          <ion-row class="bare-table__thead">
            <ion-col>
              <strong>Item</strong>
            </ion-col>
            <ion-col size="3">
              <strong>Status</strong>
            </ion-col>
            <ion-col size="2">
              <strong>Status Date</strong>
            </ion-col>
          </ion-row>
          <ng-container *ngFor="let item of offerConditionsChecklist.items">
            <ion-row class="bare-table__tbody ion-align-self-start">
              <ion-col>{{ item.type.description | default }}</ion-col>
              <ion-col size="3">{{ item.status.description | default }}</ion-col>
              <ion-col size="2">{{ item.statusChangedOn | shortDate | default }}</ion-col>
            </ion-row>
          </ng-container>
        </ion-grid>
      </ng-container>
      </div>
    </div>
    <ng-template #noData>
      <div class="padding-right-l padding-left-l" *ngIf="!isLoading">
        <p class="header__notfound">No data to display</p>
      </div>
    </ng-template>
  </app-dataload-spinner>
</ion-content>