<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col>
                Degree
            </ion-col>
            <ion-col>
                Career
            </ion-col>
            <ion-col>
                Completion Term
            </ion-col>
            <ion-col class="ion-text-center">
                Confer Date
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="degrees?.length > 0; else empty">
            <ng-container *ngFor="let degree of degrees; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col>{{ degree.degree.code | default }}</ion-col>
                    <ion-col>{{ degree.career.description | default }}</ion-col>
                    <ion-col>{{ degree.completedTerm.description | default }}</ion-col>
                    <ion-col class="ion-text-center">{{ degree.conferredOn | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-degree-details [degree]="degree"></app-degree-details>
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>
        <ng-template #empty>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>