<section class="inner-content padding-top-l">
    <ion-card *ngFor="let i of placeholders">
        <ion-card-header>
            <ion-card-title>
                <ion-skeleton-text [animated]="true" style="height: 32px; width: 40%"></ion-skeleton-text>
            </ion-card-title>
            <ion-card-subtitle class="ion-no-margin">
                <ion-skeleton-text [animated]="true" style="height: 30px; width: 30%"></ion-skeleton-text>
            </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
            <ion-grid class="ion-no-padding">
                <ion-row>
                    <ion-col size="6"><ion-skeleton-text [animated]="true" style="height: 27px; width: 50%"></ion-skeleton-text></ion-col>
                    <ion-col size="6"><ion-skeleton-text [animated]="true" style="height: 27px; width: 50%"></ion-skeleton-text></ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="6"><ion-skeleton-text [animated]="true" style="height: 27px; width: 50%"></ion-skeleton-text></ion-col>
                    <ion-col size="6"><ion-skeleton-text [animated]="true" style="height: 27px; width: 50%"></ion-skeleton-text></ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="6"><ion-skeleton-text [animated]="true" style="height: 27px; width: 50%"></ion-skeleton-text></ion-col>
                    <ion-col size="6"><ion-skeleton-text [animated]="true" style="height: 27px; width: 50%"></ion-skeleton-text></ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12" class="margin-top-l display-flex ion-justify-content-center">
                        <ion-skeleton-text [animated]="true" style="height: 32px; width: 20%"></ion-skeleton-text>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card-content>
    </ion-card>
</section>