<ng-container *ngIf="programmes?.active || programmes?.inactive else noProgrammes">
    <app-tabs [tabLabels]="tabLabels" [selectedTab]="selectedTab" (tabChange)="handleChange($event)" />

    <ng-container *ngIf="selectedTab === 'active'; else inactiveTab">
        <app-programme-table [programmes]="programmes?.active" [selected]="selected"/>
    </ng-container>
    <ng-template #inactiveTab>
        <app-programme-table [programmes]="programmes?.inactive" [selected]="selected"/>
    </ng-template>
</ng-container>
<ng-template #noProgrammes>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>