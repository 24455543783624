import { Component, ElementRef, Input, AfterContentInit, QueryList, ContentChildren, Renderer2, HostBinding } from '@angular/core';
import { FieldValueComponent } from '../field-value/field-value.component';
import { AuthorisationService } from '@app/core/services';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss'],
})
export class FieldListComponent implements AfterContentInit {
  @Input() classList?: string;
  @Input() security?: boolean = false;

  @HostBinding('style')
  private style: SafeStyle;

  @ContentChildren(FieldValueComponent, { read: ElementRef })
  private children!: QueryList<ElementRef<FieldValueComponent & Element>>;

  constructor(private auth: AuthorisationService, private renderer: Renderer2, private sanitizer: DomSanitizer) { }

  async ngAfterContentInit(): Promise<void> {
    if (!this.security) {
      return;
    }

    const hidden = await Promise.all(
      this.children.map(
        async (child) => {
          if (child.nativeElement.id) {
            return !(await this.auth.hasPermission(child.nativeElement.id)) ? child : null;
          }

          return null;
        }
      ));

    hidden
      .filter(elem => elem !== null)
      .forEach(elem => this.renderer.setStyle(elem.nativeElement, 'display', 'none'));

    // If all elements are hidden then hide the host
    if (!hidden.some(elem => elem === null)) {
      this.style = this.sanitizer.bypassSecurityTrustStyle('display:none;');
    }

  }
}
