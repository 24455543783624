
<div *ngIf="visible" class="error-wrapper">
    <ion-grid>
        <ion-row class="ion-align-items-center">
            <ion-col size="1" class="ion-align-items-center">
                <app-icon name="bug report" icon-style="filled" colour="danger" size="large"></app-icon>
            </ion-col>
            <ion-col class="ion-text-start"><p class="error-wrapper__text">
                {{message ?? 'Sorry, something went wrong'}}</p>
            </ion-col>
        </ion-row>

        <ion-row class="ion-padding-top">
            <ion-col class="ion-text-center">
                <ion-button fill="outline" (click)="doRetry()" size="medium">
                    <span class="">Try Again...</span>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>