<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2>Communications</h2>

        <div class="ion-padding-bottom">
            <app-alert-banner type="info">
                <span class="bold">Warning: To view the content of the communications you must have appropriate access to SA Images</span>
            </app-alert-banner>
        </div>

        <app-communications [communications]="communications" />
        <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </app-dataload-spinner>
    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>

