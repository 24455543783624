import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-value',
  templateUrl: './field-value.component.html',
  styleUrls: ['./field-value.component.scss']
})
export class FieldValueComponent {
    @Input() label = '';
    @Input() labelSize: number | string = 4;
    @Input() valSize: number | string = 8;
    @Input() labelClassList: string;
    @Input() valClassList: string;
}
