<div [class]="highlight ? 'row-white':'row-grey'">
    <h5>Scholarship Details</h5>
    <app-field-list>
        <ion-row>
            <ion-col size="6">
                <app-field-value labelSize="8" valSize="4" label="Payment Method">
                    {{ details.paymentMethod?.description | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Payment Frequency">
                    {{ details.frequency?.description | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Payment Amount">
                    {{ details?.amount | currency | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Stipend Amount">
                    {{ details?.stipendAmount | currency | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Allowance Type">
                    {{ details?.allowanceType?.description | default }}
                </app-field-value>
            </ion-col>
            <ion-col size="5" offset="1">
                <app-field-value labelSize="8" valSize="4" label="Start Date">
                    {{ details?.startOn | shortDate | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="End Date">
                    {{ details?.expiresOn | shortDate | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Stipend Start Date">
                    {{ details?.stipendStart | shortDate | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Stipend End Date">
                    {{ details?.stipendEnd | shortDate | default }}
                </app-field-value>
            </ion-col>
        </ion-row>
    </app-field-list>


    <h5>Academic Details</h5>
    <app-field-list>
        <ion-row>
            <ion-col size="6">
                <app-field-value labelSize="8" valSize="4" label="Career">
                    {{ details.career?.code | default }}
                </app-field-value>
                <app-field-value labelSize="8" valSize="4" label="Acad Prog">
                    {{ details.programme?.description | default }}
                </app-field-value>
            </ion-col>
            <ion-col size="5" offset="1">
                <app-field-value labelSize="8" valSize="4" label="Plan">
                    {{ details.plan?.description | default }}
                </app-field-value>
            </ion-col>
        </ion-row>
    </app-field-list>
</div>