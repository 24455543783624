<ion-header class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button color="medium" (click)="close()">
                <app-icon name="close" icon-style="filled" size="medium"></app-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="primary">Application No.  {{ research.applicationNumber }}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-grid class="research-topic table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col>Research Topic</ion-col>
                <ion-col size="3" class="ion-text-center">Intended Start Date</ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ion-row *ngIf="research.intent && research.startDate; else noResearch">
                <ion-col>{{ research.intent | default }}</ion-col>
                <ion-col size="3" class="ion-text-center">{{ research.startDate | date : 'MMMM YYYY' | default }}</ion-col>
            </ion-row>
            <ng-template #noResearch>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">No data to display</ion-col>
                </ion-row>
            </ng-template>
        </div>
    </ion-grid>

    <ion-grid class="supervisors table ion-no-padding">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">Supervisor ID</ion-col>
                <ion-col>Supervisor</ion-col>
                <ion-col size="2" class="ion-text-center">Workload</ion-col>
                <ion-col>Role</ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">
            <ng-container *ngIf="research?.supervisors && research.supervisors?.length > 0; else noSupervisors">
                <ng-container *ngFor="let supervisor of research.supervisors; even as isEven" >
                    <ion-row [ngClass]="isEven ? 'row-white':'row-grey'">
                        <ion-col size="2" class="ion-text-right">{{ supervisor.id | default }}</ion-col>
                        <ion-col>{{ supervisor.name | default }}</ion-col>
                        <ion-col size="2" class="ion-text-right">
                            <ng-container *ngIf="supervisor.percent; else noData">
                                {{ supervisor.percent }}%
                            </ng-container>
                            <ng-template #noData>
                                No data
                            </ng-template>
                        </ion-col>
                        <ion-col>{{ supervisor.role.description | default }}</ion-col>
                    </ion-row>
                </ng-container>

            </ng-container>
            <ng-template #noSupervisors>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">
                        No data to display
                    </ion-col>
                </ion-row>
            </ng-template>
        </div>
    </ion-grid>

    <div class="off-campus-research">
        <h6 class="ion-text-left color-dark-1 ion-no-margin ion-margin-bottom">Off Campus Research</h6>
        <ng-container *ngIf="research?.isOffCampus; else noOffCampusResearch">
            <div class="padding-left-l">
                <app-field-list>
                    <app-field-value label="Offshore Fieldwork">{{  research.isOffshoreFieldwork | yesNo | default }}</app-field-value>
                    <app-field-value label="Month of Offshore Fieldwork">
                        <ng-container *ngIf="research.monthsOffshore; else noData">
                            {{ research.monthsOffshore }} Month/s
                        </ng-container>
                        <ng-template #noData>
                            No data
                        </ng-template>
                    </app-field-value>
                    <app-field-value label="Off-campus Comment">
                        <span [innerHTML]="research.offCampusComment | default"></span>
                    </app-field-value>
                </app-field-list>
            </div>
        </ng-container>
        <ng-template #noOffCampusResearch>
            <span class="color-dark-1">No data to display</span>
        </ng-template>
    </div>

    <div>
        <h6 class="ion-text-left color-dark-1">Performance/Presentation</h6>
        <ng-container *ngIf="research?.presentationComment; else noPresentationComment">
            <p class="color-dark-1 ion-text-left" [innerHTML]="research.presentationComment"></p>
        </ng-container>
        <ng-template #noPresentationComment>
            <p class="color-dark-1">No data to display</p>
        </ng-template>
        <hr class="background-medium" />
    </div>

    <div class="provisional-goals">
        <h6 class="ion-text-left color-dark-1">Application Specific Provisional Goals</h6>
        <div class="provisional-goals__box">
            <ng-container *ngIf="research?.researchGoals && research.researchGoals?.length > 0; else noResearchGoals">
                <ul>
                    <li *ngFor="let researchGoal of research.researchGoals">
                        {{ researchGoal.description }}
                    </li>
                </ul>
            </ng-container>
            <ng-template #noResearchGoals>
                <span class="color-dark-1">No data to display</span>
            </ng-template>
        </div>
    </div>

    <div class="mandatory-goals">
        <h6 class="ion-text-left color-dark-1">Mandatory Goals</h6>
        <div class="mandatory-goals__box">
            <ng-container *ngIf="research?.mandatoryGoals && research.mandatoryGoals?.length > 0; else noMandatoryGoals">
                <ul>
                    <li *ngFor="let mandatoryGoal of research.mandatoryGoals">
                        {{ mandatoryGoal.description }}
                    </li>
                </ul>
            </ng-container>
            <ng-template #noMandatoryGoals>
                <span class="color-dark-1">No data to display</span>
            </ng-template>
        </div>
    </div>
</ion-content>