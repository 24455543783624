import { Component, Input } from '@angular/core';
import { LoanDetail, StudyLinkLoan } from '@domain/models/study-link-loan';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-study-link-loan-modal',
  templateUrl: './study-link-loan-modal.component.html',
  styleUrl: './study-link-loan-modal.component.scss'
})
export class StudyLinkLoanModalComponent {
    @Input() loanDetail: LoanDetail;
    @Input() loan: StudyLinkLoan;

    constructor(private modalCtrl: ModalController) {}

    close() {
        return this.modalCtrl.dismiss(null, 'close');
    }
}
