import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { getPermissions, Permissions } from './authorisation';
import { EmbargoService } from './embargo.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {
  private cache: Permissions;
  constructor(private user: UserService, private embargoService: EmbargoService) {}

  public async hasPermission(object: string): Promise<boolean> {
    const scopes = await this.user.getAccessScopes();
    if (!scopes || scopes.length === 0) {
      return false;
    }

    if (!this.cache) {
      this.cache = await getPermissions();
    }

    try {
      if (this.cache.embargoRestricted.includes(object) && await this.embargoService.isEmbargoEnabled()) {
        return false;
      }
    } catch (ex) {
      // The safe option is to disable embargoed objects if there is a problem determining the state
      return false;
    }

    return scopes
      .filter((scope) => this.cache.permissions[scope] !== undefined)
      .map((scope) => this.cache.permissions[scope])
      .some((permissions) => permissions.has(object));
  }
}

export const AuthorisationServiceProvider = { provide: AuthorisationService, useClass: AuthorisationService };
