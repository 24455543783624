import {
    ChangeDetectionStrategy,
    Component,
    Input
} from '@angular/core';
import { Comment } from '@domain/models';
import { PersonViewService } from '@modules/person/services/person-view.service';

@Component({
  selector: 'app-comment-details',
  templateUrl: './comment-details.component.html',
  styleUrl: './comment-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentDetailsComponent {
    @Input() comment: Comment;
    @Input() highlight: boolean;
    @Input() accordianHeader: string;

    constructor(private view: PersonViewService) {}

    showLinkedDetailsAccordian(comment: Comment): boolean {
        return !(comment.adminFunction.code==='GEN' ||
                comment.adminFunction.code==='SFAC' ||
                comment.adminFunction.code==='ADMA')
                && comment.programme !== undefined;
    }

    showApplicationNumber(comment: Comment): boolean {
        return comment.adminFunction.code === 'ADMP' && comment.applicationNumber !== undefined;
    }

    showProgramCodeDescription(comment: Comment): boolean {
        return comment.adminFunction.code !== 'STRM';
    }

    showAcademicYear(comment: Comment): boolean {
        return comment.adminFunction.code !== 'SPRG';
    }

    get applicationLink() {
        return this.view.toRouterLink('submitted-applications');
    }
}
