import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Plan } from '@domain/models';

@Component({
  selector: 'app-plan-details',
  templateUrl: './plan-details.component.html',
  styleUrl: './plan-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanDetailsComponent {
    @Input() plan: Plan;
}
