<ng-container *ngIf="concessions?.current || concessions?.past else noConcessions">
    <app-tabs [selectedTab]="selectedTab" [tabLabels]="tabLabels" (tabChange)="handleChange($event)" />

    <ng-container *ngIf="selectedTab === 'current'; else pastTab">
        <app-concessions-table [concessions]="concessions?.current" />
    </ng-container>
    <ng-template #pastTab>
        <app-concessions-table [concessions]="concessions?.past" />
    </ng-template>
</ng-container>
<ng-template #noConcessions>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>