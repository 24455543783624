<ng-container *ngIf="insurance?.length > 0; else noInsurance">
    <ion-grid class="table ion-no-padding" slot="content">
        <div class="table__thead">
            <ion-row>
                <ion-col size="2" class="ion-text-center">Academic Year</ion-col>
                <ion-col size="6">Term</ion-col>
                <ion-col size="2" class="ion-text-right">Premium</ion-col>
                <ion-col size="2" class="ion-text-center">Details</ion-col>
            </ion-row>
        </div>
        <div class="table__tbody">

            <ng-container *ngFor="let insurance of insurance; even as isEven; index as i">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2" class="ion-text-center">{{ insurance.academicYear | default }}</ion-col>
                    <ion-col size="6">{{ insurance?.term?.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ insurance.premiumAmount | currency | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-insurance-details [insurance]="insurance" [highlight]="isEven"/>
                    </ion-col>
                </ion-row>
            </ng-container>

        </div>
    </ion-grid>
</ng-container>

<ng-template #noInsurance>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>