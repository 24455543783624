import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Notice, ServiceError } from '@domain/models';
import { NoticeService } from '@app/core/services';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrl: './notices.component.scss'
})
export class NoticesComponent {
  notices: Notice[] = [];
  isLoading = true;
  isError = false;
  message: string;

  constructor(private noticeService: NoticeService) {
    this.load();
  }

  load () {
    this.noticeService
          .getNotices()
          .pipe(takeUntilDestroyed())
          .subscribe({
            next: (notices) => {
              this.isLoading = false;
              this.notices = notices;
            },
            error: (error) => {
              if (error instanceof ServiceError) {
                  this.message = error.message;
              }
              this.isError = true;
              this.isLoading = false;
            }
          });

  }
}
