/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input } from '@angular/core';

const ICON_BASE_URL='https://icons.auckland.ac.nz/assets/icons';

export type IconStyle = 'filled' | 'outlined' | 'rounded' | 'sharp' | 'two-tone';
export type IconSize = 'small' | 'medium' | 'large' | 'xl' | 'xxl';
export type IconColour = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';
export type IconLibrary = 'material' | 'uoa';

/**
 * A light weigh wrapp around the ionic icon to use the University of Auckland's material icon collection
 */
@Component({
    selector: 'app-icon',
    styleUrl: './icon.component.scss',
    template: '<ion-icon slot="{{slot}}" aria-hidden="{{ariaHidden}}" aria-label="{{name}}" class="{{size}}" color="{{colour}}" src="{{url}}"></ion-icon>'
})
export class IconComponent {
    @Input({alias: 'icon-style'}) iconStyle: IconStyle = 'filled';
    @Input() name = 'error';
    @Input() slot: string;
    @Input() size: IconSize = 'medium';
    @Input({alias: 'aria-hidden', transform: (v: string) => new Boolean(v)}) ariaHidden = false;
    @Input() colour: IconColour = 'primary';
    @Input() library: IconLibrary = 'material';

    get url(): string {
        if (this.library === 'material') {
            return `${ICON_BASE_URL}/${this.library}/${this.iconStyle}/${this.file}`;
        } else {
            // UOA icons do not have a style
            return `${ICON_BASE_URL}/${this.library}/${this.file}`;
        }
    }

    get file(): string {
        return `${this.name.replaceAll(' ', '_')}.svg`;
    }
}