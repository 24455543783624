import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
    transform(data: boolean): string {
        if (data === null || data === undefined) {
            return null;
        }
        return data ? 'Yes' : 'No';
    }
}
