<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2>Research Information</h2>
        <app-research-information *ngIf="research?.length > 0; else empty" [research]="research"></app-research-information>
        <ng-template #empty>
            <h3 class="header__notfound">No data to display</h3>
        </ng-template>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>