<ion-grid class="bare-table ion-no-padding">
    <ion-row class="bare-table__thead">
        <ion-col size="1"  class="bold ion-text-center">No.</ion-col>
        <ion-col size="2" size-lg="" class="bold">Type</ion-col>
        <ion-col size="2" class="bold ion-text-center">Transaction<br/> Date</ion-col>
        <ion-col size="2" size-lg="" class="bold ion-text-center">Status</ion-col>
        <ion-col size="2" class="bold">Prospectus code</ion-col>
        <ion-col size="2" class="bold ion-text-center">Total fees</ion-col>
        <ion-col size="1" class="bold ion-text-center" >Details</ion-col>
    </ion-row>
    <ion-row class="bare-table__tbody ion-align-self-start" *ngFor="let detail of loan.details">
        <ion-col size="1" class="ion-text-right">{{ detail.fileNumber | default }}</ion-col>
        <ion-col size="2" size-lg="">{{ detail.fileType.description | default }}</ion-col>
        <ion-col size="2" class="ion-text-center">{{ detail.transactionDate | shortDate | default }}</ion-col>
        <ion-col size="2" size-lg="" class="ion-text-center">{{ detail.status || detail.status.description | default }}</ion-col>
        <ion-col size="2">{{ detail.reference | default }}</ion-col>
        <ion-col size="2" class="ion-text-right">{{ detail.totalFees | currency | default }}</ion-col>
        <ion-col size="1" class="ion-text-center">
            <ion-button color="secondary" (click)="openLoanDetailsModal(detail.fileNumber, loan)" fill="clear" class="ion-no-padding ion-no-margin">
                <app-icon name="info" slot="icon-only" size="large"></app-icon>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>