<ion-grid class="ion-no-padding margin-bottom-l">
    <ion-row>
        <ion-col class="ion-padding-0 margin-right-l" size="auto">
            <ion-item color="primary" lines="none">
                <ion-select [value]="selectedScholarship" (ionChange)="onChangeScholarship($event)"
                            label="Scholarship:" interface="popover" placeholder="All">
                    <ion-select-option [value]="null">All</ion-select-option>
                    <ng-container *ngFor="let scholarship of scholarshipList">
                        <ion-select-option [value]="scholarship.code">{{ scholarship.description }}</ion-select-option>
                    </ng-container>
                </ion-select>
            </ion-item>
        </ion-col>
        <ion-col size="auto">
            <ion-item color="primary" lines="none">
                <ion-select [value]="selectedStatus" (ionChange)="onChangeStatus($event)"
                            label="Status:" interface="popover" placeholder="All">
                    <ion-select-option [value]="null">All</ion-select-option>
                    <ng-container *ngFor="let status of statusList">
                        <ion-select-option [value]="status?.code">{{ status?.description }}</ion-select-option>
                    </ng-container>
                </ion-select>
            </ion-item>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-grid class="table ion-no-padding">
    <div class="table__thead">
        <ion-row>
            <ion-col size="2">
                Code
            </ion-col>
            <ion-col size="2">
                Description
            </ion-col>
            <ion-col size="2">
                Next Paydate
            </ion-col>
            <ion-col size="2">
                Amount Due
            </ion-col>
            <ion-col size="2">
                Status
            </ion-col>
            <ion-col size="2" class="ion-text-center">
                Details
            </ion-col>
        </ion-row>
    </div>
    <div class="table__tbody">

        <ng-container *ngIf="paymentList?.length > 0; else empty">
            <ng-container *ngFor="let payment of paymentList; even as isEven; index as i; trackBy: trackBy">
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'">
                    <ion-col size="2">{{ payment.scholarship.code | default }}</ion-col>
                    <ion-col size="2">{{ payment.scholarship.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">{{ payment.paymentOn | shortDate | default }}</ion-col>
                    <ion-col size="2" class="ion-text-right">{{ payment.amount | currency | default }}</ion-col>
                    <ion-col size="2">{{ payment.status.description | default }}</ion-col>
                    <ion-col size="2" class="ion-text-center">
                        <app-details-button [id]="i" (toggled)="handleToggleDetails(i)"></app-details-button>
                    </ion-col>
                </ion-row>
                <ion-row [ngClass]="!isEven ? 'row-grey':'row-white'" [class.ion-hide]="!isHideDetails(i)" class="more-details">
                    <ion-col size="12">
                        <app-scholarship-payment-details [payment]="payment"></app-scholarship-payment-details>
                    </ion-col>
                </ion-row>
            </ng-container>
        </ng-container>

        <ng-template #empty>
            <ion-row class="more-details">
                <ion-col size="12" class="ion-text-center">
                    No data to display
                </ion-col>
            </ion-row>
        </ng-template>

    </div>
</ion-grid>