import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ServiceError } from '@domain/models';
import { PersonAdapter } from '@app/core/adapters';

@Component({
  templateUrl: './affiliations-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class AffiliationsViewComponent extends PersonViewComponent {
    isLoading = true;
    isError = false;
    message: string;
    affiliations: string[];

    constructor(private personAdapter: PersonAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.personAdapter.getAffiliations(super.identity.id)
            .subscribe(
                {
                    next: result => {
                        this.affiliations = result;
                        this.isLoading = false;
                    },

                    error: (error) => {
                        if (error instanceof ServiceError) {
                            this.message = error.message;
                        }
                        this.isLoading = false;
                        this.isError = true;
                    },
        });
    }

    render(): void {
        this.load();
    }
}
