<app-field-list classList="margin-top-0 margin-bottom-0">
    <ion-row>
        <ion-col size="6">
            <p class="margin-0 margin-bottom-m bold color-dark-1">Transfer Credit Model</p>
            <div class="margin-left-l">
                <app-field-value labelSize="6" valSize="6" label="Model Number">{{ credit.id | default }}</app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Transcript Level">{{ credit.transcriptLevel.description | default }}</app-field-value>
            </div>
        </ion-col>
        <ion-col size="6">
            <p class="margin-0 margin-bottom-m bold color-dark-1">Target Information</p>
            <div class="margin-left-l">
                <app-field-value labelSize="6" valSize="6" label="Academic Plan">
                    <ng-container *ngIf="credit.academicPlan?.code && credit.academicPlan?.description; else noPlan">
                        {{ credit.academicPlan.code }} - {{ credit.academicPlan.description }}
                    </ng-container>
                    <ng-template #noPlan>
                        No data
                    </ng-template>
                </app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Include In GPA">{{ credit.includeInGpa | yesNo | default }}</app-field-value>
            </div>
        </ion-col>
    </ion-row>
</app-field-list>
<hr />
<app-field-list classList="margin-top-0 margin-bottom-0">
    <p class="margin-0 margin-bottom-m bold color-dark-1">Source Information</p>
    <ion-row>
        <ion-col size="6">
            <div class="margin-left-l">
                <app-field-value labelSize="6" valSize="6" label="Credit Source Type">{{ credit.transferType.description | default }}</app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Source Instution">{{ credit.organisation | default }}</app-field-value>
            </div>
        </ion-col>
        <ion-col size="6">
            <div class="margin-left-l">
                <app-field-value labelSize="6" valSize="6" label="School Type">{{ credit.schoolType.description | default }}</app-field-value>
                <app-field-value labelSize="6" valSize="6" label="Credit Type">{{ credit.creditType.description | default }}</app-field-value>
            </div>
        </ion-col>
    </ion-row>
</app-field-list>
<hr />
<p class="margin-0 margin-bottom-m bold color-dark-1">Courses Transferred</p>
<ng-container *ngFor="let course of credit.courses | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage[index], id: 'pagination'+index }; even as isEven">
    <div class="padding-l" [ngClass]="isEven ? 'background-light-1':'background-light'">
        <app-field-list classList="margin-top-0 margin-bottom-0">
            <ion-row>
                <ion-col size="12">
                  <p class="margin-0 margin-bottom-m bold color-dark-1">Sequence #{{ course.sequence | default }}</p>
                </ion-col>
            </ion-row>
        </app-field-list>
        <app-field-list classList="margin-top-0 margin-bottom-0">
            <p class="margin-0 margin-left-l margin-bottom-m bold color-dark-1">Incoming Course</p>
            <ion-row>
                <ion-col size="6">
                    <div class="margin-left-l padding-left-l">
                        <app-field-value labelSize="6" valSize="6" label="Subject">{{ course.source.subject | default }}</app-field-value>
                    </div>
                </ion-col>
                <ion-col size="6">
                    <div class="margin-left-l">
                        <app-field-value labelSize="6" valSize="6" label="Description">{{ course.source.description | default }}</app-field-value>
                    </div>
                </ion-col>
            </ion-row>
        </app-field-list>
        <app-field-list classList="margin-top-0 margin-bottom-0">
            <p class="margin-0 margin-left-l margin-bottom-m bold color-dark-1">Equivalent Course</p>
            <ion-row>
                <ion-col size="6">
                    <div class="margin-left-l padding-left-l">
                        <app-field-value labelSize="6" valSize="6" label="Course ID">
                            {{ course.course.code | default }} {{ course.course.description | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Offer No">{{ course.offerNumber | default }}</app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Units Transferred">{{ course.credits | default }}</app-field-value>
                    </div>
                </ion-col>
                <ion-col size="6">
                    <div class="margin-left-l">
                        <app-field-value labelSize="6" valSize="6" label="Grading Scheme">
                            {{ course.gradingScheme.code | default }} - {{ course.gradingScheme.description | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Grading Basis">
                            {{ course.gradingBasis.code | default }} - {{ course.gradingBasis.description | default }}
                        </app-field-value>
                        <app-field-value labelSize="6" valSize="6" label="Official Grade">
                            {{ course.gradeOffered | default }}
                        </app-field-value>
                    </div>
                </ion-col>
            </ion-row>
        </app-field-list>
    </div>
</ng-container>
<div class="display-flex ion-justify-content-center">
    <pagination-controls class="my-pagination" previousLabel="Prev" nextLabel="Next" (pageChange)="currentPage[index] = $event" [id]="'pagination'+index" />
</div>