<app-page-header></app-page-header>
<ion-content>
  <div class="content-wrapper">
    <div class="inner-content">
      <ion-grid>
        <ion-row>
          <ion-col size="6" size-md="6">
            <h2>Recently Viewed</h2>
            <app-recently-viewed></app-recently-viewed>
          </ion-col>

          <ion-col size="6" size-md="6">
            <h2>Notices</h2>
            <app-notices></app-notices>
          </ion-col>          
        </ion-row>
      </ion-grid>    
    </div>
  </div>
</ion-content>
<app-page-footer></app-page-footer>