<app-field-list>
    <ion-row>
        <ion-col size="6">
            <app-field-value label="Course Code">
                {{ exam.course?.code | default }}
            </app-field-value>
            <app-field-value label="Description">
                {{ exam.course?.description | default }}
            </app-field-value>
            <app-field-value label="Location">
                {{ location | default }}
            </app-field-value>
        </ion-col>
        <ion-col size="6">
            <app-field-value label="Exam Mode">
                {{ examMode | default }}
            </app-field-value>
            <app-field-value label="Book">
                {{ exam.bookRequirement?.description | default }}
            </app-field-value>
            <app-field-value label="Calculator">
                {{ exam.calculatorRequirements?.description | default }}
            </app-field-value>
        </ion-col>
    </ion-row>
</app-field-list>