/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Observable, map, of, throwError } from 'rxjs';
import { Identity, SearchResult } from '@domain/models';
import { IdentityService } from '@core/services';
import { isUpi, isIdentityId} from '@app/util/types';

/**
 * Retrieve and orchestrate information about people. This includes identity and related entities.
 *
 */
@Injectable({
    providedIn: 'root',
})
export class SearchAdapter {
    private cached: Identity[] = null;
    private nextPageLink: string;
    private previousPageLink: string;
    x = new Set<string>();

    constructor(
        private identityService: IdentityService
    ) {
    }

    /**
     * Get a person by id or upi from the search results or from the identity service if not found
     *
     * @param idOrUpi Student id or upi
     * @returns List of student visas
     */
    getPerson(idOrUpi: string): Observable<Identity> {
        if (!this.cached) {
            return throwError(() => new Error('No search results'));
        }

        // Try and find the identity in the search results by id or upi
        let found: Identity;

        if (isIdentityId(idOrUpi)) {
            found = this.cached.find((identity) => identity.id==Number.parseInt(idOrUpi));
        } else if (isUpi(idOrUpi)) {
            found = this.cached.find((identity) => identity.upi==idOrUpi);
        }

        if (found) {
            return of(found);
        }

        return throwError(() => new Error('Identity not found'));
    }

    /**
     * Get a list of identity for a free form search criteria
     *
     * @param criteria free form search text that may be name (first and last) or email
     *
     * @returns
     */
    search(criteria: string): Observable<SearchResult> {
        this.clear();

        return this.identityService.search(criteria)
                    .pipe(
                        map(result => {
                            this.cached = result.data;
                            this.nextPageLink = result?._links?.next?.href;
                            this.previousPageLink = result?._links?.previous?.href;

                            return {
                                data: this.cached,
                                hasNextPage: result?._links?.next !== undefined,
                                hasPreviousPage: result?._links?.previous !== undefined
                            };
                        })
                    );
    }

    /**
     * Goto next page if search has been executed
     *
     * @returns
     */
    nextPage(): Observable<SearchResult> {
        return this.identityService.gotoPage(this.nextPageLink)
                    .pipe(
                         map(result => {
                            // Should not have side effects in map, but we need to combine this with previous paged data
                            this.cached.push(...result.data);
                            this.nextPageLink = result?._links?.next?.href;
                            this.previousPageLink = result?._links?.previous?.href;

                            return {
                                data: this.cached,
                                hasNextPage: result?._links?.next !== undefined,
                                hasPreviousPage: result?._links?.previous !== undefined
                            };
                        })
                    );
    }

    /**
     * TRUE if the next page of results is available
     */
    get hasNextPage(): boolean {
        return this.nextPageLink !== null;
    }

    /**
     * TRUE if the previous page of results is available
     */
    get hasPreviousPage(): boolean {
        return this.previousPageLink !== null;
    }

    private clear(): void {
        this.cached = null;
        this.nextPageLink = null;
        this.previousPageLink = null;
    }
}

export const SearchAdapterProvider={ provide: SearchAdapter };