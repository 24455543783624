<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2>Scholarship Payments</h2>
        <app-scholarship-payments [payments]="payments" *ngIf="payments?.length > 0; else empty"/>
        <ion-infinite-scroll *ngIf="nextPageLink" (ionInfinite)="onInfiniteScroll($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
        <ng-template #empty>
            <h3 class="header__notfound">No data to display</h3>
        </ng-template>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>