import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StudentAdapter } from '@core/adapters';
import { ProgrammeCourses, ServiceError, StudentProgramme, TermGpa } from '@domain/models';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { CourseTermGpaViewModel, ProgrammeCoursesViewModel, ProgrammeViewModel } from './programme-courses-view.model';
import { groupBy } from 'ramda';
import { sortByProp } from '@app/util';

@Component({
  templateUrl: './programme-courses-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProgrammeCoursesViewComponent extends PersonViewComponent {
    programmes: ProgrammeCoursesViewModel;
    isLoading = true;
    isError = false;
    message: string;

    constructor(private student: StudentAdapter) {
        super();
    }

    load(): void {
        this.isLoading = true;
        this.isError = false;

        this.student.getProgrammeCourses(super.identity.id)
            .subscribe({
                next: result => {
                    this.programmes = this.buildViewModel(result);
                    this.isLoading = false;
                },

                error: (error) => {
                    if (error instanceof ServiceError) {
                        this.message = error.message;
                    }
                    this.isLoading = false;
                    this.isError = true;
                },
            });
    }

    render(): void {
        this.load();
    }

    buildViewModel(result: ProgrammeCourses): ProgrammeCoursesViewModel {
        const programme = result.programmes;
        const courses = sortByProp('descend', result.courses, 'term.description');
        const termProgress = result.termProgress;

        // Flatten term progress to get term gpa
        const termGpa: TermGpa[] = [].concat(...termProgress.map(term => term.terms));

        // Flatten and maps term GPA to courses
        const courseTermGpa = [].concat(...courses.map((course: CourseTermGpaViewModel) => {
            const found = termGpa.find((gpa)=> course.term.description === gpa.term.description);

            return {...course, termGpa: found};
        })) as CourseTermGpaViewModel[];

        const programmeGroup = programme.map((programme: StudentProgramme) => {
            const courseItems = courseTermGpa.filter((course: CourseTermGpaViewModel) => {
                return course.studentCareerNumber === programme.careerNumber;
            });

            return { ...programme, courses: courseItems, studentId: super.identity.id };
        });

        return groupBy((v: ProgrammeViewModel) => {
            if (v.status.code === 'AC') {
                return 'active';
            }

            return 'inactive';
        }, programmeGroup);
    }
}
