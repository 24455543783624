import { ActivatedRouteSnapshot, CanActivateFn, ResolveFn, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@uoa/auth';
import { PersonPageComponent } from './pages/person.page';
import { Identity } from '@app/domain/models';
import { inject } from '@angular/core';
import { PersonViewService } from './services/person-view.service';
import { AuthorisationService } from '@services/authorisation.service';

export const identityResolver: ResolveFn<Identity> =
    (route: ActivatedRouteSnapshot) => {
      return inject(PersonViewService).getOrRetrieveInstance(route.paramMap.get('id'));
    };

/**
 * Check if the user has authorisation to visit the route
 *
 * @param route
 * @returns
 */
const AuthorisationGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const view = route.params['view'];
  try {
    const isPermitted = await inject(AuthorisationService).hasPermission(view);
    if (!isPermitted) {
      // eslint-disable-next-line no-console
      console.error(`Illegal attempt to route to view ${view}`);
    }
    return isPermitted;
  } catch (ex) {
    return false;
  }
};

const routes: Routes = [
  {
    path: 'person/:id/:view',
    canActivate: [AuthGuard, AuthorisationGuard],
    component: PersonPageComponent,
    resolve: {identity: identityResolver},
    pathMatch: 'full'
  },
  {
    path: 'person/:id',
    redirectTo: 'person/:id/personal',
    pathMatch: 'full'
  },
];

export const PersonRoutes=RouterModule.forChild(routes);