import { Component } from '@angular/core';
import { ServiceError } from '@domain/models';
import { StudentAdapter } from '@core/adapters';
import { sortByProp } from '@app/util';
import { PersonViewComponent } from '@modules/person/model/person-view.component';
import { ScholarshipApplication } from '@domain/models/scholarship-application';
import { ScholarshipApplicationViewModel } from './scholarship-applications-view.model';

@Component({
  selector: 'app-scholarship-applications-view',
  templateUrl: './scholarship-applications-view.component.html',
  styleUrl: './scholarship-applications-view.component.scss'
})
export class ScholarshipApplicationsViewComponent extends PersonViewComponent {
  isLoading: boolean;
  isError: boolean;
  message: string;
  applications: ScholarshipApplicationViewModel[];

  constructor(private student: StudentAdapter) {
    super();
  }

  public load(): void {
    this.isError = false;
    this.isLoading = true;

    this.student.getScholarshipsApplications(super.identity.id)
        .subscribe({
          next: (result) => {
            this.applications = this.buildViewModel(result);
            this.isLoading = false;
          },
          error: (error) => {
            if (error instanceof ServiceError) {
              this.message = error.message;
            }
            this.isError = true;
            this.isLoading = false;
          },
        });
  }

  render() {
    this.load();
  }

  buildViewModel(result: ScholarshipApplication[]): ScholarshipApplicationViewModel[] {
      if (result?.length > 0) {
          result = sortByProp('ascend', result, 'scholarshipName');
          return result.map(v => ({...v, studentId: super.identity.id}));
      }

      return [];
  }
}
