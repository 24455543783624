<ng-container *ngIf="submittedApplicationsGroup?.current?.length > 0 || submittedApplicationsGroup?.past?.length > 0; else noSubmittedApplications">
    <app-tabs [selectedTab]="selectedTab" [tabLabels]="tabLabels" (tabChange)="handleChange($event)" />

    <ng-container *ngIf="selectedTab === 'current'; else pastTab">
        <app-submitted-applications-table [submittedApplications]="submittedApplicationsGroup?.current" [selected]="selected"/>
    </ng-container>
    <ng-template #pastTab>
        <app-submitted-applications-table [submittedApplications]="submittedApplicationsGroup?.past"  [selected]="selected"/>
    </ng-template>
</ng-container>

<ng-template #noSubmittedApplications>
    <h3 class="header__notfound">
        No data to display
    </h3>
</ng-template>