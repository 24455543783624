import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/providers';
import { Identity, AppConfiguration } from '@domain/models';
import { Observable } from 'rxjs';
import { BypassErrorService } from '@uoa/error-pages';
import { BaseHttpService } from './base-http-service';
import { cacheable } from '@core/cache';
import { isEmail } from '@app/util';
import { IdentitySearchResponse } from './model/search-response';

type SearchParameters = {email?: string; lastname?: string; firstname?: string, from?: number, size?: number};

/**
 * Identity search and find
 *
 */
@Injectable({
    providedIn: 'root',
})
export class IdentityService extends BaseHttpService {
    constructor(
            @Inject(APP_CONFIG) configuration: AppConfiguration,
            http: HttpClient,
            errorBypass: BypassErrorService) {
        super(configuration, http, errorBypass);
    }

    /**
     * Get a single identity from EPR (cacheable) using student id or upi
     *
     * @param idOrUpi person identifier as student id or UPI
     */
    @cacheable()
    getById(idOrUpi: string): Observable<Identity> {
        return this.getResource<Identity>('identity', {params: {id: idOrUpi}, handleError: true});
    }

    /**
     * Search using email or name with optional pagination
     *
     * @param criteria search criteria free form text
     * @param page zero index page number
     * @param limit number of results to return per page
     */
    search(criteria: string, page=0, limit=20): Observable<IdentitySearchResponse> {
        const query: SearchParameters = {size: limit, from: page};

        if (isEmail(criteria)) {
            // Email address
            query.email = criteria;
        } else {
            const names = criteria.split(' ');
            if (names.length === 1) {
                query.lastname = names[0];
            } else if (names.length > 1) {
                query.firstname = names[0];

                // In case of multiple names (e.g. middle) only take the last name
                query.lastname = names[names.length - 1];
            }
        }

        return this.getResource<IdentitySearchResponse>('search', {query});
    }

    /**
     * Get the next or previous page in search results
     *
     * @param link pagination link path
     */
    gotoPage(link: string): Observable<IdentitySearchResponse> {
        return this.get<IdentitySearchResponse>(link);
    }
}

export const IdentityServiceProvider={ provide: IdentityService, useClass: IdentityService };