import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-details-button',
  templateUrl: './details-button.component.html',
  styleUrl: './details-button.component.scss'
})
export class DetailsButtonComponent {
    isIconDown = true;
    icon='chevron-down-sharp';
    @Input() id: unknown;
    @Input() disabled = false;
    @Output() toggled = new EventEmitter<unknown>();

    toggle(val: unknown) {
        this.isIconDown = !this.isIconDown;

        if (this.isIconDown) {
          this.icon = 'chevron-down-sharp';
        } else {
          this.icon = 'chevron-up-sharp';
        }

        this.toggled.emit(val);
    }
}
