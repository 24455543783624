<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2 class="margin-bottom-l">Unsubmitted Applications</h2>
        <app-unsubmitted-applications [unsubmittedApplications]="unsubmittedApplications" />
    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>


