<app-dataload-spinner [isLoading]="isLoading">
    <section class="inner-content person">
        <div class="content-header">
            <h2>Personal Details</h2>
            <ion-button [href]="eprLink" target="_blank" shape="round" fill="outline" color="secondary" size="small">
                Update in EPR
            </ion-button>
        </div>

        <hr class="margin-top-m"/>

        <div class="margin-top-l" *ngIf="isMergedAccount">
            <app-alert-banner type="info">
                <span class="bold">This account has been merged and the primary ID is displaying</span>
            </app-alert-banner>
        </div>

        <app-field-list>
            <app-field-value label="UoA ID">{{person.studentId}}</app-field-value>
            <app-field-value label="Username">{{person.username}}</app-field-value>
        </app-field-list>

        <hr/>

        <app-field-list [security]="true">
            <app-field-value label="Legal Name" id="person.legalName">
                <div class="detail-row">
                    {{person.primaryName.name}}
                    <app-icon *ngIf="person.primaryName.verification.verified" class="checkmark" name="check" colour="success" aria-hidden="true"></app-icon>
                    <span *ngIf="person.primaryName.verification.method" class="verification-source">
                        ({{person.primaryName.verification.methodDescription}})
                    </span>
                </div>
            </app-field-value>
            <app-field-value *ngIf="person.preferredName" label="Preferred Name" id="person.preferredName">
                {{person.preferredName}}
            </app-field-value>
            <app-field-value label="DoB" id="person.dob">
                <div class="detail-row">
                    {{person.birth.dateOfBirth | date}}
                    <app-icon *ngIf="person.birth.verification.verified" class="checkmark" name="check" colour="success" aria-hidden="true"></app-icon>
                    <span *ngIf="person.birth.verification.method" class="verification-source">
                        ({{person.birth.verification.methodDescription}})
                    </span>
                </div>
            </app-field-value>
            <app-field-value label="Gender" id="person.gender">
                {{person.gender | titlecase}}
            </app-field-value>
            <app-field-value label="Ethnicities" id="person.ethnicities">
                <ul>
                    <li *ngFor="let ethnicity of person.ethnicities">{{ethnicity}}</li>
                </ul>
            </app-field-value>
            <app-field-value label="Residency" id="person.residency">
                <div class="detail-row">
                    {{person.residency.country}}
                    <app-icon *ngIf="person.residency.verification.verified" class="checkmark" name="check" colour="success"
                              aria-hidden="true"></app-icon>
                    <span *ngIf="person.residency.verification.method" class="verification-source">
                        ({{person.residency.verification.methodDescription}})
                    </span>
                </div>
            </app-field-value>
            <app-field-value label="Citizenship" id="person.citizenship">
                <ul>
                    <li *ngFor="let citizenship of person.citizenships">{{citizenship}}</li>
                </ul>
            </app-field-value>
        </app-field-list>

        <app-field-list [security]="true">
            <hr/>

            <app-field-value label="NSN" id="person.nsn">
                <div class="detail-row">
                    {{person.nsn.nsn}}
                    <app-icon *ngIf="person.nsn.verification.verified" class="checkmark" name="check" colour="success"
                        aria-hidden="true"></app-icon>
                    <span *ngIf="person.nsn.verification.method" class="verification-source">
                        ({{person.nsn.verification.methodDescription}})
                    </span>
                </div>
            </app-field-value>
        </app-field-list>        

        <app-field-list [security]="true">
            <hr *ngIf="person.emails?.length > 0 || person.preferredEmail"/>

            <app-field-value label="Preferred Email" id="person.preferredEmail">
                <a href="mailto:{{person.preferredEmail?.email}}" title="Send email">{{person.preferredEmail?.email}}</a>
                <ion-icon *ngIf="person.preferredEmail?.verified" class="checkmark" name="check" colour="success" aria-hidden="true"></ion-icon>
            </app-field-value>
            <app-field-value *ngFor="let email of person.emails" label="Email ({{email.type}})" id="person.otherEmail">
                <a href="mailto:{{email.email}}" title="Send email">{{email.email}}</a>
                <app-icon *ngIf="email.verified" class="checkmark" name="check" colour="success" aria-hidden="true"></app-icon>
            </app-field-value>
        </app-field-list>

        <app-field-list [security]="true">
            <hr *ngIf="person.phoneNumbers?.length > 0"/>

            <app-field-value *ngFor="let phone of person.phoneNumbers" label="Phone ({{phone.type}})" id="person.telephone">
                {{phone.number}}
            </app-field-value>
        </app-field-list>

        <app-field-list [security]="true">
            <hr/>

            <app-field-value label="Mailing Address" id="person.address">
                <span class="preserve-white-space">
                    {{person.mailingAddress}}
                </span>
            </app-field-value>
        </app-field-list>

        <div class="person__footer">
            Last updated in EPR {{person.lastUpdated | date:'medium'}}
        </div>
    </section>
</app-dataload-spinner>