import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonViewServiceProvider } from './services/person-view.service';
import { PersonMenuServiceProvider } from './services/person-menu-service';
import { SharedComponentsModule } from '@modules/shared/shared.module';
import { IonicModule } from '@ionic/angular';
import { PersonPageComponent } from './pages';
import { PersonRoutes } from './person-routes';

import {
    IdentitySummaryComponent,
    ChecklistsViewComponent,
    ChecklistsComponent,
    ChecklistDetailsComponent,
    ChecklistModalComponent,
    CommunicationsViewComponent,
    PersonalDetailsViewComponent,
    NavigationMenuComponent,
    AffiliationsViewComponent,
    ServiceIndicatorsViewComponent,
    ServiceIndicatorsModalComponent,
    ServiceIndicatorsComponent,
    VisaViewComponent,
    VisaInformationComponent,
    VisaDetailsComponent,
    NotAvailableComponent,
    ExternalTestsViewComponent,
    ExternalTestDetailsComponent,
    PriorEducationViewComponent,
    SecondaryEducationComponent,
    TertiaryEducationComponent,
    FoundationEducationComponent,
    EntranceQualificationComponent,
    UnsubmittedApplicationsViewComponent,
    UnsubmittedApplicationsComponent,
    UnsubmittedApplicationDetailsComponent,
    SubmittedApplicationsViewComponent,
    SubmittedApplicationsComponent,
    SubmittedApplicationDetailsComponent,
    SubmittedApplicationsTableComponent,
    PersonNotFoundComponent,
    EnglishProficiencyViewComponent,
    EnglishLanguageInfoComponent,
    EnglishLanguageTestsComponent,
    CommentsChecklistsViewComponent,
    ResidencyViewComponent,
    InsuranceInformationComponent,
    InsuranceDetailsComponent,
    GroupsViewComponent,
    StudentProgressViewComponent,
    StudentProgressDetailsComponent,
    AelrComponent,
    DelnaComponent,
    CommentsComponent,
    CommentDetailsComponent,
    ApplicationCommentsViewComponent,
    AcademicStandingComponent,
    PriorityGroupsViewComponent,
    StudyLinkViewComponent,
    StudyLinkApplicationsComponent,
    StudyLinkApplicationDetailsComponent,
    ResearchApplicationModalComponent,
    StudyLinkLoanViewComponent,
    StudyLinkLoanModalComponent,
    StudyLinkLoanDetailsComponent,
    TuitionFeesViewComponent,
    TuitionFeesComponent,
    TuitionFeeDetailsComponent,
    ConcessionsViewComponent,
    ConcessionsComponent,
    ConcessionDetailsComponent,
    ConcessionsTableComponent,
    ProgrammeCoursesViewComponent,
    ProgrammeComponent,
    ProgrammeTableComponent,
    ProgrammeDetailsComponent,
    CoursesModalComponent,
    CourseDetailsComponent,
    ThirdPartyInfoViewComponent,
    ThirdPartyInfoComponent,
    StudentProgressComponent,
    TranscriptViewComponent,
    TranscriptComponent,
    TranscriptDetailsComponent,
    PlansModalComponent,
    PlanDetailsComponent,
    CommunicationsComponent,
    CommunicationDetailsComponent,
    TimetableViewComponent,
    TimetableEventDetailsComponent,
    TimetableCalendarComponent,
    ExamsViewComponent,
    ExamsTableComponent,
    ExamDetailsComponent,
    ReferralModalComponent,
    TaxInvoiceViewComponent,
    TaxInvoiceComponent,
    TaxInvoiceDetailsComponent,
    GraduationApplicationsViewComponent,
    GraduationApplicationsComponent,
    GraduationApplicationDetailsComponent,
    ResearchInformationViewComponent,
    ResearchInformationComponent,
    ResearchInformationDetailsComponent,
    ResearchInformationModalComponent,
    DegreesViewComponent,
    DegreesComponent,
    DegreeDetailsComponent,
    IntendedCourseModalComponent,
    CourseCreditViewComponent,
    CourseCreditComponent,
    CourseCreditDetailsComponent,
    EnrolmentCartViewComponent,
    EnrolmentCartComponent,
    EnrolmentCartDetailsComponent,
    EnrolmentCartCalendarComponent,
    ScholarshipPaymentsViewComponent,
    ScholarshipPaymentsComponent,
    ScholarshipPaymentDetailsComponent,
    ScholarshipsHistoryViewComponent,
    ScholarshipHistoryDetailsComponent,
    ScholarshipsHistoryComponent,
    ScholarshipApplicationsViewComponent,
    ScholarshipApplicationsComponent,
    ScholarshipApplicationDetailsComponent,
    OfferConditionsModalComponent
} from './components';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
    declarations: [
        AffiliationsViewComponent,
        ChecklistsViewComponent,
        ChecklistsComponent,
        ChecklistDetailsComponent,
        CommunicationsViewComponent,
        ExternalTestsViewComponent,
        IdentitySummaryComponent,
        NavigationMenuComponent,
        NotAvailableComponent,
        PersonalDetailsViewComponent,
        PersonPageComponent,
        ServiceIndicatorsViewComponent,
        ServiceIndicatorsModalComponent,
        UnsubmittedApplicationsComponent,
        UnsubmittedApplicationDetailsComponent,
        UnsubmittedApplicationsViewComponent,
        SubmittedApplicationsViewComponent,
        SubmittedApplicationsComponent,
        SubmittedApplicationDetailsComponent,
        SubmittedApplicationsTableComponent,
        VisaInformationComponent,
        NotAvailableComponent,
        ExternalTestsViewComponent,
        PriorEducationViewComponent,
        SecondaryEducationComponent,
        TertiaryEducationComponent,
        FoundationEducationComponent,
        EntranceQualificationComponent,
        VisaViewComponent,
        PersonNotFoundComponent,
        ChecklistsComponent,
        ChecklistModalComponent,
        EnglishProficiencyViewComponent,
        EnglishLanguageInfoComponent,
        EnglishLanguageTestsComponent,
        CommentsChecklistsViewComponent,
        ResidencyViewComponent,
        InsuranceInformationComponent,
        InsuranceDetailsComponent,
        VisaDetailsComponent,
        GroupsViewComponent,
        StudentProgressViewComponent,
        StudentProgressDetailsComponent,
        AelrComponent,
        DelnaComponent,
        CommentsComponent,
        CommentDetailsComponent,
        ApplicationCommentsViewComponent,
        AcademicStandingComponent,
        PriorityGroupsViewComponent,
        StudyLinkViewComponent,
        StudyLinkApplicationsComponent,
        StudyLinkApplicationDetailsComponent,
        ResearchApplicationModalComponent,
        StudyLinkLoanViewComponent,
        StudyLinkLoanModalComponent,
        StudyLinkLoanDetailsComponent,
        TuitionFeesViewComponent,
        TuitionFeesComponent,
        TuitionFeeDetailsComponent,
        ConcessionsViewComponent,
        ConcessionsComponent,
        ConcessionDetailsComponent,
        ConcessionsTableComponent,
        ProgrammeCoursesViewComponent,
        ProgrammeComponent,
        ProgrammeTableComponent,
        ProgrammeDetailsComponent,
        CoursesModalComponent,
        CourseDetailsComponent,
        ThirdPartyInfoViewComponent,
        ThirdPartyInfoComponent,
        StudentProgressComponent,
        TranscriptViewComponent,
        TranscriptComponent,
        TranscriptDetailsComponent,
        PlansModalComponent,
        PlanDetailsComponent,
        CommunicationsComponent,
        CommunicationDetailsComponent,
        ExternalTestDetailsComponent,
        TimetableViewComponent,
        TimetableEventDetailsComponent,
        TimetableCalendarComponent,
        ExamsViewComponent,
        ExamsTableComponent,
        ExamDetailsComponent,
        ReferralModalComponent,
        TaxInvoiceViewComponent,
        TaxInvoiceComponent,
        TaxInvoiceDetailsComponent,
        ServiceIndicatorsComponent,
        GraduationApplicationsViewComponent,
        GraduationApplicationsComponent,
        GraduationApplicationDetailsComponent,
        ResearchInformationViewComponent,
        ResearchInformationComponent,
        ResearchInformationDetailsComponent,
        ResearchInformationModalComponent,
        DegreesViewComponent,
        DegreesComponent,
        DegreeDetailsComponent,
        IntendedCourseModalComponent,
        CourseCreditViewComponent,
        CourseCreditComponent,
        CourseCreditDetailsComponent,
        EnrolmentCartViewComponent,
        EnrolmentCartComponent,
        EnrolmentCartDetailsComponent,
        EnrolmentCartCalendarComponent,
        OfferConditionsModalComponent,
        ScholarshipPaymentsViewComponent,
        ScholarshipPaymentsComponent,
        ScholarshipPaymentDetailsComponent,
        ScholarshipsHistoryViewComponent,
        ScholarshipsHistoryComponent,
        ScholarshipHistoryDetailsComponent,
        ScholarshipApplicationsViewComponent,
        ScholarshipApplicationsComponent,
        ScholarshipApplicationDetailsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        SharedComponentsModule,
        PersonRoutes,
        FullCalendarModule,
        NgxPaginationModule
    ],
  providers: [
    PersonViewServiceProvider,
    PersonMenuServiceProvider,
  ],
})
export class PersonModule {}