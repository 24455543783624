<section class="inner-content">
    <app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
        <h2>Student Progress</h2>
        <app-student-progress [progress]="progress"></app-student-progress>
        <app-academic-standing [academicStanding]="academicStanding"></app-academic-standing>
        <app-aelr [aelr]="aelr"></app-aelr>
        <app-delna [delna]="delna"></app-delna>
    </app-dataload-spinner>

    <app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>
</section>
