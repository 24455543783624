import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
    transform(content) {
        //URLs starting with http://, https://
        const pattern = /(\b(https?):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
        return content.replace(pattern, '<a href="$1" class="uoa-link" rel="noopener" target="_blank">$1</a>');
    }
}
