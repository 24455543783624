<app-dataload-spinner [isLoading]="isLoading" *ngIf="!isError">
    <section class="inner-content">
        <h2 class="margin-bottom-0">Affiliations</h2>
        <ion-list class="ion-no-padding">
            <ng-container *ngIf="affiliations && affiliations.length > 0; else noAffiliations">
                <ion-item *ngFor="let affiliation of affiliations">
                    <ion-label>{{affiliation}}</ion-label>
                </ion-item>
            </ng-container>
            
            <ng-template #noAffiliations>
                <h3 class="header__notfound">
                    No data to display
                </h3>
            </ng-template>
        </ion-list>
    </section>
</app-dataload-spinner>

<app-error-retry [message]="message" [visible]="isError" (retry)="load()"></app-error-retry>