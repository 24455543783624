import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgrammeCoursesViewModel } from '../programme-courses-view.model';
import { ActivatedRoute } from '@angular/router';

type Career = { careerNumber: number; careerCode: string };

@Component({
  selector: 'app-programme',
  templateUrl: './programme.component.html',
  styleUrl: './programme.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgrammeComponent {
    selectedTab = 'active';
    tabLabels = ['active', 'inactive'];
    viewModel: ProgrammeCoursesViewModel;
    selected: Career = { careerNumber: null, careerCode: null };

    constructor(route: ActivatedRoute) {
        route
            .queryParams
            .subscribe(params => {
                this.selected.careerNumber = parseInt(params['careerNumber']);
                this.selected.careerCode = params['careerCode'];
            });
    }

    @Input() set programmes(programmes) {
        this.viewModel = programmes;
        const foundInactive = programmes?.inactive?.find(v =>
            v.careerNumber === this.selected.careerNumber && v.programme.career.code === this.selected.careerCode);

        if (foundInactive !== undefined || (programmes?.inactive?.length > 0 && !programmes?.active)) {
            this.selectedTab = 'inactive';
        }
    }

    get programmes() {
        return this.viewModel;
    }

    handleChange(tabValue: string) {
        this.selectedTab = tabValue;
    }
}
