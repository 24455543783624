import { CacheStorage } from './cache';
import { InMemoryCache } from './in-memory-cache';

export class CacheManager {
	constructor(
		private storage?: CacheStorage
	) {
		if (this.storage === undefined) {
			this.storage = new InMemoryCache();
		}
	}

	private getCacheKey(method: string, key: string): string {
		return `${method}:${key}`;
	}

	cache<T>(method: string, key: string, value: T, expiry?: number): boolean {
		return this.storage.put(this.getCacheKey(method, key), value, expiry);
	}

	get<T>(method: string, key: string): T {
		return this.storage.get(this.getCacheKey(method, key));
	}
}
