import { ActivatedRouteSnapshot, DetachedRouteHandle, PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@uoa/auth';
import { HomePageComponent } from '@app/modules/home/pages';
import { IonicRouteStrategy } from '@ionic/angular';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomePageComponent,
    title: 'Customer Profile Home',
    canActivate: [AuthGuard]
  },
  {
    path: 'error/:errorCode',
    loadChildren: () => import('./modules/error-routing/error-routing.module').then((m) => m.ErrorRoutingModule),
  }
];

export const ApplicationRouting=RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules });

/**
 * A custom route reuse strategy to use ionic's cached component if the target component is the same.
 *
 * Note: The ionic angular framework router outlet is a cut down route reuse implementation.
 * The full angular route reuse strategy where the custom provider stores snapshots is not possible.
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
class CustomReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
     return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    throw new Error('not supported');
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
     return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
     throw new Error('not supported');
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
     return future.routeConfig === curr.routeConfig;
  }
 }

 /**
  * Use the ionic built-in routing strategy and provide a custom routing strategy to reuse routes with the same component
  */
export const RouteStrategyProviders=[
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {provide: RouteReuseStrategy, useValue: new CustomReuseStrategy()}
];