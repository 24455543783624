import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExamViewModel } from '../exam-view.model';
import { groupBy } from 'ramda';

@Component({
  selector: 'app-exams-table',
  templateUrl: './exams-table.component.html',
  styleUrl: './exams-table.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ExamsTableComponent {
    openPanels = new Set<number>();
    selectedExams: ExamViewModel[];
    tabLabels: string[];
    selectedTab: string;
    groupExams: {[key: string]: ExamViewModel[]};

    @Input() set exams(exams: ExamViewModel[]) {
        if (exams) {
            this.groupExams = groupBy((v: ExamViewModel) => {
                return v.term.description;
            }, exams);
            const groupExamsKeys = Object.keys(this.groupExams).sort();

            this.tabLabels = groupExamsKeys;
            this.selectedTab = groupExamsKeys[0];
            this.selectedExams = this.groupExams[groupExamsKeys[0]];
        }
    }

    course(exam: ExamViewModel) {
        if (exam.subject.code && exam.catalogueNumber) {
            return `${exam.subject.code} ${exam.catalogueNumber}`;
        }

        return null;
    }

    handleToggleDetails(index: number) {
        if (!this.openPanels.has(index)) {
            this.openPanels.add(index);
        } else {
            this.openPanels.delete(index);
        }
    }

    isHideDetails(index: number) {
        return this.openPanels.has(index) ?? false;
    }

    onTabChange(tabValue) {
        this.openPanels.clear();
        this.selectedTab = tabValue;
        this.selectedExams = this.groupExams[tabValue];
    }
}
