import {
    ChangeDetectionStrategy,
    Component,
    Input
} from '@angular/core';
import { ChecklistView } from '@modules/person/components/checklists-view/checklists-view.model';
import { ModalController, ModalOptions } from '@ionic/angular';
import { ChecklistItem } from '@domain/models';
import { ChecklistModalComponent } from '@modules/person/components/checklists-view';
import { PersonViewService } from '@app/modules/person/services/person-view.service';

@Component({
  selector: 'app-checklist-details',
  templateUrl: './checklist-details.component.html',
  styleUrl: './checklist-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistDetailsComponent {
    @Input() checklist: ChecklistView;
    @Input() highlight: boolean;

    constructor(private modalCtrl: ModalController, private view: PersonViewService) {}

    async openItemModal(item?: ChecklistItem) {
        const modal = await this.modalCtrl.create({
            componentProps: {
                item: item
            },
            component: ChecklistModalComponent
        });

        void modal.present();
    }

    get applicationLink() {
        return this.view.toRouterLink('submitted-applications');
    }

    openChecklistModal(checklist: ChecklistView): ModalOptions {
        return {
            componentProps: {
                checklist: checklist
            },
            component: ChecklistModalComponent
        };
    }
}
