export const NoticeSeverity = {
    Error: 'E',
    Warning: 'W',
    Message: 'M'
} as const;

export interface Notice {
    id: number;
    severity: {
        code: 'E' | 'W' | 'M';
        description: string;
    };
    title: string;
    message: string;
    meta?: {
        start: string;
        end: string;
    };
}